import React, { ReactElement } from 'react';
import Section from '../components/Section/Section';
const AuthorizationsPage = (): ReactElement => {
  return (
    <Section>
      <h3>Consent authorizations - coming soon</h3>
    </Section>
  );
};

export default AuthorizationsPage;
