import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  ConfirmRegisterType,
  RegisterResponseType,
  RegisterType,
  UserRegisterType,
} from '../models/authType';
import {
  confirmRegister,
  register,
  confirmRegisterProvider,
  registerResend,
} from '../services/auth.service';
import { UserRoles } from '../enum/userRoles';
import { RegisterStepsEnum } from '../enum/registerStepsEnum';
import { FormikHelpers } from 'formik';
import filterEmptyStringsFromObject from '../utils/filterEmptyStringsFromObject';
import { AuthError } from '../enum/authErrors';
import messages from '../staticText/messages';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';

interface UserRegisterHook {
  loading: boolean;
  handleRegister: (values: RegisterType) => Promise<RegisterResponseType>;
  handleConfirmRegister: (
    values: ConfirmRegisterType,
    role: UserRoles,
    admin: string,
  ) => Promise<RegisterResponseType>;
  errorMessage: string | undefined;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  userType: UserRoles;
  setUserType: Dispatch<SetStateAction<UserRoles>>;
  step: RegisterStepsEnum;
  setStep: Dispatch<SetStateAction<RegisterStepsEnum>>;
  initialValues: UserRegisterType;
  handleChangeUserType: (
    value: UserRoles,
    setFieldValue: (field: string, value: number) => void,
  ) => void;
  onSubmit: (
    values: UserRegisterType,
    { resetForm }: FormikHelpers<UserRegisterType>,
  ) => Promise<void>;
  email: string;
  handleResend: ({
    email,
    codeHash,
  }: {
    email?: string;
    codeHash?: string;
  }) => Promise<RegisterResponseType>;
  resendEmailSent: boolean;
  setResendEmailSent: Dispatch<SetStateAction<boolean>>;
  loadingResendEmailSent: boolean;
  responseStatus: number;
}

const useRegister = (emailUrl?: string): UserRegisterHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [userType, setUserType] = useState<UserRoles>(UserRoles.PATIENT);
  const [step, setStep] = useState(RegisterStepsEnum.INITIAL);
  const [resendEmailSent, setResendEmailSent] = useState(false);
  const [responseStatus, setResponseStatus] = useState<number>(null);
  const [loadingResendEmailSent, setLoadingResendEmailSent] = useState(false);

  const initialValues: UserRegisterType = {
    role: UserRoles.PATIENT,
    firstName: '',
    lastName: '',
    email: emailUrl || '',
    birthday: '',
    ssn: '',
    phoneNumber: '',
    npi: '',
    institutionName: '',
    address: '',
    secondAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    termsCheck: false,
  };

  const handleChangeUserType = useCallback(
    (value: UserRoles, setFieldValue: (field: string, value: number) => void) => {
      setUserType(value);
      setFieldValue('role', value);
    },
    [setUserType],
  );

  const onSubmit = async (
    values: UserRegisterType,
    { resetForm }: FormikHelpers<UserRegisterType>,
  ) => {
    setEmail(values.email);
    const registerData = {
      role: values.role,
      data: filterEmptyStringsFromObject(values) as UserRegisterType,
    };
    const responseData = await handleRegister(registerData);
    if (!responseData) return;
    setStep(RegisterStepsEnum.PENDING);
    resetForm();
  };

  const handleResend = async ({
    email,
    codeHash,
  }: {
    email?: string;
    codeHash?: string;
  }): Promise<RegisterResponseType> => {
    try {
      setErrorMessage(undefined);
      setResendEmailSent(false);
      setLoadingResendEmailSent(true);
      const response = await registerResend({ email, codeHash });
      setResendEmailSent(true);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        setErrorMessage(error.response.data?.message);
        return;
      } else {
        setErrorMessage('An error occurred during register resend request.');
      }
    } finally {
      setLoadingResendEmailSent(false);
    }
  };

  const handleRegister = async (values: RegisterType): Promise<RegisterResponseType> => {
    try {
      setResendEmailSent(false);
      setErrorMessage(undefined);
      setLoading(true);
      const updatedValues = {
        ...values,
        data: {
          ...values.data,
          ...(values.data.birthday && { birthday: formatDateInheritLocale(values.data.birthday) }),
          email: values.data.email.toLowerCase(),
        },
      };

      const response = await register(updatedValues);
      return response.data;
    } catch (error) {
      if (!(error instanceof AxiosError) && !error?.response?.data?.message) {
        setErrorMessage(messages.errorRegisterGeneric);
      } else if (error.response.data.message === AuthError.ROLE_NOT_MATCH) {
        setErrorMessage(messages.roleNotMatch);
      } else if (error.response.data.message === AuthError.BIRTHDAY_NOT_MATCH_REGISTER) {
        setErrorMessage(messages.registerBirthdayNotMatch);
      } else {
        setErrorMessage(error.response.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmRegister = useCallback(
    async (
      values: ConfirmRegisterType,
      role: UserRoles,
      admin: string,
    ): Promise<RegisterResponseType> => {
      try {
        setErrorMessage(undefined);
        setResponseStatus(null);
        setLoading(true);
        if (role === UserRoles.PROVIDER && admin === 'true') {
          const response = await confirmRegisterProvider(values);
          return response.data;
        }
        if (admin === 'false') {
          const response = await confirmRegister(values);
          return response.data;
        }
      } catch (error) {
        if (error instanceof AxiosError && error.response) {
          setResponseStatus(error.response.status);
          setErrorMessage(error.response.data?.message);
        } else {
          setErrorMessage('An error occurred during register request.');
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (resendEmailSent) {
      const timer = setTimeout(() => {
        setResendEmailSent(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [resendEmailSent, setResendEmailSent]);

  return {
    loading,
    handleRegister,
    handleConfirmRegister,
    errorMessage,
    setErrorMessage,
    userType,
    setUserType,
    step,
    setStep,
    initialValues,
    handleChangeUserType,
    onSubmit,
    email,
    handleResend,
    resendEmailSent,
    setResendEmailSent,
    loadingResendEmailSent,
    responseStatus,
  };
};

export default useRegister;
