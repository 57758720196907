import React, { useState, useEffect, ChangeEvent } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { PencilSquare, Check, X } from 'react-bootstrap-icons';
import styles from './InlineInput.module.css';

type InlineInputProps = {
  name: string;
  label?: string;
  isEditable?: boolean;
  formatDateDisplay?: boolean;
  onChange?: (field: string, newVal: string) => void;
};

const InlineInput = ({
  name,
  label,
  isEditable,
  onChange,
  formatDateDisplay,
}: InlineInputProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>();

  const isIsoString = (value: string) => {
    const isoRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z/;
    return isoRegex.test(value);
  };

  useEffect(() => {
    let { value } = getFieldMeta(name);
    if (typeof value === 'string' && (isIsoString(value) || formatDateDisplay)) {
      value = new Date(value);
    }
    if (value instanceof Date) {
      value = value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
    }
    setInputValue(String(value));
  }, [name, getFieldMeta, formatDateDisplay]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    const { value } = getFieldMeta(name);
    setInputValue(value as string);
    setIsEditing(false);
  };

  const handleSave = () => {
    setFieldValue(name, inputValue);
    if (onChange) {
      onChange(name, inputValue);
    }
    setIsEditing(false);
  };

  const renderField = () => {
    if (isEditing) {
      return (
        <>
          <input
            className={styles.input}
            type='text'
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          />
          <Check className={styles.btn} onClick={handleSave} />
          <X className={styles.btn} onClick={handleCancel} />
        </>
      );
    } else {
      return (
        <>
          {inputValue}
          {isEditable && !formatDateDisplay && (
            <PencilSquare className={styles.btn} onClick={isEditable ? handleEdit : undefined} />
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}:
          </label>
        )}
        <div className={styles.value}>{renderField()}</div>
      </div>
      <ErrorMessage name={name} render={(msg) => <div className={styles.error}>{msg}</div>} />
    </div>
  );
};

export default InlineInput;
