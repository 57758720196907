import React from 'react';

import styles from './StudyDate.module.css';

type StudyDateProps = {
  cell: string;
  excludeTime?: boolean;
};

const StudyDate = (date: StudyDateProps) => {
  const formattedDate = new Date(date.cell).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const [datePart, timePart] = formattedDate.split('at ');

  return (
    <div className={styles.root}>
      <div className={styles.date}>{datePart}</div>
      {!date.excludeTime && <div className={styles.time}>{timePart}</div>}
    </div>
  );
};

export default StudyDate;
