import React from 'react';

type EmptySpaceProps = {
  height: number;
};

const EmptySpace = ({ height }: EmptySpaceProps) => {
  return <div style={{ height, width: '100%' }} />;
};

export default EmptySpace;
