import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './ProgressBar.module.css';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar = ({ progress }: ProgressBarProps): ReactElement => {
  return (
    <div className={cx(styles.loadingBar, 'loadingBar')}>
      <div className={styles.fullBar}>
        <div className={styles.state} style={{ width: `${progress}%` }} />
      </div>
      <div className={styles.percentage}>{progress}%</div>
    </div>
  );
};

export default ProgressBar;
