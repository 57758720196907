import React, { ReactElement } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './register.module.css';
import config from '../../config';
import Button from '../../components/Button/Button';
import InputField from '../../components/Form/InputField';
import { RegisterStepsEnum } from '../../shared/enum/registerStepsEnum';
import { UserRoles } from '../../shared/enum/userRoles';
import useRegister from '../../shared/hooks/useRegister';
import messages from '../../shared/staticText/messages';
import { ReactComponent as RequestProcessingSvg } from '../../assets/request_process.svg';
import StatusScreen from '../../components/RegisterScreens/StatusScreen';
import countryOptions from '../../shared/utils/countryOptions';
import { AuthError } from '../../shared/enum/authErrors';
import BackButton from '../../components/Button/BackButton';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';
import ConfirmText from '../../components/ConfirmText/ConfirmText';

const Register = (): ReactElement => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const emailUrl = queryParameters.get('email');

  const {
    loading,
    errorMessage,
    setErrorMessage,
    userType,
    handleChangeUserType,
    step,
    setStep,
    initialValues,
    onSubmit,
    handleResend,
    email,
    resendEmailSent,
    loadingResendEmailSent,
  } = useRegister(emailUrl);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email(messages.invalidEmail).required('Email is required'),
    birthday: Yup.date().when('role', {
      is: (val: number) => val === UserRoles.PATIENT,
      then: (schema) =>
        schema.required('Birthday is required').max(new Date(), messages.onlyPastDates),
      otherwise: (schema) => schema.notRequired(),
    }),
    ssn: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PATIENT,
      then: (schema) =>
        schema
          .required('SSN is required')
          .min(5, messages.min5charsLength)
          .max(5, messages.min5charsLength),
      otherwise: (schema) => schema.notRequired(),
    }),
    phoneNumber: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('Phone is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    npi: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('NPI is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    institutionName: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('Institution name is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    address: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('Address is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('City is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    state: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('State is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    zipCode: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('Zipcode is required').max(6, messages.min6charsLength),
      otherwise: (schema) => schema.notRequired(),
    }),
    country: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PROVIDER,
      then: (schema) => schema.required('Country is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    termsCheck: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions')
      .required('You must agree to the terms and conditions'),
  });

  const ErrorWrapper = () => {
    if (errorMessage === AuthError.EMAIL_NOT_CONFIRMED) {
      return (
        <>
          <p className={styles.erorr}>
            You already have an account, but it is unconfirmed.{' '}
            <Button
              onClick={() => handleResend({ email })}
              classNames={styles.resendButton}
              type='button'
              style='inlineButton'
            >
              Resend confirmation email.
              {loadingResendEmailSent && <LoadingCircle size={10} />}
            </Button>
          </p>
        </>
      );
    }
    if (errorMessage === AuthError.PROVIDER_NOT_CONFIRMED) {
      return (
        <>
          <div className={styles.erorr}>
            {messages.providerNotConfirmed}
            <Button
              onClick={() => handleResend({ email })}
              classNames={styles.resendButtonValidate}
              type='button'
              style='inlineButton'
            >
              resend validation email.
              {loadingResendEmailSent && <LoadingCircle size={10} />}
            </Button>
          </div>
        </>
      );
    }
    if (errorMessage === AuthError.USER_ALREADY_EXISTS) {
      return <p className={styles.erorr}>Email already verified</p>;
    }
    return <p className={styles.erorr}>{errorMessage}</p>;
  };

  if (step === RegisterStepsEnum.PENDING) {
    return (
      <StatusScreen
        backAction={() => setStep(RegisterStepsEnum.INITIAL)}
        backButton
        svgIcon={<RequestProcessingSvg width={396} height={82} />}
        title={
          <>
            Welcome to <b>MediMint</b>
          </>
        }
        bodyMessage={
          <>
            <p>
              Verification email sent to <b>{email}</b>. Please check your email for a verification
              link.
            </p>
            {userType === UserRoles.PATIENT && (
              <p>Once you&apos;ve verified your email, you can login and use the app.</p>
            )}
            {userType === UserRoles.PROVIDER && (
              <p>
                Once you&apos;ve verified your email, an admin will review your request and approve
                your access.
              </p>
            )}
            {resendEmailSent ? (
              <ConfirmText text='Email resent successfully!' />
            ) : (
              <>
                {errorMessage ? (
                  <ErrorWrapper />
                ) : (
                  <div className={styles.row}>
                    Didn’t get an email?{' '}
                    <Button
                      onClick={() => handleResend({ email })}
                      type='button'
                      style='inlineButton'
                      loading={loadingResendEmailSent}
                    >
                      Resend
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        }
      />
    );
  }
  return (
    <>
      {RegisterStepsEnum.INITIAL && (
        <BackButton onClick={() => navigate(config.routes.signIn)} classNames='back' />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
      >
        {({ setFieldValue, handleChange, values, errors }) => {
          const handleInputChange = (e) => {
            setErrorMessage(undefined);
            handleChange(e);
          };
          return (
            <Form className={styles.signUpForm}>
              <h2 className={styles.formTitle}>
                Get access to <span>MediMint</span>
              </h2>
              <p>Create an account to get access to MediMint.</p>
              <div className={styles.tabs}>
                <Button
                  type='button'
                  style={userType === UserRoles.PATIENT ? 'navy' : 'nakedGrey'}
                  onClick={() => handleChangeUserType(UserRoles.PATIENT, setFieldValue)}
                >
                  User
                </Button>
                <Button
                  type='button'
                  style={userType === UserRoles.PROVIDER ? 'navy' : 'nakedGrey'}
                  onClick={() => handleChangeUserType(UserRoles.PROVIDER, setFieldValue)}
                  disabled={Boolean(emailUrl)}
                >
                  Provider
                </Button>
              </div>
              <div className={styles.inputRow}>
                <InputField
                  label='First Name'
                  name='firstName'
                  type='text'
                  onChange={handleInputChange}
                  required
                />
                <InputField
                  label='Last Name'
                  name='lastName'
                  type='text'
                  onChange={handleInputChange}
                  required
                />
              </div>
              <InputField
                label='Email'
                name='email'
                type='text'
                required
                onChange={handleInputChange}
                readOnly={Boolean(emailUrl)}
              />
              {values.role === UserRoles.PATIENT && (
                <>
                  <InputField
                    name='birthday'
                    type='date'
                    label='Date of birth'
                    onChange={handleInputChange}
                    required
                  />
                  <InputField
                    label='SSN (last 5 digits)'
                    name='ssn'
                    type='password'
                    maxLength={5}
                    regex={/^\d*$/}
                    onChange={handleInputChange}
                    required
                  />
                </>
              )}
              {values.role === UserRoles.PROVIDER && (
                <>
                  <InputField
                    name='phoneNumber'
                    type='phone'
                    label='Phone Number'
                    onChange={handleInputChange}
                    required
                  />
                  <InputField
                    name='npi'
                    type='text'
                    label='NPI'
                    onChange={handleInputChange}
                    required
                  />
                  <InputField
                    name='institutionName'
                    type='text'
                    label='Institution Name'
                    required
                    onChange={handleInputChange}
                  />
                  <InputField
                    name='address'
                    type='text'
                    label='Address 1'
                    onChange={handleInputChange}
                    required
                  />
                  <InputField name='secondAddress' type='text' label='Address 2' />
                  <div className={styles.inputRow}>
                    <InputField
                      label='City'
                      name='city'
                      type='text'
                      onChange={handleInputChange}
                      required
                    />
                    <InputField
                      label='State / County'
                      name='state'
                      type='text'
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <InputField
                    name='zipCode'
                    type='text'
                    label='Zipcode'
                    maxLength={6}
                    regex={/^\d*$/}
                    required
                    onChange={handleInputChange}
                  />
                  <InputField
                    label='Country'
                    name='country'
                    type='select'
                    options={countryOptions}
                    required
                    onChange={handleInputChange}
                  />
                </>
              )}
              <div className={styles.inputRow}>
                <div className={styles.checkboxWrapper}>
                  <Field type='checkbox' name='termsCheck' className={styles.checkboxInput} />
                  <p className={styles.forgotPassword}>
                    By continuing, you agree to the{' '}
                    <Link
                      className={styles.link}
                      to={config.routes.termsAndConditions}
                      target='_blank'
                    >
                      MediMint Customer Agreement
                    </Link>{' '}
                    or other agreement for MediMint services, and the{' '}
                    <Link className={styles.link} to={config.routes.privacyPolicy} target='_blank'>
                      Privacy Notice
                    </Link>
                    . This site uses essential cookies. See our{' '}
                    <Link className={styles.link} to={config.routes.cookiePolicy} target='_blank'>
                      Cookie Notice
                    </Link>{' '}
                    for more information.
                  </p>
                </div>
              </div>
              {errors.termsCheck && (
                <ErrorMessage
                  name={'termsCheck'}
                  render={(msg) => <div className={styles.error}>{msg}</div>}
                />
              )}
              <Button style='navy' size='large' type='submit' loading={loading}>
                Sign up
              </Button>
              {errorMessage && <ErrorWrapper />}
              {resendEmailSent && <ConfirmText text='Email resent successfully!' />}
              <p>
                Already have an account?{' '}
                <span>
                  <Link to={config.routes.signIn}>Sign in</Link>
                </span>
              </p>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Register;
