import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SessionTimeout.module.css';

import Section from '../Section/Section';
import Button from '../Button/Button';
import config from '../../config';
import { ReactComponent as SessionTimeoutSvg } from '../../assets/time_out.svg';
import StatusScreen from '../RegisterScreens/StatusScreen';
import msToMinutes from '../../shared/utils/msToMinutes';

const SessionTimeout = (): ReactElement => {
  const navigate = useNavigate();
  const inactivityTimeoutMs = config.customization.inactivityTimeoutMs;

  return (
    <Section classNames={styles.section}>
      <StatusScreen
        backButton
        backAction={() => navigate(config.routes.signIn)}
        svgIcon={<SessionTimeoutSvg width={109} height={109} />}
        title={<>Session time out</>}
        bodyMessage={
          <div className={styles.body}>
            <p>
              You have been logged out because we didn’t detect any activity in the last{' '}
              {msToMinutes(inactivityTimeoutMs)} minutes.
            </p>
            <div className={styles.row}>
              <Button
                classNames={styles.button}
                onClick={() => navigate(config.routes.signIn)}
                type='button'
                style='navy'
                size='large'
              >
                Login
              </Button>
            </div>
          </div>
        }
      />
    </Section>
  );
};

export default SessionTimeout;
