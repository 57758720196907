import React, { ReactElement } from 'react';
import { GearFill } from 'react-bootstrap-icons';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as LogOutIcon } from '../../assets/logOut.svg';
import styles from './UserMenu.module.css';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../shared/context/user.context';
import config from '../../config';
import cs from 'classnames';

const UserMenu = (): ReactElement => {
  const { logOut, isLoggedIn } = useUserContext();

  const userMenu = [
    {
      icon: <GearFill width={18} height='100%' />,
      label: 'Settings',
      href: config.routes.settings,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.innerIcon}>
        <UserIcon width={24} height='100%' />
      </div>
      <div className={styles.subMenu}>
        <div className={styles.inner}>
          {isLoggedIn && (
            <>
              {userMenu.map((item) => (
                <Link to={item.href} className={styles.link} key={item.label}>
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              ))}
              <hr className={styles.separator} />
              <button className={cs(styles.link, styles.buttonOverride)} onClick={() => logOut()}>
                <LogOutIcon />
                <span>Log out</span>
              </button>
            </>
          )}
          {!isLoggedIn && (
            <Link to={config.routes.signIn} className={styles.link}>
              <UserIcon width={18} height='100%' />
              <span>Log in</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
