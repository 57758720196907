import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ReleasePatientInterface, StudyRequestFormInterface } from '../models/dicom';
import { requestAccess, validateRequestAccess } from '../services/dicom.service';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import filterEmptyStringsFromObject from '../utils/filterEmptyStringsFromObject';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';
import { ReleaseError } from '../enum/releaseError';
import messages from '../staticText/messages';

interface UseRequestHook {
  formLoading: boolean;
  patientValidationdata: StudyRequestFormInterface;
  setPatientValidationData: React.Dispatch<React.SetStateAction<StudyRequestFormInterface>>;
  showSecondStep: boolean;
  setShowSecondStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelRelease: () => void;
  handleRequestForm: (formValues: StudyRequestFormInterface) => Promise<string>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

const useRequest = (): UseRequestHook => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showSecondStep, setShowSecondStep] = useState<boolean>(false);
  const [patientValidationdata, setPatientValidationData] = useState<StudyRequestFormInterface>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onCancelRelease = () => {
    setShowSecondStep(false);
    setFormLoading(false);
  };

  const validateRequestRecordAccess = useCallback(async (data: ReleasePatientInterface) => {
    try {
      setErrorMessage(undefined);
      const validateDataToLowerCase = {
        ...data,
        email: data.email.toLowerCase(),
      };
      await validateRequestAccess(validateDataToLowerCase);
      return true;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === ReleaseError.MATCH_EMAIL) {
          setErrorMessage(messages.emailNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_DOB) {
          setErrorMessage(messages.dobNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_FIRSTNAME) {
          setErrorMessage(messages.firstNameNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_LASTNAME) {
          setErrorMessage(messages.lastNameNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_SSN) {
          setErrorMessage(messages.ssnNotMatchError);
        } else {
          setErrorMessage(messages.errorOccured);
        }
      } else {
        toast.error(messages.errorOccured);
      }
      return false;
    }
  }, []);

  const handleRequestForm = useCallback(
    async (formValues: StudyRequestFormInterface): Promise<string> => {
      try {
        setFormLoading(true);
        const filteredValues = {
          patient: {
            ...formValues.patient,
            birthday: formatDateInheritLocale(formValues.patient.birthday),
          },
          provider: filterEmptyStringsFromObject(formValues.provider),
        };
        const validPatientData = await validateRequestRecordAccess(filteredValues.patient);
        if (!validPatientData) return;

        const request = await requestAccess(filteredValues as StudyRequestFormInterface);
        return request.url;
      } catch (error) {
        console.log(error);
        toast.error('An error occurred during request.');
        return;
      } finally {
        setFormLoading(false);
      }
    },
    [validateRequestRecordAccess],
  );

  return {
    formLoading,
    patientValidationdata,
    setPatientValidationData,
    showSecondStep,
    setShowSecondStep,
    onCancelRelease,
    handleRequestForm,
    errorMessage,
    setErrorMessage,
  };
};

export default useRequest;
