import React, { ReactElement, useEffect } from 'react';
import cx from 'classnames';
import styles from './StudyFiles.module.css';
import StudyFile from '../File/StudyFile';
import useStudy from '../../shared/hooks/useStudy';
import Pagination from '../Pagination/Pagination';
import config from '../../config';

const StudyFiles = ({ studyId }: { studyId: number }): ReactElement => {
  const { fetchStudyFiles, studyFiles, fetchLoading, page, onPageChange } = useStudy();

  useEffect(() => {
    if (studyId) {
      fetchStudyFiles(studyId, page);
    }
  }, [studyId, fetchStudyFiles, page]);

  return (
    <div className={cx(styles.root)}>
      {!fetchLoading && studyFiles?.data.map((file) => <StudyFile key={file.id} file={file} />)}
      {fetchLoading && (
        <>
          {[...Array(5)].map((_, index) => (
            <StudyFile key={index} loadingGhost />
          ))}
        </>
      )}
      {studyFiles?.meta && studyFiles?.meta.totalItems / config.customization.filesPerPage > 1 && (
        <Pagination
          meta={studyFiles.meta}
          page={page}
          onPageChange={onPageChange}
          perPage={config.customization.filesPerPage}
        />
      )}
    </div>
  );
};

export default StudyFiles;
