import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { PaginatedResponse } from '../models/paginatedResponse';
import { getPatientDetails, getProviderPatients } from '../services/provider.service';
import { UserInterface } from '../models/userType';
import { getPatientStudies } from '../services/dicom.service';
import { StudyInterface } from '../models/study';

interface PatientsHook {
  fetchLoading: boolean;
  fetchProviderPatients: ({
    page,
    filters,
  }: {
    page: number;
    filters?: Record<string, string>;
  }) => void;
  patientsList: PaginatedResponse<UserInterface> | undefined;
  page: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  studiesLoading: boolean;
  fetchPatientStudies: ({
    patientId,
    page,
    filters,
  }: {
    patientId: string;
    page: number;
    filters?: Record<string, string>;
  }) => void;
  patientStudies: PaginatedResponse<StudyInterface> | undefined;
  fetchPatientDetails: (patientId: string) => Promise<UserInterface>;
}

const usePatients = (): PatientsHook => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [studiesLoading, setStudiesLoading] = useState<boolean>(true);
  const [patientsList, setPatientsList] = useState<PaginatedResponse<UserInterface>>();
  const [patientStudies, setPatientStudies] = useState<PaginatedResponse<StudyInterface>>();
  const [page, setPage] = useState<number>(0);

  const fetchProviderPatients = useCallback(
    async ({ page, filters }: { page: number; filters?: Record<string, string> }) => {
      try {
        setFetchLoading(true);
        const patients = await getProviderPatients(page, filters);
        setPatientsList(patients);
      } catch (error) {
        toast.error('An error occurred during fetch.');
        console.log(error);
      } finally {
        setFetchLoading(false);
      }
    },
    [],
  );

  const fetchPatientStudies = useCallback(
    async ({
      patientId,
      page,
      filters,
    }: {
      patientId: string;
      page: number;
      filters?: Record<string, string>;
    }) => {
      try {
        setStudiesLoading(true);
        const dicoms = await getPatientStudies(patientId, page, filters);
        setPatientStudies(dicoms);
      } catch (error) {
        toast.error('An error occurred during fetch.');
        console.log(error);
      } finally {
        setStudiesLoading(false);
      }
    },
    [],
  );

  const fetchPatientDetails = useCallback(async (patientId: string) => {
    try {
      setStudiesLoading(true);
      const userData = await getPatientDetails(patientId);
      return userData;
    } catch (error) {
      console.log(error);
    } finally {
      setStudiesLoading(false);
    }
  }, []);

  const onPageChange = useCallback(
    ({ selected }) => {
      setPage(selected);
    },
    [setPage],
  );

  return {
    fetchLoading,
    fetchProviderPatients,
    patientsList,
    page,
    onPageChange,
    studiesLoading,
    fetchPatientStudies,
    patientStudies,
    fetchPatientDetails,
  };
};

export default usePatients;
