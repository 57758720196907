import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserInterface } from '../models/userType';
import { DecodedToken } from '../models/authType';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { axiosInstance } from '../../api/AxiosInstance';
import { AxiosError } from 'axios';
import isOlderThanTimeout from '../utils/olderThan15Mins';

interface UserContextType {
  user: DecodedToken | undefined;
  setUser: (value: DecodedToken) => void;
  userData: UserInterface | undefined;
  setUserData: React.Dispatch<React.SetStateAction<UserInterface | undefined>>;
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  logOut: (route?: string) => void;
  initLoading: boolean;
  refreshUserData: () => Promise<void>;
  emailRequestLoading: boolean;
  emailRequestSent: boolean;
  accessTokenState: string | undefined;
  setAccessTokenState: React.Dispatch<React.SetStateAction<string | undefined>>;
  errorMessage: string;
  handleImportStudiesEmailRequest: () => Promise<void>;
}

const defaultValue: UserContextType = {
  user: undefined,
  setUser: () => {},
  userData: undefined,
  setUserData: undefined,
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  logOut: () => {},
  initLoading: true,
  refreshUserData: async () => {},
  emailRequestLoading: false,
  emailRequestSent: false,
  accessTokenState: '',
  setAccessTokenState: undefined,
  errorMessage: '',
  handleImportStudiesEmailRequest: async () => {},
};
export const UserContext = React.createContext<UserContextType>(defaultValue);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [user, setUser] = useState<DecodedToken>();
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [accessTokenState, setAccessTokenState] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [emailRequestLoading, setEmailRequestLoading] = useState<boolean>(false);
  const [emailRequestSent, setEmailRequestSent] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserInterface | undefined>();

  const navigate = useNavigate();

  const handleImportStudiesEmailRequest = useCallback(async () => {
    try {
      setEmailRequestLoading(true);
      const response = await axiosInstance.post(config.api.importStudiesEmail);
      setEmailRequestSent(true);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        setErrorMessage(error.response.data?.message);
      } else {
        setErrorMessage('An error occurred during register request.');
      }
    } finally {
      setEmailRequestLoading(false);
    }
  }, []);

  const logOut = useCallback(
    (route?: string) => {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userTimeStamp');
      setUser(undefined);
      setIsLoggedIn(false);
      setAccessTokenState(undefined);
      localStorage.setItem('logout-event', JSON.stringify({ time: Date.now(), action: 'logout' }));
      navigate(route || config.routes.signIn);
    },
    [navigate],
  );

  const timeout = config.customization.inactivityTimeoutMs;
  const refreshTimer = config.customization.userDataRefreshTimerMs;

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    let countdown;

    const startCountdown = () => {
      countdown = setInterval(() => {
        const localStorageTimeStamp = localStorage.getItem('userTimeStamp');

        if (localStorageTimeStamp && isOlderThanTimeout(localStorageTimeStamp, timeout)) {
          clearInterval(countdown);
          logOut(config.routes.sessionTimeout);
        }
      }, 1000);
    };

    const handleActivity = () => {
      const timeStampDate = new Date().toISOString();
      localStorage.setItem('userTimeStamp', timeStampDate);
    };

    window.addEventListener('click', handleActivity);
    document.addEventListener('keydown', handleActivity);
    window.addEventListener('load', handleActivity);

    startCountdown();

    return () => {
      if (countdown) {
        clearInterval(countdown);
      }
      window.removeEventListener('click', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      window.removeEventListener('load', handleActivity);
    };
  }, [isLoggedIn, logOut, navigate, timeout]);

  const refreshUserData = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('jwtToken');
      if (typeof accessToken === 'string' && accessToken !== '') {
        setInitLoading(true);
        setEmailRequestSent(false);
        const response = await axiosInstance.get(config.api.userMe);
        setUser({ id: response.data.id, roleId: response.data.roleId });
        setUserData(response.data);
        setIsLoggedIn(true);
      }
    } catch (error) {
      setIsLoggedIn(false);
      localStorage.removeItem('jwtToken');
      setUser(undefined);
    } finally {
      setInitLoading(false);
    }
  }, []);
  useEffect(() => {
    refreshUserData();
  }, [refreshUserData]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const interval = setInterval(() => {
      const tokenFromLocalStorage = localStorage.getItem('jwtToken');
      const localStorageTimeStamp = localStorage.getItem('userTimeStamp');
      if (!tokenFromLocalStorage) {
        logOut(config.routes.signIn);
      }
      if (accessTokenState !== tokenFromLocalStorage) {
        setAccessTokenState(tokenFromLocalStorage);
        refreshUserData();
      }
      if (localStorageTimeStamp && isOlderThanTimeout(localStorageTimeStamp, timeout)) {
        logOut(config.routes.sessionTimeout);
      }
    }, refreshTimer);

    return () => clearInterval(interval);
  }, [accessTokenState, isLoggedIn, logOut, navigate, refreshTimer, refreshUserData, timeout]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logout-event') {
        setUser(undefined);
        setIsLoggedIn(false);
        setAccessTokenState(undefined);
        navigate(config.routes.signIn);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate]);

  const contextValues: UserContextType = useMemo(
    () => ({
      user,
      setUser,
      userData,
      setUserData,
      isLoggedIn,
      setIsLoggedIn,
      logOut,
      initLoading,
      refreshUserData,
      errorMessage,
      emailRequestLoading,
      emailRequestSent,
      handleImportStudiesEmailRequest,
      accessTokenState,
      setAccessTokenState,
    }),
    [
      user,
      userData,
      isLoggedIn,
      logOut,
      initLoading,
      refreshUserData,
      errorMessage,
      emailRequestLoading,
      emailRequestSent,
      handleImportStudiesEmailRequest,
      accessTokenState,
    ],
  );

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
