import React, { ReactElement, FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './style.module.css';
import Section from '../Section/Section';
import BackButton from '../Button/BackButton';

type StatusScreenProps = {
  svgIcon: ReactElement;
  title: ReactNode;
  bodyMessage: ReactNode;
  alignContentLeft?: boolean;
  backButton?: boolean;
  backAction?: () => void;
};

const StatusScreen: FC<StatusScreenProps> = ({
  svgIcon,
  title,
  bodyMessage,
  alignContentLeft,
  backButton,
  backAction,
}): ReactElement => {
  return (
    <Section classNames={cx(alignContentLeft ? styles.confirmWrapper : styles.wrapper)}>
      {backButton && <BackButton classNames={styles.backButton} onClick={backAction} />}
      <div className={cx(styles.wrapper, { [styles.confirmWrapper]: alignContentLeft })}>
        {svgIcon}
        <h2 className={styles.title}>{title}</h2>
        <div className={cx(styles.messageBody, { [styles.width100]: alignContentLeft })}>
          {bodyMessage}
        </div>
      </div>
    </Section>
  );
};

export default StatusScreen;
