import { dicomTags } from '../staticText/rawDicomLabelTags';

interface DicomDataSet {
  string: (tag: string) => string | undefined;
}

export const extractDICOMData = (dataSet: DicomDataSet): Record<string, string> => {
  const extractedData: Record<string, string> = {};
  for (const tag of Object.keys(dicomTags)) {
    const value = dataSet.string(tag.toLowerCase());
    if (value && value.trim() !== '' && (/[0-9]/.test(value) || /[a-zA-Z]/.test(value))) {
      extractedData[tag] = value;
    }
  }
  return extractedData;
};
