export enum ReleaseError {
  PATIENT_DECRYPT = 'Could not decrypt patient data',
  PROVIDER_INACTIVE = 'Provider not active',
  INVALID_PATIENT_DATA = 'Invalid patient id',
  NO_DICOM_UPLOADED = 'No dicom file uploaded',
  MATCH_DOB = 'User data not match: birthday',
  MATCH_FIRSTNAME = 'User data not match: firstName',
  MATCH_LASTNAME = 'User data not match: lastName',
  MATCH_SSN = 'User data not match: ssn',
  MATCH_EMAIL = 'User data not match: email',
}
