import React, { ReactElement, ReactNode, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import cx from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import styles from './InputField.module.css';
import DatePickerField from './DatePicker';
import DisclaimerBox from '../Disclaimer/DisclaimerBox';
import { DisclaimerBoxStyle } from '../../shared/enum/disclaimerBoxStyles';
import CodeInputField from './CodeInputField';
import { SelectInterface } from '../../shared/models/filter';

type InputFieldProps = {
  placeholder?: string;
  name: string;
  label?: string | ReactNode;
  type?: string;
  required?: boolean;
  options?: SelectInterface[];
  disclaimer?: string;
  onChange?: (field: string, newVal: string) => void;
  maxLength?: number;
  regex?: RegExp;
  isCodeField?: boolean;
  classNames?: string;
  icon?: ReactElement;
  readOnly?: boolean;
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    minHeight: 'initial',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    padding: 0,
    left: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'var(--gray-100)' : 'white',
    color: 'black',
    padding: '10px 15px',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
};

const InputField = ({
  placeholder,
  name,
  label,
  type,
  required,
  options,
  disclaimer,
  onChange,
  maxLength,
  regex,
  isCodeField,
  classNames,
  icon,
  readOnly,
}: InputFieldProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const [showText, setShowText] = useState(false);

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if ((type === 'text' || type === 'password') && maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    if ((type === 'text' || type === 'password') && regex && !regex.test(value)) {
      value = getFieldMeta(name).value as string;
    }

    setFieldValue(name, value);
    if (onChange) {
      onChange(name, value);
    }
  };

  const toggleShowText = () => {
    setShowText(!showText);
  };

  const renderInput = () => {
    if (type === 'select' && options) {
      const selectedOption = options.find((option) => option.value === getFieldMeta(name).value);
      return (
        <Select
          options={options}
          onChange={(option: SelectInterface) => setFieldValue('country', option.value)}
          className={cx(styles.input, styles.select, classNames)}
          classNamePrefix={styles.select}
          styles={customSelectStyles}
          value={selectedOption}
        />
      );
    } else if (type === 'date' && !readOnly) {
      return <DatePickerField name={name} onChange={onChange} />;
    } else if (isCodeField) {
      return <CodeInputField name={name} length={maxLength} />;
    } else if (type === 'checkbox') {
      return (
        <div className={cx(styles.inputWrapper, styles.checkboxWrapper)}>
          <Field
            type='checkbox'
            name={name}
            className={cx(styles.checkboxInput, classNames)}
            onChange={onFieldChange}
            readOnly={readOnly}
          />
          <p>{label}</p>
        </div>
      );
    } else if (type === 'phone') {
      return (
        <div className={cx(styles.inputWrapper, styles.checkboxWrapper)}>
          <PhoneInput
            country={'us'}
            value={`${getFieldMeta(name).value}`}
            onChange={(phone) => setFieldValue(`${name}`, phone)}
            containerClass={styles.phoneInputContainer}
            dropdownClass={styles.flagDropdown}
            specialLabel=''
            inputClass={cx(styles.phoneInput, styles.input)}
          />
        </div>
      );
    } else {
      return (
        <div className={cx(styles.inputWrapper, icon && styles.withIcon)}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <Field
            type={showText ? 'text' : type}
            name={name}
            placeholder={placeholder}
            className={cx(styles.input, classNames)}
            onChange={onFieldChange}
            value={getFieldMeta(name).value}
            maxLength={maxLength}
            readOnly={readOnly}
            disabled={readOnly}
            data-tooltip-id={`not_editable_${name}`}
          />
          {type === 'password' && (
            <button onClick={toggleShowText} type='button' className={styles.toggleShowText}>
              {showText ? <EyeSlash /> : <Eye />}
            </button>
          )}
          {readOnly && (
            <Tooltip id={`not_editable_${name}`} content={`${label} field is not editable`} />
          )}
        </div>
      );
    }
  };

  return (
    <div className={cx(styles.root)}>
      {label && type !== 'checkbox' && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      {renderInput()}
      <ErrorMessage name={name} render={(msg) => <div className={styles.error}>{msg}</div>} />
      {disclaimer && <DisclaimerBox type={DisclaimerBoxStyle.INFO} content={disclaimer} />}
    </div>
  );
};

export default InputField;
