import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { PaginatedResponse } from '../models/paginatedResponse';
import { UserInterface } from '../models/userType';
import { getPaginateParams } from '../utils/getPaginateParams';

export const getProviderPatients = async (
  page: number,
  filterParams?: Record<string, string>,
): Promise<PaginatedResponse<UserInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.patientsPerPage);
  const response = await axiosInstance.get(config.api.providerPatients, {
    params: {
      ...pageParams,
      ...filterParams,
    },
  });
  return response.data;
};

export const getPatientDetails = async (patientId: string): Promise<UserInterface> => {
  const response = await axiosInstance.get(config.api.providerPatientId.replace(':id', patientId));
  return response.data;
};
