import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { ActivityInterface } from '../models/activity';
import { PaginatedResponse } from '../models/paginatedResponse';
import { getPaginateParams } from '../utils/getPaginateParams';

export const getUserActivity = async (
  page: number,
): Promise<PaginatedResponse<ActivityInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.activitiesPerPage);
  const response = await axiosInstance.get(config.api.userActivity, {
    params: pageParams,
  });
  return response.data;
};
