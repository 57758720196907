import { useState } from 'react';
import { toast } from 'react-toastify';
import { UserUpdateType } from '../models/userType';
import { updateUser } from '../services/user.service';
import { useUserContext } from '../context/user.context';
import messages from '../staticText/messages';

interface UserHookProps {
  loading: boolean;
  onUpdate: (values: UserUpdateType) => Promise<void>;
  errorMessage: string | undefined;
}

const useUser = (): UserHookProps => {
  const { user, setUserData } = useUserContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onUpdate = async (values: UserUpdateType) => {
    try {
      setLoading(true);
      setErrorMessage(undefined);
      const response = await updateUser({ role: user.roleId, data: values });
      setUserData(response.data);
      toast.success(messages.updatedSuccessfully);
    } catch (error) {
      toast.error(messages.errorTryAgainLater);
      setErrorMessage(messages.errorTryAgainLater);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onUpdate,
    errorMessage,
  };
};

export default useUser;
