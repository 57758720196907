export const formatDateInheritLocale = (dateString: string | Date) => {
  if (!(dateString instanceof Date)) {
    dateString = new Date(dateString);
  }

  const year = dateString.getFullYear();
  const month = dateString.getMonth() + 1;
  const day = dateString.getDate();
  const utcDate = new Date(Date.UTC(year, month - 1, day));
  const isoUtcDate = utcDate.toISOString();

  return isoUtcDate;
};
