import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as ArrowLeft } from '../../assets/arrowLeft.svg';
import styles from './BackButton.module.css';

type ButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  backAlternativeRoute?: string;
  alternativeText?: string;
  classNames?: string;
};

const BackButton = ({
  disabled,
  onClick,
  backAlternativeRoute,
  alternativeText,
  classNames,
}: ButtonProps): ReactElement => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (backAlternativeRoute) {
      navigate(backAlternativeRoute);
      return;
    }
    navigate(-1);
  };

  return (
    <button className={cx(styles.root, classNames)} disabled={disabled} onClick={onButtonClick}>
      <div className={styles.icon}>
        <ArrowLeft width={24} height='100%' />
      </div>
      <span>{alternativeText ?? 'Back'}</span>
    </button>
  );
};

export default BackButton;
