export const formatGender = (initgender: string): string => {
  const initgenderLowerCase = initgender.toLowerCase();
  if (initgenderLowerCase === 'm' || initgenderLowerCase === 'male') {
    return 'Male';
  }
  if (initgenderLowerCase === 'f' || initgenderLowerCase === 'female') {
    return 'Female';
  }
  if (initgenderLowerCase === 'o' || initgenderLowerCase === 'other') {
    return 'Other';
  }
  return initgender;
};
