import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from '../SignIn.module.css';

import config from '../../../config';
import Button from '../../../components/Button/Button';
import { UserRoles } from '../../../shared/enum/userRoles';
import messages from '../../../shared/staticText/messages';
import InputField from '../../../components/Form/InputField';
import { LoginRequestType, RegisterResponseType } from '../../../shared/models/authType';
import { AuthError } from '../../../shared/enum/authErrors';
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle';
import ConfirmText from '../../../components/ConfirmText/ConfirmText';

type EmailFormPropType = {
  initialValues: {
    role: number;
    email: string;
    birthday: string;
    ssn: string;
  };
  handleSubmit: (values: LoginRequestType) => Promise<void>;
  validEmail: boolean;
  handleFormReset: () => void;
  resetError: Dispatch<SetStateAction<string>>;
  errorMessage: string;
  loading: boolean;
  resendEmailSent: boolean;
  handleResend: (email?: string) => Promise<RegisterResponseType>;
  loadingResendEmailSent: boolean;
};

const EmailForm = ({
  initialValues,
  handleSubmit,
  validEmail,
  handleFormReset,
  errorMessage,
  resetError,
  loading,
  handleResend,
  resendEmailSent,
  loadingResendEmailSent,
}: EmailFormPropType) => {
  const initialValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('This field is mandatory'),
    birthday: Yup.date().when('role', {
      is: (val: number) => val === UserRoles.PATIENT,
      then: (schema) =>
        schema.required('Birthday is required').max(new Date(), messages.onlyPastDates),
      otherwise: (schema) => schema.notRequired(),
    }),
    ssn: Yup.string().when('role', {
      is: (val: number) => val === UserRoles.PATIENT,
      then: (schema) =>
        schema
          .required('SSN is required')
          .min(5, messages.min5charsLength)
          .max(5, messages.min5charsLength),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const ErrorWrapper = () => {
    if (errorMessage === AuthError.EMAIL_NOT_CONFIRMED) {
      return (
        <p className={cx([styles.error, styles.centralError])}>
          You already have an account, but it is unconfirmed.{' '}
          <Button
            onClick={() => handleResend()}
            classNames={styles.resendButton}
            type='button'
            style='inlineButton'
          >
            Resend confirmation email.
            {loadingResendEmailSent && <LoadingCircle size={10} />}
          </Button>
        </p>
      );
    }
    if (errorMessage === messages.providerNotConfirmed) {
      return (
        <div className={cx([styles.error])}>
          {messages.providerNotConfirmed}{' '}
          <Button
            onClick={() => handleResend()}
            classNames={styles.resendButtonValidate}
            type='button'
            style='inlineButton'
          >
            Resend validation email.
            {loadingResendEmailSent && <LoadingCircle size={10} />}
          </Button>
        </div>
      );
    }
    return <p className={styles.error}>{errorMessage}</p>;
  };

  const NewAccountPrompt = () => {
    return (
      <div className={styles.newAccountWrapper}>
        <h2 className={styles.title}>
          <span className={styles.line}></span>
          <p className={styles.titleText}>New to MediMint?</p>
          <span className={styles.line}></span>
        </h2>
        <Button
          style='navy'
          size='large'
          type='button'
          toPage={config.routes.register}
          classNames={styles.newAccountButton}
        >
          Create a new account
        </Button>
      </div>
    );
  };

  const PatientInputs = ({ inputCHange }: { inputCHange: (e) => void }) => {
    return (
      <>
        <InputField
          name='birthday'
          type='date'
          label='Date of birth'
          required
          onChange={inputCHange}
        />
        <InputField
          label='SSN (last 5 digits)'
          name='ssn'
          type='password'
          maxLength={5}
          regex={/^\d*$/}
          required
          onChange={inputCHange}
        />
      </>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={initialValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange }) => {
          const handleInputChange = (e) => {
            resetError(undefined);
            handleChange(e);
          };
          return (
            <Form className={styles.signInForm}>
              <h2 className={styles.formTitle}>
                Sign in to <span>MediMint</span>
              </h2>
              <p>Provide your email to get access to your MediMint dashboard</p>
              <div className={styles.formFields}>
                <div>
                  <InputField
                    label='Email'
                    name='email'
                    type='text'
                    required
                    onChange={handleInputChange}
                    readOnly={validEmail}
                  />
                  {validEmail && (
                    <Button
                      style='naked'
                      size='small'
                      type='button'
                      onClick={handleFormReset}
                      classNames={styles.resetButton}
                      icon={<ArrowClockwise width={11} height={11} />}
                    >
                      Change email
                    </Button>
                  )}
                </div>
                {values.role === UserRoles.PATIENT && (
                  <PatientInputs inputCHange={handleInputChange} />
                )}
              </div>
              {errorMessage && <ErrorWrapper />}
              {resendEmailSent && <ConfirmText text='Email resent successfully!' />}
              <Button style='navy' size='large' type='submit' loading={loading}>
                Continue
              </Button>
              <p>
                By continuing, you agree to the{' '}
                <Link className={styles.link} to={config.routes.termsAndConditions} target='_blank'>
                  MediMint Customer Agreement
                </Link>{' '}
                or other agreement for MediMint services, and the{' '}
                <Link className={styles.link} to={config.routes.privacyPolicy} target='_blank'>
                  Privacy Notice
                </Link>
                . This site uses essential cookies. See our{' '}
                <Link className={styles.link} to={config.routes.cookiePolicy} target='_blank'>
                  Cookie Notice
                </Link>{' '}
                for more information.
              </p>
            </Form>
          );
        }}
      </Formik>
      <NewAccountPrompt />
    </>
  );
};

export default EmailForm;
