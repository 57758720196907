const dicomLabels = {
  patientName: `Patient's name`,
  patientFirstName: `Patient's first name`,
  patientLastName: `Patient's last name`,
  patientEmail: `Patient's email`,
  ssn: 'SSN',
  birthday: 'Date of birth',
  patientDOB: 'Patient DOB',
  providerNpi: 'Provider NPI',
  providerName: 'Provider Name',
  referringPhysicianName: 'Referring Physician Name',
  physicianName: 'Physician Name',
  physicianFirstName: 'Physician First Name',
  physicianLastName: 'Physician Last Name',
  performingPhysicianName: 'Performing Physician Name',
  physicianEmail: 'Physician Email',
  radiologistName: 'Radiologist Name',
  radiologistEmail: 'Radiologist Email',
  fileSetId: 'File Set ID',
  imageId: 'Image ID',
  dicomStudyId: 'DICOM Study ID',
  dicomStudyDate: 'Study Date',
  dicomStudyDescription: 'DICOM Study Description',
  studyName: 'Study name',
  dicomAccessionId: 'DICOM Accession ID',
  dicomSeriesId: 'DICOM Series ID',
  dicomSeriesModality: 'DICOM Series Modality',
  dicomSeriesDescription: 'DICOM Series Descriptio',
  dicomSeriesNumber: 'DICOM Series Number',
  dicomBodyPartExamined: 'DICOM Body Part Examined',
  dicomSOPInstanceId: 'DICOM SOP Instance ID',
  patientGender: 'Patinet gender',
};

export default dicomLabels;
