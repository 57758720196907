import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { PaginatedResponse } from '../models/paginatedResponse';
import { getUserActivity } from '../services/activity.service';
import { ActivityInterface } from '../models/activity';

interface ActivityHook {
  fetchLoading: boolean;
  fetchUserActivity: ({ page, isAutoRefresh }: { page: number; isAutoRefresh?: boolean }) => void;
  userActivities: PaginatedResponse<ActivityInterface> | undefined;
  page: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const useActivity = (): ActivityHook => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [userActivities, setUserActivities] = useState<PaginatedResponse<ActivityInterface>>();
  const [page, setPage] = useState<number>(0);

  const fetchUserActivity = useCallback(
    async ({ page, isAutoRefresh }: { page: number; isAutoRefresh?: boolean }) => {
      try {
        !isAutoRefresh && setFetchLoading(true);
        const activities = await getUserActivity(page);
        setUserActivities(activities);
      } catch (error) {
        !isAutoRefresh && toast.error('An error occurred during fetch.');
        console.log(error);
      } finally {
        !isAutoRefresh && setFetchLoading(false);
      }
    },
    [],
  );

  const onPageChange = useCallback(
    ({ selected }) => {
      setPage(selected);
    },
    [setPage],
  );

  return {
    fetchLoading,
    userActivities,
    page,
    onPageChange,
    fetchUserActivity,
  };
};

export default useActivity;
