import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Section from '../../components/Section/Section';
import Pagination from '../../components/Pagination/Pagination';
import config from '../../config';
import usePatients from '../../shared/hooks/usePatients';
import PatientsList from '../../components/PatientsList/PatientsList';
import { FilterInterface } from '../../shared/models/filter';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import Filters from '../../components/Filters/Filters';

const PatientsPage = (): ReactElement => {
  const { patientsList, fetchProviderPatients, fetchLoading, page, onPageChange } = usePatients();

  const [filterParams, setFilterParams] = useState<Record<string, string>>({});
  useEffect(() => {
    fetchProviderPatients({ page, filters: filterParams });
  }, [fetchProviderPatients, page, filterParams]);

  const handleFilterFieldChange = useCallback(
    (field: string, newVal?: string) => {
      setFilterParams((prevData) => {
        const newData = { ...prevData };
        if (!newVal) {
          delete newData[field];
        } else {
          newData[field] = newVal;
        }
        return newData;
      });
      onPageChange({ selected: 0 });
    },
    [onPageChange],
  );

  const filters: FilterInterface[] = [
    {
      name: 'birthday',
      type: FieldTypes.DATE,
      label: 'Date of birth',
    },
  ];

  return (
    <>
      <Section flexSpacing>
        <Filters setFiltersValue={handleFilterFieldChange} filters={filters} />
        {!fetchLoading && Boolean(patientsList?.data.length) && (
          <PatientsList patients={patientsList.data} />
        )}
        {fetchLoading && <PatientsList loadingGhost />}
        {!fetchLoading && patientsList?.data.length === 0 && 'No patients found'}
        {patientsList?.meta &&
          patientsList?.meta.totalItems / config.customization.patientsPerPage > 1 && (
            <Pagination
              meta={patientsList.meta}
              page={page}
              onPageChange={onPageChange}
              perPage={config.customization.patientsPerPage}
            />
          )}
      </Section>
    </>
  );
};

export default PatientsPage;
