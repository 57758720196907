import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import * as dicomParser from 'dicom-parser';
import { DicomAddFormInterface } from '../models/dicom';
import { dicomTags } from '../staticText/rawDicomLabelTags';
import detectDicomDateFormat from '../utils/detectDicomDateFormat';
import { releaseStudy, validateRequestAccess } from '../services/dicom.service';
import { ReleaseSessionDataInterface } from './useExternal';
import messages from '../staticText/messages';
import { ReleaseError } from '../enum/releaseError';
import { extractDICOMData } from '../utils/extractDicomTags';
import extractUserData from '../utils/extractUserData';

interface UseReleaseHook {
  formLoading: boolean;
  handleReleaseForm: (formValue: DicomAddFormInterface) => Promise<number>;
  uploadProgress: number;
  dicomData: Record<string, string> | undefined;
  setFiles: React.Dispatch<React.SetStateAction<FormFile[]>>;
  files: FormFile[];
  setPatientValidationData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  fileErrors: Record<string, string[]>;
  validFilesChecks: boolean;
  showSecondStep: boolean;
  setShowSecondStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelRelease: () => void;
  dicomFileAdded: boolean;
  uploadedStudy: boolean;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  validatingRequestFormLoading: boolean;
  validateReleaseRecordAccess: () => Promise<boolean>;
}

export interface FormFile {
  file: File;
  parsedData?: Record<string, string> | undefined;
  loading?: boolean;
  progress?: number;
  error?: string;
}

const useRelease = ({
  isExternal,
  releaseSessionData,
}: {
  isExternal?: boolean;
  releaseSessionData?: ReleaseSessionDataInterface;
}): UseReleaseHook => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [validatingRequestFormLoading, setValidatingRequestFormLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [dicomData, setDicomData] = useState<Record<string, string>>();
  const [patientValidationdata, setPatientValidationData] = useState<
    Record<string, string> | undefined
  >(
    releaseSessionData?.user && {
      'user.firstName': releaseSessionData.user.firstName,
      'user.lastName': releaseSessionData.user.lastName,
      'user.birthday': releaseSessionData.user.birthday,
    },
  );
  const [files, setFiles] = useState<FormFile[]>([]);
  const [fileErrors, setFileErrors] = useState<Record<string, string[]>>({});
  const [showSecondStep, setShowSecondStep] = useState<boolean>(isExternal);
  const [dicomFileAdded, setDicomFileAdded] = useState<boolean>(false);
  const [uploadedStudy, setUploadedStudy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onCancelRelease = () => {
    setShowSecondStep(false);
    setFiles([]);
    setDicomData(undefined);
    setUploadedStudy(false);
    setUploadProgress(0);
    setErrorMessage(undefined);
  };

  const processDICOMFile = useCallback((formFile: FormFile) => {
    setFiles((prevFiles) =>
      prevFiles.map((f) => (f.file === formFile.file ? { ...f, loading: true } : f)),
    );

    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target && e.target.result) {
        try {
          const dataSet = dicomParser.parseDicom(new Uint8Array(e.target.result as ArrayBuffer));
          const parsedData = extractDICOMData(dataSet);
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file === formFile.file ? { ...f, parsedData, loading: false } : f,
            ),
          );
        } catch (error) {
          console.error('Eroare la parsarea fișierului DICOM', error);
          setFiles((prevFiles) =>
            prevFiles.map((f) => (f.file === formFile.file ? { ...f, loading: false } : f)),
          );
        }
      }
    };
    reader.readAsArrayBuffer(formFile.file);
  }, []);

  useEffect(() => {
    const firstDcmFile = files.find((file) => {
      return file.file.name.toLowerCase().endsWith('.dcm');
    });
    if (!firstDcmFile) {
      setDicomData(undefined);
    }
    if (firstDcmFile && firstDcmFile.parsedData && !firstDcmFile.loading) {
      setDicomData(firstDcmFile.parsedData);
    }
  }, [files]);

  const validateDicomFiles = useCallback(
    (patientValidationData: Record<string, string>) => {
      const newFileErrors: Record<string, string[]> = {};

      const firstDicomFile = files.find(
        (file) => file.file.name.toLowerCase().endsWith('.dcm') && file.parsedData,
      );

      if (firstDicomFile) {
        setDicomFileAdded(true);
      }

      if (!firstDicomFile) {
        setDicomFileAdded(false);
      }

      files.forEach((file) => {
        if (file.file.name.toLowerCase().endsWith('.dcm') && file.parsedData) {
          const errors: string[] = [];

          if (file.parsedData['x00100010'] !== firstDicomFile.parsedData['x00100010']) {
            errors.push('Patient name does not match');
          }

          if (patientValidationData['user.birthday'] && file.parsedData['x00100030']) {
            const birthdayDateFormat = detectDicomDateFormat(
              file.parsedData['x00100030'],
              patientValidationData['user.birthday'],
            );
            if (!birthdayDateFormat) {
              errors.push('Birthday does not match');
            }
          }

          if (firstDicomFile && file !== firstDicomFile) {
            const requiredFields = ['x00100020', 'x0020000D', 'x00100040'];
            requiredFields.forEach((field) => {
              const referenceValue = firstDicomFile.parsedData && firstDicomFile.parsedData[field];
              if (referenceValue && file.parsedData && file.parsedData[field] !== referenceValue) {
                errors.push(`${dicomTags[field]} does not match`);
              }
            });
          }

          if (errors.length) {
            newFileErrors[file.file.name] = errors;
          }
        }
      });

      setFileErrors(newFileErrors);
    },
    [files],
  );

  const validateReleaseRecordAccess = useCallback(async () => {
    const userValidateData = extractUserData(patientValidationdata);
    try {
      setErrorMessage(undefined);
      setValidatingRequestFormLoading(true);
      const validateDataToLowerCase = {
        ...userValidateData,
        email: userValidateData.email.toLowerCase(),
      };
      await validateRequestAccess(validateDataToLowerCase);
      setValidatingRequestFormLoading(false);
      return true;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === ReleaseError.MATCH_EMAIL) {
          setErrorMessage(messages.emailNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_DOB) {
          setErrorMessage(messages.dobNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_FIRSTNAME) {
          setErrorMessage(messages.firstNameNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_LASTNAME) {
          setErrorMessage(messages.lastNameNotMatchError);
        } else if (error.response.data.message === ReleaseError.MATCH_SSN) {
          setErrorMessage(messages.ssnNotMatchError);
        } else {
          setErrorMessage(messages.errorOccured);
        }
      } else {
        toast.error(messages.errorOccured);
      }
      return false;
    } finally {
      setValidatingRequestFormLoading(false);
    }
  }, [patientValidationdata]);

  useEffect(() => {
    if (formLoading) {
      return;
    }
    files.forEach((file) => {
      if (!file.loading && !file.parsedData && file.file.name.toLowerCase().endsWith('.dcm')) {
        processDICOMFile(file);
      }
    });
    validateDicomFiles(patientValidationdata);
  }, [files, formLoading, patientValidationdata, processDICOMFile, validateDicomFiles]);

  const validFilesChecks = useMemo((): boolean => {
    if (Object.keys(fileErrors).length) return false;
    const dicomFileInFiles = files.find((file) => file.file.name.toLowerCase().endsWith('.dcm'));
    if (!dicomFileInFiles) return false;
    return true;
  }, [fileErrors, files]);

  const handleReleaseForm = useCallback(
    async (formValue: DicomAddFormInterface): Promise<number> => {
      try {
        setUploadProgress(0);
        setFormLoading(true);
        setErrorMessage(undefined);
        if (!isExternal) {
          await releaseStudy({ values: formValue, prevalidateRequest: true });
        }

        const filesToSend = files.map((file) => file.file);
        const releasedStudy = await releaseStudy({
          values: formValue,
          files: filesToSend,
          onUploadProgress: setUploadProgress,
          externalToken: isExternal && releaseSessionData?.token,
        });

        setUploadProgress(0);
        setUploadedStudy(true);
        return releasedStudy.id;
      } catch (error) {
        if (error instanceof AxiosError && error.response) {
          if (error.response.data.message === ReleaseError.NO_DICOM_UPLOADED) {
            setErrorMessage(messages.atLeastOneDicom);
            toast.error(messages.atLeastOneDicom);
          } else if (error.response.data.message === ReleaseError.MATCH_DOB) {
            setErrorMessage(messages.dobNotMatchError);
            toast.error(messages.dobNotMatchError);
          } else {
            setErrorMessage(messages.errorOccured);
            toast.error(messages.errorOccured);
          }
        } else {
          toast.error(messages.errorOccured);
        }
        console.log(error);
        return 0;
      } finally {
        setFormLoading(false);
      }
    },
    [files, isExternal, releaseSessionData],
  );

  return {
    formLoading,
    handleReleaseForm,
    uploadProgress,
    dicomData,
    setFiles,
    files,
    setPatientValidationData,
    fileErrors,
    validFilesChecks,
    showSecondStep,
    setShowSecondStep,
    onCancelRelease,
    dicomFileAdded,
    uploadedStudy,
    errorMessage,
    setErrorMessage,
    validatingRequestFormLoading,
    validateReleaseRecordAccess,
  };
};

export default useRelease;
