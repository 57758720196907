export const dicomTags: Record<string, string> = {
  x00020000: 'File Meta Information Group Length',
  x00020001: 'File Meta Information Version',
  x00020002: 'Media Storage SOP Class UID',
  x00020003: 'Media Storage SOP Instance UID',
  x00020010: 'Transfer Syntax UID',
  x00020012: 'Implementation Class UID',
  x00020013: 'Implementation Version Name',
  x00020016: 'Source Application Entity Title',
  x00020017: 'Sending Application Entity Title',
  x00020018: 'Receiving Application Entity Title',
  x00020100: 'Private Information Creator UID',
  x00020102: 'Private Information',
  x00041130: 'File-set ID',
  x00041141: 'File-set Descriptor File ID',
  x00041142: 'Specific Character Set of File-set Descriptor File',
  x00041200: 'Offset of the First Directory Record of the Root Directory Entity',
  x00041202: 'Offset of the Last Directory Record of the Root Directory Entity',
  x00041212: 'File-set Consistency Flag',
  x00041220: 'Directory Record Sequence',
  x00041400: 'Offset of the Next Directory Record',
  x00041410: 'Record In-use Flag',
  x00041420: 'Offset of Referenced Lower-Level Directory Entity',
  x00041430: 'Directory Record Type',
  x00041432: 'Private Record UID',
  x00041500: 'Referenced File ID',
  x00041504: 'MRDR Directory Record Offset',
  x00041510: 'Referenced SOP Class UID in File',
  x00041511: 'Referenced SOP Instance UID in File',
  x00041512: 'Referenced Transfer Syntax UID in File',
  x0004151A: 'Referenced Related General SOP Class UID in File',
  x00041600: 'Number of References',
  x00080001: 'Length to End',
  x00080005: 'Specific Character Set',
  x00080006: 'Language Code Sequence',
  x00080008: 'Image Type',
  x00080010: 'Recognition Code',
  x00080012: 'Instance Creation Date',
  x00080013: 'Instance Creation Time',
  x00080014: 'Instance Creator UID',
  x00080015: 'Instance Coercion DateTime',
  x00080016: 'SOP Class UID',
  x00080018: 'SOP Instance UID',
  x0008001A: 'Related General SOP Class UID',
  x0008001B: 'Original Specialized SOP Class UID',
  x00080020: 'Study Date',
  x00080021: 'Series Date',
  x00080022: 'Acquisition Date',
  x00080023: 'Content Date',
  x00080024: 'Overlay Date',
  x00080025: 'Curve Date',
  x0008002A: 'Acquisition DateTime',
  x00080030: 'Study Time',
  x00080031: 'Series Time',
  x00080032: 'Acquisition Time',
  x00080033: 'Content Time',
  x00080034: 'Overlay Time',
  x00080035: 'Curve Time',
  x00080040: 'Data Set Type',
  x00080041: 'Data Set Subtype',
  x00080042: 'Nuclear Medicine Series Type',
  x00080050: 'Accession Number',
  x00080051: 'Issuer of Accession Number Sequence',
  x00080052: 'Query/Retrieve Level',
  x00080053: 'Query/Retrieve View',
  x00080054: 'Retrieve AE Title',
  x00080055: 'Station AE Title',
  x00080056: 'Instance Availability',
  x00080058: 'Failed SOP Instance UID List',
  x00080060: 'Modality',
  x00080061: 'Modalities in Study',
  x00080062: 'SOP Classes in Study',
  x00080064: 'Conversion Type',
  x00080068: 'Presentation Intent Type',
  x00080070: 'Manufacturer',
  x00080080: 'Institution Name',
  x00080081: 'Institution Address',
  x00080082: 'Institution Code Sequence',
  x00080090: 'Referring Physician`s Name',
  x00080092: 'Referring Physician`s Address',
  x00080094: 'Referring Physician`s Telephone Numbers',
  x00080096: 'Referring Physician Identification Sequence',
  x0008009C: 'Consulting Physician`s Name',
  x0008009D: 'Consulting Physician Identification Sequence',
  x00080100: 'Code Value',
  x00080101: 'Extended Code Value',
  x00080102: 'Coding Scheme Designator',
  x00080103: 'Coding Scheme Version',
  x00080104: 'Code Meaning',
  x00080105: 'Mapping Resource',
  x00080106: 'Context Group Version',
  x00080107: 'Context Group Local Version',
  x00080108: 'Extended Code Meaning',
  x0008010B: 'Context Group Extension Flag',
  x0008010C: 'Coding Scheme UID',
  x0008010D: 'Context Group Extension Creator UID',
  x0008010F: 'Context Identifier',
  x00080110: 'Coding Scheme Identification Sequence',
  x00080112: 'Coding Scheme Registry',
  x00080114: 'Coding Scheme External ID',
  x00080115: 'Coding Scheme Name',
  x00080116: 'Coding Scheme Responsible Organization',
  x00080117: 'Context UID',
  x00080118: 'Mapping Resource UID',
  x00080119: 'Long Code Value',
  x00080120: 'URN Code Value',
  x00080121: 'Equivalent Code Sequence',
  x00080122: 'Mapping Resource Name',
  x00080123: 'Context Group Identification Sequence',
  x00080124: 'Mapping Resource Identification Sequence',
  x00080201: 'Timezone Offset From UTC',
  x00080300: 'Private Data Element Characteristics Sequence',
  x00080301: 'Private Group Reference',
  x00080302: 'Private Creator Reference',
  x00080303: 'Block Identifying Information Status',
  x00080304: 'Nonidentifying Private Elements',
  x00080306: 'Identifying Private Elements',
  x00080305: 'Deidentification Action Sequence',
  x00080307: 'Deidentification Action',
  x00081000: 'Network ID',
  x00081010: 'Station Name',
  x00081030: 'Study Description',
  x00081032: 'Procedure Code Sequence',
  x0008103E: 'Series Description',
  x0008103F: 'Series Description Code Sequence',
  x00081040: 'Institutional Department Name',
  x00081048: 'Physician(s) of Record',
  x00081049: 'Physician(s) of Record Identification Sequence',
  x00081050: 'Performing Physician`s Name',
  x00081052: 'Performing Physician Identification Sequence',
  x00081060: 'Name of Physician(s) Reading Study',
  x00081062: 'Physician(s) Reading Study Identification Sequence',
  x00081070: 'Operators` Name',
  x00081072: 'Operator Identification Sequence',
  x00081080: 'Admitting Diagnoses Description',
  x00081084: 'Admitting Diagnoses Code Sequence',
  x00081090: 'Manufacturer`s Model Name',
  x00081100: 'Referenced Results Sequence',
  x00081110: 'Referenced Study Sequence',
  x00081111: 'Referenced Performed Procedure Step Sequence',
  x00081115: 'Referenced Series Sequence',
  x00081120: 'Referenced Patient Sequence',
  x00081125: 'Referenced Visit Sequence',
  x00081130: 'Referenced Overlay Sequence',
  x00081134: 'Referenced Stereometric Instance Sequence',
  x0008113A: 'Referenced Waveform Sequence',
  x00081140: 'Referenced Image Sequence',
  x00081145: 'Referenced Curve Sequence',
  x0008114A: 'Referenced Instance Sequence',
  x0008114B: 'Referenced Real World Value Mapping Instance Sequence',
  x00081150: 'Referenced SOP Class UID',
  x00081155: 'Referenced SOP Instance UID',
  x0008115A: 'SOP Classes Supported',
  x00081160: 'Referenced Frame Number',
  x00081161: 'Simple Frame List',
  x00081162: 'Calculated Frame List',
  x00081163: 'Time Range',
  x00081164: 'Frame Extraction Sequence',
  x00081167: 'Multi-frame Source SOP Instance UID',
  x00081190: 'Retrieve URL',
  x00081195: 'Transaction UID',
  x00081196: 'Warning Reason',
  x00081197: 'Failure Reason',
  x00081198: 'Failed SOP Sequence',
  x00081199: 'Referenced SOP Sequence',
  x0008119A: 'Other Failures Sequence',
  x00081200: 'Studies Containing Other Referenced Instances Sequence',
  x00081250: 'Related Series Sequence',
  x00082110: 'Lossy Image Compression (Retired)',
  x00082111: 'Derivation Description',
  x00082112: 'Source Image Sequence',
  x00082120: 'Stage Name',
  x00082122: 'Stage Number',
  x00082124: 'Number of Stages',
  x00082127: 'View Name',
  x00082128: 'View Number',
  x00082129: 'Number of Event Timers',
  x0008212A: 'Number of Views in Stage',
  x00082130: 'Event Elapsed Time(s)',
  x00082132: 'Event Timer Name(s)',
  x00082133: 'Event Timer Sequence',
  x00082134: 'Event Time Offset',
  x00082135: 'Event Code Sequence',
  x00082142: 'Start Trim',
  x00082143: 'Stop Trim',
  x00082144: 'Recommended Display Frame Rate',
  x00082200: 'Transducer Position',
  x00082204: 'Transducer Orientation',
  x00082208: 'Anatomic Structure',
  x00082218: 'Anatomic Region Sequence',
  x00082220: 'Anatomic Region Modifier Sequence',
  x00082228: 'Primary Anatomic Structure Sequence',
  x00082229: 'Anatomic Structure, Space or Region Sequence',
  x00082230: 'Primary Anatomic Structure Modifier Sequence',
  x00082240: 'Transducer Position Sequence',
  x00082242: 'Transducer Position Modifier Sequence',
  x00082244: 'Transducer Orientation Sequence',
  x00082246: 'Transducer Orientation Modifier Sequence',
  x00082251: 'Anatomic Structure Space Or Region Code Sequence (Trial)',
  x00082253: 'Anatomic Portal Of Entrance Code Sequence (Trial)',
  x00082255: 'Anatomic Approach Direction Code Sequence (Trial)',
  x00082256: 'Anatomic Perspective Description (Trial)',
  x00082257: 'Anatomic Perspective Code Sequence (Trial)',
  x00082258: 'Anatomic Location Of Examining Instrument Description (Trial)',
  x00082259: 'Anatomic Location Of Examining Instrument Code Sequence (Trial)',
  x0008225A: 'Anatomic Structure Space Or Region Modifier Code Sequence (Trial)',
  x0008225C: 'On Axis Background Anatomic Structure Code Sequence (Trial)',
  x00083001: 'Alternate Representation Sequence',
  x00083010: 'Irradiation Event UID',
  x00083011: 'Source Irradiation Event Sequence',
  x00083012: 'Radiopharmaceutical Administration Event UID',
  x00084000: 'Identifying Comments',
  x00089007: 'Frame Type',
  x00089092: 'Referenced Image Evidence Sequence',
  x00089121: 'Referenced Raw Data Sequence',
  x00089123: 'Creator-Version UID',
  x00089124: 'Derivation Image Sequence',
  x00089154: 'Source Image Evidence Sequence',
  x00089205: 'Pixel Presentation',
  x00089206: 'Volumetric Properties',
  x00089207: 'Volume Based Calculation Technique',
  x00089208: 'Complex Image Component',
  x00089209: 'Acquisition Contrast',
  x00089215: 'Derivation Code Sequence',
  x00089237: 'Referenced Presentation State Sequence',
  x00089410: 'Referenced Other Plane Sequence',
  x00089458: 'Frame Display Sequence',
  x00089459: 'Recommended Display Frame Rate in Float',
  x00089460: 'Skip Frame Range Flag',
  x00100010: 'Patient`s Name',
  x00100020: 'Patient ID',
  x00100021: 'Issuer of Patient ID',
  x00100022: 'Type of Patient ID',
  x00100024: 'Issuer of Patient ID Qualifiers Sequence',
  x00100026: 'Source Patient Group Identification Sequence',
  x00100027: 'Group of Patients Identification Sequence',
  x00100028: 'Subject Relative Position in Image',
  x00100030: 'Patient`s Birth Date',
  x00100032: 'Patient`s Birth Time',
  x00100033: 'Patient`s Birth Date in Alternative Calendar',
  x00100034: 'Patient`s Death Date in Alternative Calendar',
  x00100035: 'Patient`s Alternative Calendar',
  x00100040: 'Patient`s Sex',
  x00100050: 'Patient`s Insurance Plan Code Sequence',
  x00100101: 'Patient`s Primary Language Code Sequence',
  x00100102: 'Patient`s Primary Language Modifier Code Sequence',
  x00100200: 'Quality Control Subject',
  x00100201: 'Quality Control Subject Type Code Sequence',
  x00100212: 'Strain Description',
  x00100213: 'Strain Nomenclature',
  x00100214: 'Strain Stock Number',
  x00100215: 'Strain Source Registry Code Sequence',
  x00100216: 'Strain Stock Sequence',
  x00100217: 'Strain Source',
  x00100218: 'Strain Additional Information',
  x00100219: 'Strain Code Sequence',
  x00101000: 'Other Patient IDs',
  x00101001: 'Other Patient Names',
  x00101002: 'Other Patient IDs Sequence',
  x00101005: 'Patient`s Birth Name',
  x00101010: 'Patient`s Age',
  x00101020: 'Patient`s Size',
  x00101021: 'Patient`s Size Code Sequence',
  x00101030: 'Patient`s Weight',
  x00101040: 'Patient`s Address',
  x00101050: 'Insurance Plan Identification',
  x00101060: 'Patient`s Mother`s Birth Name',
  x00101080: 'Military Rank',
  x00101081: 'Branch of Service',
  x00101090: 'Medical Record Locator',
  x00101100: 'Referenced Patient Photo Sequence',
  x00102000: 'Medical Alerts',
  x00102110: 'Allergies',
  x00102150: 'Country of Residence',
  x00102152: 'Region of Residence',
  x00102154: 'Patient`s Telephone Numbers',
  x00102155: 'Patient`s Telecom Information',
  x00102160: 'Ethnic Group',
  x00102180: 'Occupation',
  x001021A0: 'Smoking Status',
  x001021B0: 'Additional Patient History',
  x001021C0: 'Pregnancy Status',
  x001021D0: 'Last Menstrual Date',
  x001021F0: 'Patient`s Religious Preference',
  x00102201: 'Patient Species Description',
  x00102202: 'Patient Species Code Sequence',
  x00102203: 'Patient`s Sex Neutered',
  x00102210: 'Anatomical Orientation Type',
  x00102292: 'Patient Breed Description',
  x00102293: 'Patient Breed Code Sequence',
  x00102294: 'Breed Registration Sequence',
  x00102295: 'Breed Registration Number',
  x00102296: 'Breed Registry Code Sequence',
  x00102297: 'Responsible Person',
  x00102298: 'Responsible Person Role',
  x00102299: 'Responsible Organization',
  x00104000: 'Patient Comments',
  x00109431: 'Examined Body Thickness',
  x00120010: 'Clinical Trial Sponsor Name',
  x00120020: 'Clinical Trial Protocol ID',
  x00120021: 'Clinical Trial Protocol Name',
  x00120030: 'Clinical Trial Site ID',
  x00120031: 'Clinical Trial Site Name',
  x00120040: 'Clinical Trial Subject ID',
  x00120042: 'Clinical Trial Subject Reading ID',
  x00120050: 'Clinical Trial Time Point ID',
  x00120051: 'Clinical Trial Time Point Description',
  x00120060: 'Clinical Trial Coordinating Center Name',
  x00120062: 'Patient Identity Removed',
  x00120063: 'De-identification Method',
  x00120064: 'De-identification Method Code Sequence',
  x00120071: 'Clinical Trial Series ID',
  x00120072: 'Clinical Trial Series Description',
  x00120081: 'Clinical Trial Protocol Ethics Committee Name',
  x00120082: 'Clinical Trial Protocol Ethics Committee Approval Number',
  x00120083: 'Consent for Clinical Trial Use Sequence',
  x00120084: 'Distribution Type',
  x00120085: 'Consent for Distribution Flag',
  x00140023: 'CAD File Format',
  x00140024: 'Component Reference System',
  x00140025: 'Component Manufacturing Procedure',
  x00140028: 'Component Manufacturer',
  x00140030: 'Material Thickness',
  x00140032: 'Material Pipe Diameter',
  x00140034: 'Material Isolation Diameter',
  x00140042: 'Material Grade',
  x00140044: 'Material Properties Description',
  x00140045: 'Material Properties File Format (Retired)',
  x00140046: 'Material Notes',
  x00140050: 'Component Shape',
  x00140052: 'Curvature Type',
  x00140054: 'Outer Diameter',
  x00140056: 'Inner Diameter',
  x00140100: 'Component Welder IDs',
  x00140101: 'Secondary Approval Status',
  x00140102: 'Secondary Review Date',
  x00140103: 'Secondary Review Time',
  x00140104: 'Secondary Reviewer Name',
  x00140105: 'Repair ID',
  x00140106: 'Multiple Component Approval Sequence',
  x00140107: 'Other Approval Status',
  x00140108: 'Other Secondary Approval Status',
  x00141010: 'Actual Environmental Conditions',
  x00141020: 'Expiry Date',
  x00141040: 'Environmental Conditions',
  x00142002: 'Evaluator Sequence',
  x00142004: 'Evaluator Number',
  x00142006: 'Evaluator Name',
  x00142008: 'Evaluation Attempt',
  x00142012: 'Indication Sequence',
  x00142014: 'Indication Number',
  x00142016: 'Indication Label',
  x00142018: 'Indication Description',
  x0014201A: 'Indication Type',
  x0014201C: 'Indication Disposition',
  x0014201E: 'Indication ROI Sequence',
  x00142030: 'Indication Physical Property Sequence',
  x00142032: 'Property Label',
  x00142202: 'Coordinate System Number of Axes',
  x00142204: 'Coordinate System Axes Sequence',
  x00142206: 'Coordinate System Axis Description',
  x00142208: 'Coordinate System Data Set Mapping',
  x0014220A: 'Coordinate System Axis Number',
  x0014220C: 'Coordinate System Axis Type',
  x0014220E: 'Coordinate System Axis Units',
  x00142210: 'Coordinate System Axis Values',
  x00142220: 'Coordinate System Transform Sequence',
  x00142222: 'Transform Description',
  x00142224: 'Transform Number of Axes',
  x00142226: 'Transform Order of Axes',
  x00142228: 'Transformed Axis Units',
  x0014222A: 'Coordinate System Transform Rotation and Scale Matrix',
  x0014222C: 'Coordinate System Transform Translation Matrix',
  x00143011: 'Internal Detector Frame Time',
  x00143012: 'Number of Frames Integrated',
  x00143020: 'Detector Temperature Sequence',
  x00143022: 'Sensor Name',
  x00143024: 'Horizontal Offset of Sensor',
  x00143026: 'Vertical Offset of Sensor',
  x00143028: 'Sensor Temperature',
  x00143040: 'Dark Current Sequence',
  x00143050: 'Dark Current Counts',
  x00143060: 'Gain Correction Reference Sequence',
  x00143070: 'Air Counts',
  x00143071: 'KV Used in Gain Calibration',
  x00143072: 'MA Used in Gain Calibration',
  x00143073: 'Number of Frames Used for Integration',
  x00143074: 'Filter Material Used in Gain Calibration',
  x00143075: 'Filter Thickness Used in Gain Calibration',
  x00143076: 'Date of Gain Calibration',
  x00143077: 'Time of Gain Calibration',
  x00143080: 'Bad Pixel Image',
  x00143099: 'Calibration Notes',
  x00144002: 'Pulser Equipment Sequence',
  x00144004: 'Pulser Type',
  x00144006: 'Pulser Notes',
  x00144008: 'Receiver Equipment Sequence',
  x0014400A: 'Amplifier Type',
  x0014400C: 'Receiver Notes',
  x0014400E: 'Pre-Amplifier Equipment Sequence',
  x0014400F: 'Pre-Amplifier Notes',
  x00144010: 'Transmit Transducer Sequence',
  x00144011: 'Receive Transducer Sequence',
  x00144012: 'Number of Elements',
  x00144013: 'Element Shape',
  x00144014: 'Element Dimension A',
  x00144015: 'Element Dimension B',
  x00144016: 'Element Pitch A',
  x00144017: 'Measured Beam Dimension A',
  x00144018: 'Measured Beam Dimension B',
  x00144019: 'Location of Measured Beam Diameter',
  x0014401A: 'Nominal Frequency',
  x0014401B: 'Measured Center Frequency',
  x0014401C: 'Measured Bandwidth',
  x0014401D: 'Element Pitch B',
  x00144020: 'Pulser Settings Sequence',
  x00144022: 'Pulse Width',
  x00144024: 'Excitation Frequency',
  x00144026: 'Modulation Type',
  x00144028: 'Damping',
  x00144030: 'Receiver Settings Sequence',
  x00144031: 'Acquired Soundpath Length',
  x00144032: 'Acquisition Compression Type',
  x00144033: 'Acquisition Sample Size',
  x00144034: 'Rectifier Smoothing',
  x00144035: 'DAC Sequence',
  x00144036: 'DAC Type',
  x00144038: 'DAC Gain Points',
  x0014403A: 'DAC Time Points',
  x0014403C: 'DAC Amplitude',
  x00144040: 'Pre-Amplifier Settings Sequence',
  x00144050: 'Transmit Transducer Settings Sequence',
  x00144051: 'Receive Transducer Settings Sequence',
  x00144052: 'Incident Angle',
  x00144054: 'Coupling Technique',
  x00144056: 'Coupling Medium',
  x00144057: 'Coupling Velocity',
  x00144058: 'Probe Center Location X',
  x00144059: 'Probe Center Location Z',
  x0014405A: 'Sound Path Length',
  x0014405C: 'Delay Law Identifier',
  x00144060: 'Gate Settings Sequence',
  x00144062: 'Gate Threshold',
  x00144064: 'Velocity of Sound',
  x00144070: 'Calibration Settings Sequence',
  x00144072: 'Calibration Procedure',
  x00144074: 'Procedure Version',
  x00144076: 'Procedure Creation Date',
  x00144078: 'Procedure Expiration Date',
  x0014407A: 'Procedure Last Modified Date',
  x0014407C: 'Calibration Time',
  x0014407E: 'Calibration Date',
  x00144080: 'Probe Drive Equipment Sequence',
  x00144081: 'Drive Type',
  x00144082: 'Probe Drive Notes',
  x00144083: 'Drive Probe Sequence',
  x00144084: 'Probe Inductance',
  x00144085: 'Probe Resistance',
  x00144086: 'Receive Probe Sequence',
  x00144087: 'Probe Drive Settings Sequence',
  x00144088: 'Bridge Resistors',
  x00144089: 'Probe Orientation Angle',
  x0014408B: 'User Selected Gain Y',
  x0014408C: 'User Selected Phase',
  x0014408D: 'User Selected Offset X',
  x0014408E: 'User Selected Offset Y',
  x00144091: 'Channel Settings Sequence',
  x00144092: 'Channel Threshold',
  x0014409A: 'Scanner Settings Sequence',
  x0014409B: 'Scan Procedure',
  x0014409C: 'Translation Rate X',
  x0014409D: 'Translation Rate Y',
  x0014409F: 'Channel Overlap',
  x001440A0: 'Image Quality Indicator Type',
  x001440A1: 'Image Quality Indicator Material',
  x001440A2: 'Image Quality Indicator Size',
  x00145002: 'LINAC Energy',
  x00145004: 'LINAC Output',
  x00145100: 'Active Aperture',
  x00145101: 'Total Aperture',
  x00145102: 'Aperture Elevation',
  x00145103: 'Main Lobe Angle',
  x00145104: 'Main Roof Angle',
  x00145105: 'Connector Type',
  x00145106: 'Wedge Model Number',
  x00145107: 'Wedge Angle Float',
  x00145108: 'Wedge Roof Angle',
  x00145109: 'Wedge Element 1 Position',
  x0014510A: 'Wedge Material Velocity',
  x0014510B: 'Wedge Material',
  x0014510C: 'Wedge Offset Z',
  x0014510D: 'Wedge Origin Offset X',
  x0014510E: 'Wedge Time Delay',
  x0014510F: 'Wedge Name',
  x00145110: 'Wedge Manufacturer Name',
  x00145111: 'Wedge Description',
  x00145112: 'Nominal Beam Angle',
  x00145113: 'Wedge Offset X',
  x00145114: 'Wedge Offset Y',
  x00145115: 'Wedge Total Length',
  x00145116: 'Wedge In Contact Length',
  x00145117: 'Wedge Front Gap',
  x00145118: 'Wedge Total Height',
  x00145119: 'Wedge Front Height',
  x0014511A: 'Wedge Rear Height',
  x0014511B: 'Wedge Total Width',
  x0014511C: 'Wedge In Contact Width',
  x0014511D: 'Wedge Chamfer Height',
  x0014511E: 'Wedge Curve',
  x0014511F: 'Radius Along the Wedge',
  x00180010: 'Contrast/Bolus Agent',
  x00180012: 'Contrast/Bolus Agent Sequence',
  x00180013: 'Contrast/Bolus T1 Relaxivity',
  x00180014: 'Contrast/Bolus Administration Route Sequence',
  x00180015: 'Body Part Examined',
  x00180020: 'Scanning Sequence',
  x00180021: 'Sequence Variant',
  x00180022: 'Scan Options',
  x00180023: 'MR Acquisition Type',
  x00180024: 'Sequence Name',
  x00180025: 'Angio Flag',
  x00180026: 'Intervention Drug Information Sequence',
  x00180027: 'Intervention Drug Stop Time',
  x00180028: 'Intervention Drug Dose',
  x00180029: 'Intervention Drug Code Sequence',
  x0018002A: 'Additional Drug Sequence',
  x00180030: 'Radionuclide',
  x00180031: 'Radiopharmaceutical',
  x00180032: 'Energy Window Centerline',
  x00180033: 'Energy Window Total Width',
  x00180034: 'Intervention Drug Name',
  x00180035: 'Intervention Drug Start Time',
  x00180036: 'Intervention Sequence',
  x00180037: 'Therapy Type',
  x00180038: 'Intervention Status',
  x00180039: 'Therapy Description',
  x0018003A: 'Intervention Description',
  x00180040: 'Cine Rate',
  x00180042: 'Initial Cine Run State',
  x00180050: 'Slice Thickness',
  x00180060: 'KVP',
  x00180061: '',
  x00180070: 'Counts Accumulated',
  x00180071: 'Acquisition Termination Condition',
  x00180072: 'Effective Duration',
  x00180073: 'Acquisition Start Condition',
  x00180074: 'Acquisition Start Condition Data',
  x00180075: 'Acquisition Termination Condition Data',
  x00180080: 'Repetition Time',
  x00180081: 'Echo Time',
  x00180082: 'Inversion Time',
  x00180083: 'Number of Averages',
  x00180084: 'Imaging Frequency',
  x00180085: 'Imaged Nucleus',
  x00180086: 'Echo Number(s)',
  x00180087: 'Magnetic Field Strength',
  x00180088: 'Spacing Between Slices',
  x00180089: 'Number of Phase Encoding Steps',
  x00180090: 'Data Collection Diameter',
  x00180091: 'Echo Train Length',
  x00180093: 'Percent Sampling',
  x00180094: 'Percent Phase Field of View',
  x00180095: 'Pixel Bandwidth',
  x00181000: 'Device Serial Number',
  x00181002: 'Device UID',
  x00181003: 'Device ID',
  x00181004: 'Plate ID',
  x00181005: 'Generator ID',
  x00181006: 'Grid ID',
  x00181007: 'Cassette ID',
  x00181008: 'Gantry ID',
  x00181010: 'Secondary Capture Device ID',
  x00181011: 'Hardcopy Creation Device ID',
  x00181012: 'Date of Secondary Capture',
  x00181014: 'Time of Secondary Capture',
  x00181016: 'Secondary Capture Device Manufacturer',
  x00181017: 'Hardcopy Device Manufacturer',
  x00181018: 'Secondary Capture Device Manufacturer`s Model Name',
  x00181019: 'Secondary Capture Device Software Versions',
  x0018101A: 'Hardcopy Device Software Version',
  x0018101B: 'Hardcopy Device Manufacturer`s Model Name',
  x00181020: 'Software Version(s)',
  x00181022: 'Video Image Format Acquired',
  x00181023: 'Digital Image Format Acquired',
  x00181030: 'Protocol Name',
  x00181040: 'Contrast/Bolus Route',
  x00181041: 'Contrast/Bolus Volume',
  x00181042: 'Contrast/Bolus Start Time',
  x00181043: 'Contrast/Bolus Stop Time',
  x00181044: 'Contrast/Bolus Total Dose',
  x00181045: 'Syringe Counts',
  x00181046: 'Contrast Flow Rate',
  x00181047: 'Contrast Flow Duration',
  x00181048: 'Contrast/Bolus Ingredient',
  x00181049: 'Contrast/Bolus Ingredient Concentration',
  x00181050: 'Spatial Resolution',
  x00181060: 'Trigger Time',
  x00181061: 'Trigger Source or Type',
  x00181062: 'Nominal Interval',
  x00181063: 'Frame Time',
  x00181064: 'Cardiac Framing Type',
  x00181065: 'Frame Time Vector',
  x00181066: 'Frame Delay',
  x00181067: 'Image Trigger Delay',
  x00181068: 'Multiplex Group Time Offset',
  x00181069: 'Trigger Time Offset',
  x0018106A: 'Synchronization Trigger',
  x0018106C: 'Synchronization Channel',
  x0018106E: 'Trigger Sample Position',
  x00181070: 'Radiopharmaceutical Route',
  x00181071: 'Radiopharmaceutical Volume',
  x00181072: 'Radiopharmaceutical Start Time',
  x00181073: 'Radiopharmaceutical Stop Time',
  x00181074: 'Radionuclide Total Dose',
  x00181075: 'Radionuclide Half Life',
  x00181076: 'Radionuclide Positron Fraction',
  x00181077: 'Radiopharmaceutical Specific Activity',
  x00181078: 'Radiopharmaceutical Start DateTime',
  x00181079: 'Radiopharmaceutical Stop DateTime',
  x00181080: 'Beat Rejection Flag',
  x00181081: 'Low R-R Value',
  x00181082: 'High R-R Value',
  x00181083: 'Intervals Acquired',
  x00181084: 'Intervals Rejected',
  x00181085: 'PVC Rejection',
  x00181086: 'Skip Beats',
  x00181088: 'Heart Rate',
  x00181090: 'Cardiac Number of Images',
  x00181094: 'Trigger Window',
  x00181100: 'Reconstruction Diameter',
  x00181110: 'Distance Source to Detector',
  x00181111: 'Distance Source to Patient',
  x00181114: 'Estimated Radiographic Magnification Factor',
  x00181120: 'Gantry/Detector Tilt',
  x00181121: 'Gantry/Detector Slew',
  x00181130: 'Table Height',
  x00181131: 'Table Traverse',
  x00181134: 'Table Motion',
  x00181135: 'Table Vertical Increment',
  x00181136: 'Table Lateral Increment',
  x00181137: 'Table Longitudinal Increment',
  x00181138: 'Table Angle',
  x0018113A: 'Table Type',
  x00181140: 'Rotation Direction',
  x00181141: 'Angular Position',
  x00181142: 'Radial Position',
  x00181143: 'Scan Arc',
  x00181144: 'Angular Step',
  x00181145: 'Center of Rotation Offset',
  x00181146: 'Rotation Offset',
  x00181147: 'Field of View Shape',
  x00181149: 'Field of View Dimension(s)',
  x00181150: 'Exposure Time',
  x00181151: 'X-Ray Tube Current',
  x00181152: 'Exposure',
  x00181153: 'Exposure in µAs',
  x00181154: 'Average Pulse Width',
  x00181155: 'Radiation Setting',
  x00181156: 'Rectification Type',
  x0018115A: 'Radiation Mode',
  x0018115E: 'Image and Fluoroscopy Area Dose Product',
  x00181160: 'Filter Type',
  x00181161: 'Type of Filters',
  x00181162: 'Intensifier Size',
  x00181164: 'Imager Pixel Spacing',
  x00181166: 'Grid',
  x00181170: 'Generator Power',
  x00181180: 'Collimator/grid Name',
  x00181181: 'Collimator Type',
  x00181182: 'Focal Distance',
  x00181183: 'X Focus Center',
  x00181184: 'Y Focus Center',
  x00181190: 'Focal Spot(s)',
  x00181191: 'Anode Target Material',
  x001811A0: 'Body Part Thickness',
  x001811A2: 'Compression Force',
  x001811A4: 'Paddle Description',
  x00181200: 'Date of Last Calibration',
  x00181201: 'Time of Last Calibration',
  x00181202: 'DateTime of Last Calibration',
  x00181210: 'Convolution Kernel',
  x00181240: 'Upper/Lower Pixel Values',
  x00181242: 'Actual Frame Duration',
  x00181243: 'Count Rate',
  x00181244: 'Preferred Playback Sequencing',
  x00181250: 'Receive Coil Name',
  x00181251: 'Transmit Coil Name',
  x00181260: 'Plate Type',
  x00181261: 'Phosphor Type',
  x00181271: 'Water Equivalent Diameter',
  x00181272: 'Water Equivalent Diameter Calculation Method Code Sequence',
  x00181300: 'Scan Velocity',
  x00181301: 'Whole Body Technique',
  x00181302: 'Scan Length',
  x00181310: 'Acquisition Matrix',
  x00181312: 'In-plane Phase Encoding Direction',
  x00181314: 'Flip Angle',
  x00181315: 'Variable Flip Angle Flag',
  x00181316: 'SAR',
  x00181318: 'dB/dt',
  x00181320: 'B1rms',
  x00181400: 'Acquisition Device Processing Description',
  x00181401: 'Acquisition Device Processing Code',
  x00181402: 'Cassette Orientation',
  x00181403: 'Cassette Size',
  x00181404: 'Exposures on Plate',
  x00181405: 'Relative X-Ray Exposure',
  x00181411: 'Exposure Index',
  x00181412: 'Target Exposure Index',
  x00181413: 'Deviation Index',
  x00181450: 'Column Angulation',
  x00181460: 'Tomo Layer Height',
  x00181470: 'Tomo Angle',
  x00181480: 'Tomo Time',
  x00181490: 'Tomo Type',
  x00181491: 'Tomo Class',
  x00181495: 'Number of Tomosynthesis Source Images',
  x00181500: 'Positioner Motion',
  x00181508: 'Positioner Type',
  x00181510: 'Positioner Primary Angle',
  x00181511: 'Positioner Secondary Angle',
  x00181520: 'Positioner Primary Angle Increment',
  x00181521: 'Positioner Secondary Angle Increment',
  x00181530: 'Detector Primary Angle',
  x00181531: 'Detector Secondary Angle',
  x00181600: 'Shutter Shape',
  x00181602: 'Shutter Left Vertical Edge',
  x00181604: 'Shutter Right Vertical Edge',
  x00181606: 'Shutter Upper Horizontal Edge',
  x00181608: 'Shutter Lower Horizontal Edge',
  x00181610: 'Center of Circular Shutter',
  x00181612: 'Radius of Circular Shutter',
  x00181620: 'Vertices of the Polygonal Shutter',
  x00181622: 'Shutter Presentation Value',
  x00181623: 'Shutter Overlay Group',
  x00181624: 'Shutter Presentation Color CIELab Value',
  x00181700: 'Collimator Shape',
  x00181702: 'Collimator Left Vertical Edge',
  x00181704: 'Collimator Right Vertical Edge',
  x00181706: 'Collimator Upper Horizontal Edge',
  x00181708: 'Collimator Lower Horizontal Edge',
  x00181710: 'Center of Circular Collimator',
  x00181712: 'Radius of Circular Collimator',
  x00181720: 'Vertices of the Polygonal Collimator',
  x00181800: 'Acquisition Time Synchronized',
  x00181801: 'Time Source',
  x00181802: 'Time Distribution Protocol',
  x00181803: 'NTP Source Address',
  x00182001: 'Page Number Vector',
  x00182002: 'Frame Label Vector',
  x00182003: 'Frame Primary Angle Vector',
  x00182004: 'Frame Secondary Angle Vector',
  x00182005: 'Slice Location Vector',
  x00182006: 'Display Window Label Vector',
  x00182010: 'Nominal Scanned Pixel Spacing',
  x00182020: 'Digitizing Device Transport Direction',
  x00182030: 'Rotation of Scanned Film',
  x00182041: 'Biopsy Target Sequence',
  x00182042: 'Target UID',
  x00182043: 'Localizing Cursor Position',
  x00182044: 'Calculated Target Position',
  x00182045: 'Target Label',
  x00182046: 'Displayed Z Value',
  x00183100: 'IVUS Acquisition',
  x00183101: 'IVUS Pullback Rate',
  x00183102: 'IVUS Gated Rate',
  x00183103: 'IVUS Pullback Start Frame Number',
  x00183104: 'IVUS Pullback Stop Frame Number',
  x00183105: 'Lesion Number',
  x00184000: 'Acquisition Comments',
  x00185000: 'Output Power',
  x00185010: 'Transducer Data',
  x00185012: 'Focus Depth',
  x00185020: 'Processing Function',
  x00185021: 'Postprocessing Function',
  x00185022: 'Mechanical Index',
  x00185024: 'Bone Thermal Index',
  x00185026: 'Cranial Thermal Index',
  x00185027: 'Soft Tissue Thermal Index',
  x00185028: 'Soft Tissue-focus Thermal Index',
  x00185029: 'Soft Tissue-surface Thermal Index',
  x00185030: 'Dynamic Range',
  x00185040: 'Total Gain',
  x00185050: 'Depth of Scan Field',
  x00185100: 'Patient Position',
  x00185101: 'View Position',
  x00185104: 'Projection Eponymous Name Code Sequence',
  x00185210: 'Image Transformation Matrix',
  x00185212: 'Image Translation Vector',
  x00186000: 'Sensitivity',
  x00186011: 'Sequence of Ultrasound Regions',
  x00186012: 'Region Spatial Format',
  x00186014: 'Region Data Type',
  x00186016: 'Region Flags',
  x00186018: 'Region Location Min X0',
  x0018601A: 'Region Location Min Y0',
  x0018601C: 'Region Location Max X1',
  x0018601E: 'Region Location Max Y1',
  x00186020: 'Reference Pixel X0',
  x00186022: 'Reference Pixel Y0',
  x00186024: 'Physical Units X Direction',
  x00186026: 'Physical Units Y Direction',
  x00186028: 'Reference Pixel Physical Value X',
  x0018602A: 'Reference Pixel Physical Value Y',
  x0018602C: 'Physical Delta X',
  x0018602E: 'Physical Delta Y',
  x00186030: 'Transducer Frequency',
  x00186031: 'Transducer Type',
  x00186032: 'Pulse Repetition Frequency',
  x00186034: 'Doppler Correction Angle',
  x00186036: 'Steering Angle',
  x00186038: 'Doppler Sample Volume X Position (Retired)',
  x00186039: 'Doppler Sample Volume X Position',
  x0018603A: 'Doppler Sample Volume Y Position (Retired)',
  x0018603B: 'Doppler Sample Volume Y Position',
  x0018603C: 'TM-Line Position X0 (Retired)',
  x0018603D: 'TM-Line Position X0',
  x0018603E: 'TM-Line Position Y0 (Retired)',
  x0018603F: 'TM-Line Position Y0',
  x00186040: 'TM-Line Position X1 (Retired)',
  x00186041: 'TM-Line Position X1',
  x00186042: 'TM-Line Position Y1 (Retired)',
  x00186043: 'TM-Line Position Y1',
  x00186044: 'Pixel Component Organization',
  x00186046: 'Pixel Component Mask',
  x00186048: 'Pixel Component Range Start',
  x0018604A: 'Pixel Component Range Stop',
  x0018604C: 'Pixel Component Physical Units',
  x0018604E: 'Pixel Component Data Type',
  x00186050: 'Number of Table Break Points',
  x00186052: 'Table of X Break Points',
  x00186054: 'Table of Y Break Points',
  x00186056: 'Number of Table Entries',
  x00186058: 'Table of Pixel Values',
  x0018605A: 'Table of Parameter Values',
  x00186060: 'R Wave Time Vector',
  x00187000: 'Detector Conditions Nominal Flag',
  x00187001: 'Detector Temperature',
  x00187004: 'Detector Type',
  x00187005: 'Detector Configuration',
  x00187006: 'Detector Description',
  x00187008: 'Detector Mode',
  x0018700A: 'Detector ID',
  x0018700C: 'Date of Last Detector Calibration',
  x0018700E: 'Time of Last Detector Calibration',
  x00187010: 'Exposures on Detector Since Last Calibration',
  x00187011: 'Exposures on Detector Since Manufactured',
  x00187012: 'Detector Time Since Last Exposure',
  x00187014: 'Detector Active Time',
  x00187016: 'Detector Activation Offset From Exposure',
  x0018701A: 'Detector Binning',
  x00187020: 'Detector Element Physical Size',
  x00187022: 'Detector Element Spacing',
  x00187024: 'Detector Active Shape',
  x00187026: 'Detector Active Dimension(s)',
  x00187028: 'Detector Active Origin',
  x0018702A: 'Detector Manufacturer Name',
  x0018702B: 'Detector Manufacturer`s Model Name',
  x00187030: 'Field of View Origin',
  x00187032: 'Field of View Rotation',
  x00187034: 'Field of View Horizontal Flip',
  x00187036: 'Pixel Data Area Origin Relative To FOV',
  x00187038: 'Pixel Data Area Rotation Angle Relative To FOV',
  x00187040: 'Grid Absorbing Material',
  x00187041: 'Grid Spacing Material',
  x00187042: 'Grid Thickness',
  x00187044: 'Grid Pitch',
  x00187046: 'Grid Aspect Ratio',
  x00187048: 'Grid Period',
  x0018704C: 'Grid Focal Distance',
  x00187050: 'Filter Material',
  x00187052: 'Filter Thickness Minimum',
  x00187054: 'Filter Thickness Maximum',
  x00187056: 'Filter Beam Path Length Minimum',
  x00187058: 'Filter Beam Path Length Maximum',
  x00187060: 'Exposure Control Mode',
  x00187062: 'Exposure Control Mode Description',
  x00187064: 'Exposure Status',
  x00187065: 'Phototimer Setting',
  x00188150: 'Exposure Time in µS',
  x00188151: 'X-Ray Tube Current in µA',
  x00189004: 'Content Qualification',
  x00189005: 'Pulse Sequence Name',
  x00189006: 'MR Imaging Modifier Sequence',
  x00189008: 'Echo Pulse Sequence',
  x00189009: 'Inversion Recovery',
  x00189010: 'Flow Compensation',
  x00189011: 'Multiple Spin Echo',
  x00189012: 'Multi-planar Excitation',
  x00189014: 'Phase Contrast',
  x00189015: 'Time of Flight Contrast',
  x00189016: 'Spoiling',
  x00189017: 'Steady State Pulse Sequence',
  x00189018: 'Echo Planar Pulse Sequence',
  x00189019: 'Tag Angle First Axis',
  x00189020: 'Magnetization Transfer',
  x00189021: 'T2 Preparation',
  x00189022: 'Blood Signal Nulling',
  x00189024: 'Saturation Recovery',
  x00189025: 'Spectrally Selected Suppression',
  x00189026: 'Spectrally Selected Excitation',
  x00189027: 'Spatial Pre-saturation',
  x00189028: 'Tagging',
  x00189029: 'Oversampling Phase',
  x00189030: 'Tag Spacing First Dimension',
  x00189032: 'Geometry of k-Space Traversal',
  x00189033: 'Segmented k-Space Traversal',
  x00189034: 'Rectilinear Phase Encode Reordering',
  x00189035: 'Tag Thickness',
  x00189036: 'Partial Fourier Direction',
  x00189037: 'Cardiac Synchronization Technique',
  x00189041: 'Receive Coil Manufacturer Name',
  x00189042: 'MR Receive Coil Sequence',
  x00189043: 'Receive Coil Type',
  x00189044: 'Quadrature Receive Coil',
  x00189045: 'Multi-Coil Definition Sequence',
  x00189046: 'Multi-Coil Configuration',
  x00189047: 'Multi-Coil Element Name',
  x00189048: 'Multi-Coil Element Used',
  x00189049: 'MR Transmit Coil Sequence',
  x00189050: 'Transmit Coil Manufacturer Name',
  x00189051: 'Transmit Coil Type',
  x00189052: 'Spectral Width',
  x00189053: 'Chemical Shift Reference',
  x00189054: 'Volume Localization Technique',
  x00189058: 'MR Acquisition Frequency Encoding Steps',
  x00189059: 'De-coupling',
  x00189060: 'De-coupled Nucleus',
  x00189061: 'De-coupling Frequency',
  x00189062: 'De-coupling Method',
  x00189063: 'De-coupling Chemical Shift Reference',
  x00189064: 'k-space Filtering',
  x00189065: 'Time Domain Filtering',
  x00189066: 'Number of Zero Fills',
  x00189067: 'Baseline Correction',
  x00189069: 'Parallel Reduction Factor In-plane',
  x00189070: 'Cardiac R-R Interval Specified',
  x00189073: 'Acquisition Duration',
  x00189074: 'Frame Acquisition DateTime',
  x00189075: 'Diffusion Directionality',
  x00189076: 'Diffusion Gradient Direction Sequence',
  x00189077: 'Parallel Acquisition',
  x00189078: 'Parallel Acquisition Technique',
  x00189079: 'Inversion Times',
  x00189080: 'Metabolite Map Description',
  x00189081: 'Partial Fourier',
  x00189082: 'Effective Echo Time',
  x00189083: 'Metabolite Map Code Sequence',
  x00189084: 'Chemical Shift Sequence',
  x00189085: 'Cardiac Signal Source',
  x00189087: 'Diffusion b-value',
  x00189089: 'Diffusion Gradient Orientation',
  x00189090: 'Velocity Encoding Direction',
  x00189091: 'Velocity Encoding Minimum Value',
  x00189092: 'Velocity Encoding Acquisition Sequence',
  x00189093: 'Number of k-Space Trajectories',
  x00189094: 'Coverage of k-Space',
  x00189095: 'Spectroscopy Acquisition Phase Rows',
  x00189096: 'Parallel Reduction Factor In-plane (Retired)',
  x00189098: 'Transmitter Frequency',
  x00189100: 'Resonant Nucleus',
  x00189101: 'Frequency Correction',
  x00189103: 'MR Spectroscopy FOV/Geometry Sequence',
  x00189104: 'Slab Thickness',
  x00189105: 'Slab Orientation',
  x00189106: 'Mid Slab Position',
  x00189107: 'MR Spatial Saturation Sequence',
  x00189112: 'MR Timing and Related Parameters Sequence',
  x00189114: 'MR Echo Sequence',
  x00189115: 'MR Modifier Sequence',
  x00189117: 'MR Diffusion Sequence',
  x00189118: 'Cardiac Synchronization Sequence',
  x00189119: 'MR Averages Sequence',
  x00189125: 'MR FOV/Geometry Sequence',
  x00189126: 'Volume Localization Sequence',
  x00189127: 'Spectroscopy Acquisition Data Columns',
  x00189147: 'Diffusion Anisotropy Type',
  x00189151: 'Frame Reference DateTime',
  x00189152: 'MR Metabolite Map Sequence',
  x00189155: 'Parallel Reduction Factor out-of-plane',
  x00189159: 'Spectroscopy Acquisition Out-of-plane Phase Steps',
  x00189166: 'Bulk Motion Status',
  x00189168: 'Parallel Reduction Factor Second In-plane',
  x00189169: 'Cardiac Beat Rejection Technique',
  x00189170: 'Respiratory Motion Compensation Technique',
  x00189171: 'Respiratory Signal Source',
  x00189172: 'Bulk Motion Compensation Technique',
  x00189173: 'Bulk Motion Signal Source',
  x00189174: 'Applicable Safety Standard Agency',
  x00189175: 'Applicable Safety Standard Description',
  x00189176: 'Operating Mode Sequence',
  x00189177: 'Operating Mode Type',
  x00189178: 'Operating Mode',
  x00189179: 'Specific Absorption Rate Definition',
  x00189180: 'Gradient Output Type',
  x00189181: 'Specific Absorption Rate Value',
  x00189182: 'Gradient Output',
  x00189183: 'Flow Compensation Direction',
  x00189184: 'Tagging Delay',
  x00189185: 'Respiratory Motion Compensation Technique Description',
  x00189186: 'Respiratory Signal Source ID',
  x00189195: 'Chemical Shift Minimum Integration Limit in Hz',
  x00189196: 'Chemical Shift Maximum Integration Limit in Hz',
  x00189197: 'MR Velocity Encoding Sequence',
  x00189198: 'First Order Phase Correction',
  x00189199: 'Water Referenced Phase Correction',
  x00189200: 'MR Spectroscopy Acquisition Type',
  x00189214: 'Respiratory Cycle Position',
  x00189217: 'Velocity Encoding Maximum Value',
  x00189218: 'Tag Spacing Second Dimension',
  x00189219: 'Tag Angle Second Axis',
  x00189220: 'Frame Acquisition Duration',
  x00189226: 'MR Image Frame Type Sequence',
  x00189227: 'MR Spectroscopy Frame Type Sequence',
  x00189231: 'MR Acquisition Phase Encoding Steps in-plane',
  x00189232: 'MR Acquisition Phase Encoding Steps out-of-plane',
  x00189234: 'Spectroscopy Acquisition Phase Columns',
  x00189236: 'Cardiac Cycle Position',
  x00189239: 'Specific Absorption Rate Sequence',
  x00189240: 'RF Echo Train Length',
  x00189241: 'Gradient Echo Train Length',
  x00189250: 'Arterial Spin Labeling Contrast',
  x00189251: 'MR Arterial Spin Labeling Sequence',
  x00189252: 'ASL Technique Description',
  x00189253: 'ASL Slab Number',
  x00189254: 'ASL Slab Thickness',
  x00189255: 'ASL Slab Orientation',
  x00189256: 'ASL Mid Slab Position',
  x00189257: 'ASL Context',
  x00189258: 'ASL Pulse Train Duration',
  x00189259: 'ASL Crusher Flag',
  x0018925A: 'ASL Crusher Flow Limit',
  x0018925B: 'ASL Crusher Description',
  x0018925C: 'ASL Bolus Cut-off Flag',
  x0018925D: 'ASL Bolus Cut-off Timing Sequence',
  x0018925E: 'ASL Bolus Cut-off Technique',
  x0018925F: 'ASL Bolus Cut-off Delay Time',
  x00189260: 'ASL Slab Sequence',
  x00189295: 'Chemical Shift Minimum Integration Limit in ppm',
  x00189296: 'Chemical Shift Maximum Integration Limit in ppm',
  x00189297: 'Water Reference Acquisition',
  x00189298: 'Echo Peak Position',
  x00189301: 'CT Acquisition Type Sequence',
  x00189302: 'Acquisition Type',
  x00189303: 'Tube Angle',
  x00189304: 'CT Acquisition Details Sequence',
  x00189305: 'Revolution Time',
  x00189306: 'Single Collimation Width',
  x00189307: 'Total Collimation Width',
  x00189308: 'CT Table Dynamics Sequence',
  x00189309: 'Table Speed',
  x00189310: 'Table Feed per Rotation',
  x00189311: 'Spiral Pitch Factor',
  x00189312: 'CT Geometry Sequence',
  x00189313: 'Data Collection Center (Patient)',
  x00189314: 'CT Reconstruction Sequence',
  x00189315: 'Reconstruction Algorithm',
  x00189316: 'Convolution Kernel Group',
  x00189317: 'Reconstruction Field of View',
  x00189318: 'Reconstruction Target Center (Patient)',
  x00189319: 'Reconstruction Angle',
  x00189320: 'Image Filter',
  x00189321: 'CT Exposure Sequence',
  x00189322: 'Reconstruction Pixel Spacing',
  x00189323: 'Exposure Modulation Type',
  x00189324: 'Estimated Dose Saving',
  x00189325: 'CT X-Ray Details Sequence',
  x00189326: 'CT Position Sequence',
  x00189327: 'Table Position',
  x00189328: 'Exposure Time in ms',
  x00189329: 'CT Image Frame Type Sequence',
  x00189330: 'X-Ray Tube Current in mA',
  x00189332: 'Exposure in mAs',
  x00189333: 'Constant Volume Flag',
  x00189334: 'Fluoroscopy Flag',
  x00189335: 'Distance Source to Data Collection Center',
  x00189337: 'Contrast/Bolus Agent Number',
  x00189338: 'Contrast/Bolus Ingredient Code Sequence',
  x00189340: 'Contrast Administration Profile Sequence',
  x00189341: 'Contrast/Bolus Usage Sequence',
  x00189342: 'Contrast/Bolus Agent Administered',
  x00189343: 'Contrast/Bolus Agent Detected',
  x00189344: 'Contrast/Bolus Agent Phase',
  x00189345: 'CTDIvol',
  x00189346: 'CTDI Phantom Type Code Sequence',
  x00189351: 'Calcium Scoring Mass Factor Patient',
  x00189352: 'Calcium Scoring Mass Factor Device',
  x00189353: 'Energy Weighting Factor',
  x00189360: 'CT Additional X-Ray Source Sequence',
  x00189401: 'Projection Pixel Calibration Sequence',
  x00189402: 'Distance Source to Isocenter',
  x00189403: 'Distance Object to Table Top',
  x00189404: 'Object Pixel Spacing in Center of Beam',
  x00189405: 'Positioner Position Sequence',
  x00189406: 'Table Position Sequence',
  x00189407: 'Collimator Shape Sequence',
  x00189410: 'Planes in Acquisition',
  x00189412: 'XA/XRF Frame Characteristics Sequence',
  x00189417: 'Frame Acquisition Sequence',
  x00189420: 'X-Ray Receptor Type',
  x00189423: 'Acquisition Protocol Name',
  x00189424: 'Acquisition Protocol Description',
  x00189425: 'Contrast/Bolus Ingredient Opaque',
  x00189426: 'Distance Receptor Plane to Detector Housing',
  x00189427: 'Intensifier Active Shape',
  x00189428: 'Intensifier Active Dimension(s)',
  x00189429: 'Physical Detector Size',
  x00189430: 'Position of Isocenter Projection',
  x00189432: 'Field of View Sequence',
  x00189433: 'Field of View Description',
  x00189434: 'Exposure Control Sensing Regions Sequence',
  x00189435: 'Exposure Control Sensing Region Shape',
  x00189436: 'Exposure Control Sensing Region Left Vertical Edge',
  x00189437: 'Exposure Control Sensing Region Right Vertical Edge',
  x00189438: 'Exposure Control Sensing Region Upper Horizontal Edge',
  x00189439: 'Exposure Control Sensing Region Lower Horizontal Edge',
  x00189440: 'Center of Circular Exposure Control Sensing Region',
  x00189441: 'Radius of Circular Exposure Control Sensing Region',
  x00189442: 'Vertices of the Polygonal Exposure Control Sensing Region',
  x00189445: '',
  x00189447: 'Column Angulation (Patient)',
  x00189449: 'Beam Angle',
  x00189451: 'Frame Detector Parameters Sequence',
  x00189452: 'Calculated Anatomy Thickness',
  x00189455: 'Calibration Sequence',
  x00189456: 'Object Thickness Sequence',
  x00189457: 'Plane Identification',
  x00189461: 'Field of View Dimension(s) in Float',
  x00189462: 'Isocenter Reference System Sequence',
  x00189463: 'Positioner Isocenter Primary Angle',
  x00189464: 'Positioner Isocenter Secondary Angle',
  x00189465: 'Positioner Isocenter Detector Rotation Angle',
  x00189466: 'Table X Position to Isocenter',
  x00189467: 'Table Y Position to Isocenter',
  x00189468: 'Table Z Position to Isocenter',
  x00189469: 'Table Horizontal Rotation Angle',
  x00189470: 'Table Head Tilt Angle',
  x00189471: 'Table Cradle Tilt Angle',
  x00189472: 'Frame Display Shutter Sequence',
  x00189473: 'Acquired Image Area Dose Product',
  x00189474: 'C-arm Positioner Tabletop Relationship',
  x00189476: 'X-Ray Geometry Sequence',
  x00189477: 'Irradiation Event Identification Sequence',
  x00189504: 'X-Ray 3D Frame Type Sequence',
  x00189506: 'Contributing Sources Sequence',
  x00189507: 'X-Ray 3D Acquisition Sequence',
  x00189508: 'Primary Positioner Scan Arc',
  x00189509: 'Secondary Positioner Scan Arc',
  x00189510: 'Primary Positioner Scan Start Angle',
  x00189511: 'Secondary Positioner Scan Start Angle',
  x00189514: 'Primary Positioner Increment',
  x00189515: 'Secondary Positioner Increment',
  x00189516: 'Start Acquisition DateTime',
  x00189517: 'End Acquisition DateTime',
  x00189518: 'Primary Positioner Increment Sign',
  x00189519: 'Secondary Positioner Increment Sign',
  x00189524: 'Application Name',
  x00189525: 'Application Version',
  x00189526: 'Application Manufacturer',
  x00189527: 'Algorithm Type',
  x00189528: 'Algorithm Description',
  x00189530: 'X-Ray 3D Reconstruction Sequence',
  x00189531: 'Reconstruction Description',
  x00189538: 'Per Projection Acquisition Sequence',
  x00189541: 'Detector Position Sequence',
  x00189542: 'X-Ray Acquisition Dose Sequence',
  x00189543: 'X-Ray Source Isocenter Primary Angle',
  x00189544: 'X-Ray Source Isocenter Secondary Angle',
  x00189545: 'Breast Support Isocenter Primary Angle',
  x00189546: 'Breast Support Isocenter Secondary Angle',
  x00189547: 'Breast Support X Position to Isocenter',
  x00189548: 'Breast Support Y Position to Isocenter',
  x00189549: 'Breast Support Z Position to Isocenter',
  x00189550: 'Detector Isocenter Primary Angle',
  x00189551: 'Detector Isocenter Secondary Angle',
  x00189552: 'Detector X Position to Isocenter',
  x00189553: 'Detector Y Position to Isocenter',
  x00189554: 'Detector Z Position to Isocenter',
  x00189555: 'X-Ray Grid Sequence',
  x00189556: 'X-Ray Filter Sequence',
  x00189557: 'Detector Active Area TLHC Position',
  x00189558: 'Detector Active Area Orientation',
  x00189559: 'Positioner Primary Angle Direction',
  x00189601: 'Diffusion b-matrix Sequence',
  x00189602: 'Diffusion b-value XX',
  x00189603: 'Diffusion b-value XY',
  x00189604: 'Diffusion b-value XZ',
  x00189605: 'Diffusion b-value YY',
  x00189606: 'Diffusion b-value YZ',
  x00189607: 'Diffusion b-value ZZ',
  x00189621: 'Functional MR Sequence',
  x00189622: 'Functional Settling Phase Frames Present',
  x00189623: 'Functional Sync Pulse',
  x00189624: 'Settling Phase Frame',
  x00189701: 'Decay Correction DateTime',
  x00189715: 'Start Density Threshold',
  x00189716: 'Start Relative Density Difference Threshold',
  x00189717: 'Start Cardiac Trigger Count Threshold',
  x00189718: 'Start Respiratory Trigger Count Threshold',
  x00189719: 'Termination Counts Threshold',
  x00189720: 'Termination Density Threshold',
  x00189721: 'Termination Relative Density Threshold',
  x00189722: 'Termination Time Threshold',
  x00189723: 'Termination Cardiac Trigger Count Threshold',
  x00189724: 'Termination Respiratory Trigger Count Threshold',
  x00189725: 'Detector Geometry',
  x00189726: 'Transverse Detector Separation',
  x00189727: 'Axial Detector Dimension',
  x00189729: 'Radiopharmaceutical Agent Number',
  x00189732: 'PET Frame Acquisition Sequence',
  x00189733: 'PET Detector Motion Details Sequence',
  x00189734: 'PET Table Dynamics Sequence',
  x00189735: 'PET Position Sequence',
  x00189736: 'PET Frame Correction Factors Sequence',
  x00189737: 'Radiopharmaceutical Usage Sequence',
  x00189738: 'Attenuation Correction Source',
  x00189739: 'Number of Iterations',
  x00189740: 'Number of Subsets',
  x00189749: 'PET Reconstruction Sequence',
  x00189751: 'PET Frame Type Sequence',
  x00189755: 'Time of Flight Information Used',
  x00189756: 'Reconstruction Type',
  x00189758: 'Decay Corrected',
  x00189759: 'Attenuation Corrected',
  x00189760: 'Scatter Corrected',
  x00189761: 'Dead Time Corrected',
  x00189762: 'Gantry Motion Corrected',
  x00189763: 'Patient Motion Corrected',
  x00189764: 'Count Loss Normalization Corrected',
  x00189765: 'Randoms Corrected',
  x00189766: 'Non-uniform Radial Sampling Corrected',
  x00189767: 'Sensitivity Calibrated',
  x00189768: 'Detector Normalization Correction',
  x00189769: 'Iterative Reconstruction Method',
  x00189770: 'Attenuation Correction Temporal Relationship',
  x00189771: 'Patient Physiological State Sequence',
  x00189772: 'Patient Physiological State Code Sequence',
  x00189801: 'Depth(s) of Focus',
  x00189803: 'Excluded Intervals Sequence',
  x00189804: 'Exclusion Start DateTime',
  x00189805: 'Exclusion Duration',
  x00189806: 'US Image Description Sequence',
  x00189807: 'Image Data Type Sequence',
  x00189808: 'Data Type',
  x00189809: 'Transducer Scan Pattern Code Sequence',
  x0018980B: 'Aliased Data Type',
  x0018980C: 'Position Measuring Device Used',
  x0018980D: 'Transducer Geometry Code Sequence',
  x0018980E: 'Transducer Beam Steering Code Sequence',
  x0018980F: 'Transducer Application Code Sequence',
  x00189810: 'Zero Velocity Pixel Value',
  x0018A001: 'Contributing Equipment Sequence',
  x0018A002: 'Contribution DateTime',
  x0018A003: 'Contribution Description',
  x0020000D: 'Study Instance UID',
  x0020000E: 'Series Instance UID',
  x00200010: 'Study ID',
  x00200011: 'Series Number',
  x00200012: 'Acquisition Number',
  x00200013: 'Instance Number',
  x00200014: 'Isotope Number',
  x00200015: 'Phase Number',
  x00200016: 'Interval Number',
  x00200017: 'Time Slot Number',
  x00200018: 'Angle Number',
  x00200019: 'Item Number',
  x00200020: 'Patient Orientation',
  x00200022: 'Overlay Number',
  x00200024: 'Curve Number',
  x00200026: 'LUT Number',
  x00200030: 'Image Position',
  x00200032: 'Image Position (Patient)',
  x00200035: 'Image Orientation',
  x00200037: 'Image Orientation (Patient)',
  x00200050: 'Location',
  x00200052: 'Frame of Reference UID',
  x00200060: 'Laterality',
  x00200062: 'Image Laterality',
  x00200070: 'Image Geometry Type',
  x00200080: 'Masking Image',
  x002000AA: 'Report Number',
  x00200100: 'Temporal Position Identifier',
  x00200105: 'Number of Temporal Positions',
  x00200110: 'Temporal Resolution',
  x00200200: 'Synchronization Frame of Reference UID',
  x00200242: 'SOP Instance UID of Concatenation Source',
  x00201000: 'Series in Study',
  x00201001: 'Acquisitions in Series',
  x00201002: 'Images in Acquisition',
  x00201003: 'Images in Series',
  x00201004: 'Acquisitions in Study',
  x00201005: 'Images in Study',
  x00201020: 'Reference',
  x00201040: 'Position Reference Indicator',
  x00201041: 'Slice Location',
  x00201070: 'Other Study Numbers',
  x00201200: 'Number of Patient Related Studies',
  x00201202: 'Number of Patient Related Series',
  x00201204: 'Number of Patient Related Instances',
  x00201206: 'Number of Study Related Series',
  x00201208: 'Number of Study Related Instances',
  x00201209: 'Number of Series Related Instances',
  x002031xx: 'Source Image IDs',
  x00203401: 'Modifying Device ID',
  x00203402: 'Modified Image ID',
  x00203403: 'Modified Image Date',
  x00203404: 'Modifying Device Manufacturer',
  x00203405: 'Modified Image Time',
  x00203406: 'Modified Image Description',
  x00204000: 'Image Comments',
  x00205000: 'Original Image Identification',
  x00205002: 'Original Image Identification Nomenclature',
  x00209056: 'Stack ID',
  x00209057: 'In-Stack Position Number',
  x00209071: 'Frame Anatomy Sequence',
  x00209072: 'Frame Laterality',
  x00209111: 'Frame Content Sequence',
  x00209113: 'Plane Position Sequence',
  x00209116: 'Plane Orientation Sequence',
  x00209128: 'Temporal Position Index',
  x00209153: 'Nominal Cardiac Trigger Delay Time',
  x00209154: 'Nominal Cardiac Trigger Time Prior To R-Peak',
  x00209155: 'Actual Cardiac Trigger Time Prior To R-Peak',
  x00209156: 'Frame Acquisition Number',
  x00209157: 'Dimension Index Values',
  x00209158: 'Frame Comments',
  x00209161: 'Concatenation UID',
  x00209162: 'In-concatenation Number',
  x00209163: 'In-concatenation Total Number',
  x00209164: 'Dimension Organization UID',
  x00209165: 'Dimension Index Pointer',
  x00209167: 'Functional Group Pointer',
  x00209170: 'Unassigned Shared Converted Attributes Sequence',
  x00209171: 'Unassigned Per-Frame Converted Attributes Sequence',
  x00209172: 'Conversion Source Attributes Sequence',
  x00209213: 'Dimension Index Private Creator',
  x00209221: 'Dimension Organization Sequence',
  x00209222: 'Dimension Index Sequence',
  x00209228: 'Concatenation Frame Offset Number',
  x00209238: 'Functional Group Private Creator',
  x00209241: 'Nominal Percentage of Cardiac Phase',
  x00209245: 'Nominal Percentage of Respiratory Phase',
  x00209246: 'Starting Respiratory Amplitude',
  x00209247: 'Starting Respiratory Phase',
  x00209248: 'Ending Respiratory Amplitude',
  x00209249: 'Ending Respiratory Phase',
  x00209250: 'Respiratory Trigger Type',
  x00209251: 'R-R Interval Time Nominal',
  x00209252: 'Actual Cardiac Trigger Delay Time',
  x00209253: 'Respiratory Synchronization Sequence',
  x00209254: 'Respiratory Interval Time',
  x00209255: 'Nominal Respiratory Trigger Delay Time',
  x00209256: 'Respiratory Trigger Delay Threshold',
  x00209257: 'Actual Respiratory Trigger Delay Time',
  x00209301: 'Image Position (Volume)',
  x00209302: 'Image Orientation (Volume)',
  x00209307: 'Ultrasound Acquisition Geometry',
  x00209308: 'Apex Position',
  x00209309: 'Volume to Transducer Mapping Matrix',
  x0020930A: 'Volume to Table Mapping Matrix',
  x0020930B: 'Volume to Transducer Relationship',
  x0020930C: 'Patient Frame of Reference Source',
  x0020930D: 'Temporal Position Time Offset',
  x0020930E: 'Plane Position (Volume) Sequence',
  x0020930F: 'Plane Orientation (Volume) Sequence',
  x00209310: 'Temporal Position Sequence',
  x00209311: 'Dimension Organization Type',
  x00209312: 'Volume Frame of Reference UID',
  x00209313: 'Table Frame of Reference UID',
  x00209421: 'Dimension Description Label',
  x00209450: 'Patient Orientation in Frame Sequence',
  x00209453: 'Frame Label',
  x00209518: 'Acquisition Index',
  x00209529: 'Contributing SOP Instances Reference Sequence',
  x00209536: 'Reconstruction Index',
  x00220001: 'Light Path Filter Pass-Through Wavelength',
  x00220002: 'Light Path Filter Pass Band',
  x00220003: 'Image Path Filter Pass-Through Wavelength',
  x00220004: 'Image Path Filter Pass Band',
  x00220005: 'Patient Eye Movement Commanded',
  x00220006: 'Patient Eye Movement Command Code Sequence',
  x00220007: 'Spherical Lens Power',
  x00220008: 'Cylinder Lens Power',
  x00220009: 'Cylinder Axis',
  x0022000A: 'Emmetropic Magnification',
  x0022000B: 'Intra Ocular Pressure',
  x0022000C: 'Horizontal Field of View',
  x0022000D: 'Pupil Dilated',
  x0022000E: 'Degree of Dilation',
  x00220010: 'Stereo Baseline Angle',
  x00220011: 'Stereo Baseline Displacement',
  x00220012: 'Stereo Horizontal Pixel Offset',
  x00220013: 'Stereo Vertical Pixel Offset',
  x00220014: 'Stereo Rotation',
  x00220015: 'Acquisition Device Type Code Sequence',
  x00220016: 'Illumination Type Code Sequence',
  x00220017: 'Light Path Filter Type Stack Code Sequence',
  x00220018: 'Image Path Filter Type Stack Code Sequence',
  x00220019: 'Lenses Code Sequence',
  x0022001A: 'Channel Description Code Sequence',
  x0022001B: 'Refractive State Sequence',
  x0022001C: 'Mydriatic Agent Code Sequence',
  x0022001D: 'Relative Image Position Code Sequence',
  x0022001E: 'Camera Angle of View',
  x00220020: 'Stereo Pairs Sequence',
  x00220021: 'Left Image Sequence',
  x00220022: 'Right Image Sequence',
  x00220028: 'Stereo Pairs Present',
  x00220030: 'Axial Length of the Eye',
  x00220031: 'Ophthalmic Frame Location Sequence',
  x00220032: 'Reference Coordinates',
  x00220035: 'Depth Spatial Resolution',
  x00220036: 'Maximum Depth Distortion',
  x00220037: 'Along-scan Spatial Resolution',
  x00220038: 'Maximum Along-scan Distortion',
  x00220039: 'Ophthalmic Image Orientation',
  x00220041: 'Depth of Transverse Image',
  x00220042: 'Mydriatic Agent Concentration Units Sequence',
  x00220048: 'Across-scan Spatial Resolution',
  x00220049: 'Maximum Across-scan Distortion',
  x0022004E: 'Mydriatic Agent Concentration',
  x00220055: 'Illumination Wave Length',
  x00220056: 'Illumination Power',
  x00220057: 'Illumination Bandwidth',
  x00220058: 'Mydriatic Agent Sequence',
  x00221007: 'Ophthalmic Axial Measurements Right Eye Sequence',
  x00221008: 'Ophthalmic Axial Measurements Left Eye Sequence',
  x00221009: 'Ophthalmic Axial Measurements Device Type',
  x00221010: 'Ophthalmic Axial Length Measurements Type',
  x00221012: 'Ophthalmic Axial Length Sequence',
  x00221019: 'Ophthalmic Axial Length',
  x00221024: 'Lens Status Code Sequence',
  x00221025: 'Vitreous Status Code Sequence',
  x00221028: 'IOL Formula Code Sequence',
  x00221029: 'IOL Formula Detail',
  x00221033: 'Keratometer Index',
  x00221035: 'Source of Ophthalmic Axial Length Code Sequence',
  x00221037: 'Target Refraction',
  x00221039: 'Refractive Procedure Occurred',
  x00221040: 'Refractive Surgery Type Code Sequence',
  x00221044: 'Ophthalmic Ultrasound Method Code Sequence',
  x00221050: 'Ophthalmic Axial Length Measurements Sequence',
  x00221053: 'IOL Power',
  x00221054: 'Predicted Refractive Error',
  x00221059: 'Ophthalmic Axial Length Velocity',
  x00221065: 'Lens Status Description',
  x00221066: 'Vitreous Status Description',
  x00221090: 'IOL Power Sequence',
  x00221092: 'Lens Constant Sequence',
  x00221093: 'IOL Manufacturer',
  x00221094: 'Lens Constant Description',
  x00221095: 'Implant Name',
  x00221096: 'Keratometry Measurement Type Code Sequence',
  x00221097: 'Implant Part Number',
  x00221100: 'Referenced Ophthalmic Axial Measurements Sequence',
  x00221101: 'Ophthalmic Axial Length Measurements Segment Name Code Sequence',
  x00221103: 'Refractive Error Before Refractive Surgery Code Sequence',
  x00221121: 'IOL Power For Exact Emmetropia',
  x00221122: 'IOL Power For Exact Target Refraction',
  x00221125: 'Anterior Chamber Depth Definition Code Sequence',
  x00221127: 'Lens Thickness Sequence',
  x00221128: 'Anterior Chamber Depth Sequence',
  x00221130: 'Lens Thickness',
  x00221131: 'Anterior Chamber Depth',
  x00221132: 'Source of Lens Thickness Data Code Sequence',
  x00221133: 'Source of Anterior Chamber Depth Data Code Sequence',
  x00221134: 'Source of Refractive Measurements Sequence',
  x00221135: 'Source of Refractive Measurements Code Sequence',
  x00221140: 'Ophthalmic Axial Length Measurement Modified',
  x00221150: 'Ophthalmic Axial Length Data Source Code Sequence',
  x00221153: 'Ophthalmic Axial Length Acquisition Method Code Sequence',
  x00221155: 'Signal to Noise Ratio',
  x00221159: 'Ophthalmic Axial Length Data Source Description',
  x00221210: 'Ophthalmic Axial Length Measurements Total Length Sequence',
  x00221211: 'Ophthalmic Axial Length Measurements Segmental Length Sequence',
  x00221212: 'Ophthalmic Axial Length Measurements Length Summation Sequence',
  x00221220: 'Ultrasound Ophthalmic Axial Length Measurements Sequence',
  x00221225: 'Optical Ophthalmic Axial Length Measurements Sequence',
  x00221230: 'Ultrasound Selected Ophthalmic Axial Length Sequence',
  x00221250: 'Ophthalmic Axial Length Selection Method Code Sequence',
  x00221255: 'Optical Selected Ophthalmic Axial Length Sequence',
  x00221257: 'Selected Segmental Ophthalmic Axial Length Sequence',
  x00221260: 'Selected Total Ophthalmic Axial Length Sequence',
  x00221262: 'Ophthalmic Axial Length Quality Metric Sequence',
  x00221265: 'Ophthalmic Axial Length Quality Metric Type Code Sequence',
  x00221273: 'Ophthalmic Axial Length Quality Metric Type Description',
  x00221300: 'Intraocular Lens Calculations Right Eye Sequence',
  x00221310: 'Intraocular Lens Calculations Left Eye Sequence',
  x00221330: 'Referenced Ophthalmic Axial Length Measurement QC Image Sequence',
  x00221415: 'Ophthalmic Mapping Device Type',
  x00221420: 'Acquisition Method Code Sequence',
  x00221423: 'Acquisition Method Algorithm Sequence',
  x00221436: 'Ophthalmic Thickness Map Type Code Sequence',
  x00221443: 'Ophthalmic Thickness Mapping Normals Sequence',
  x00221445: 'Retinal Thickness Definition Code Sequence',
  x00221450: 'Pixel Value Mapping to Coded Concept Sequence',
  x00221452: 'Mapped Pixel Value',
  x00221454: 'Pixel Value Mapping Explanation',
  x00221458: 'Ophthalmic Thickness Map Quality Threshold Sequence',
  x00221460: 'Ophthalmic Thickness Map Threshold Quality Rating',
  x00221463: 'Anatomic Structure Reference Point',
  x00221465: 'Registration to Localizer Sequence',
  x00221466: 'Registered Localizer Units',
  x00221467: 'Registered Localizer Top Left Hand Corner',
  x00221468: 'Registered Localizer Bottom Right Hand Corner',
  x00221470: 'Ophthalmic Thickness Map Quality Rating Sequence',
  x00221472: 'Relevant OPT Attributes Sequence',
  x00221512: 'Transformation Method Code Sequence',
  x00221513: 'Transformation Algorithm Sequence',
  x00221515: 'Ophthalmic Axial Length Method',
  x00221517: 'Ophthalmic FOV',
  x00221518: 'Two Dimensional to Three Dimensional Map Sequence',
  x00221525: 'Wide Field Ophthalmic Photography Quality Rating Sequence',
  x00221526: 'Wide Field Ophthalmic Photography Quality Threshold Sequence',
  x00221527: 'Wide Field Ophthalmic Photography Threshold Quality Rating',
  x00221528: 'X Coordinates Center Pixel View Angle',
  x00221529: 'Y Coordinates Center Pixel View Angle',
  x00221530: 'Number of Map Points',
  x00221531: 'Two Dimensional to Three Dimensional Map Data',
  x00240010: 'Visual Field Horizontal Extent',
  x00240011: 'Visual Field Vertical Extent',
  x00240012: 'Visual Field Shape',
  x00240016: 'Screening Test Mode Code Sequence',
  x00240018: 'Maximum Stimulus Luminance',
  x00240020: 'Background Luminance',
  x00240021: 'Stimulus Color Code Sequence',
  x00240024: 'Background Illumination Color Code Sequence',
  x00240025: 'Stimulus Area',
  x00240028: 'Stimulus Presentation Time',
  x00240032: 'Fixation Sequence',
  x00240033: 'Fixation Monitoring Code Sequence',
  x00240034: 'Visual Field Catch Trial Sequence',
  x00240035: 'Fixation Checked Quantity',
  x00240036: 'Patient Not Properly Fixated Quantity',
  x00240037: 'Presented Visual Stimuli Data Flag',
  x00240038: 'Number of Visual Stimuli',
  x00240039: 'Excessive Fixation Losses Data Flag',
  x00240040: 'Excessive Fixation Losses',
  x00240042: 'Stimuli Retesting Quantity',
  x00240044: 'Comments on Patient`s Performance of Visual Field',
  x00240045: 'False Negatives Estimate Flag',
  x00240046: 'False Negatives Estimate',
  x00240048: 'Negative Catch Trials Quantity',
  x00240050: 'False Negatives Quantity',
  x00240051: 'Excessive False Negatives Data Flag',
  x00240052: 'Excessive False Negatives',
  x00240053: 'False Positives Estimate Flag',
  x00240054: 'False Positives Estimate',
  x00240055: 'Catch Trials Data Flag',
  x00240056: 'Positive Catch Trials Quantity',
  x00240057: 'Test Point Normals Data Flag',
  x00240058: 'Test Point Normals Sequence',
  x00240059: 'Global Deviation Probability Normals Flag',
  x00240060: 'False Positives Quantity',
  x00240061: 'Excessive False Positives Data Flag',
  x00240062: 'Excessive False Positives',
  x00240063: 'Visual Field Test Normals Flag',
  x00240064: 'Results Normals Sequence',
  x00240065: 'Age Corrected Sensitivity Deviation Algorithm Sequence',
  x00240066: 'Global Deviation From Normal',
  x00240067: 'Generalized Defect Sensitivity Deviation Algorithm Sequence',
  x00240068: 'Localized Deviation From Normal',
  x00240069: 'Patient Reliability Indicator',
  x00240070: 'Visual Field Mean Sensitivity',
  x00240071: 'Global Deviation Probability',
  x00240072: 'Local Deviation Probability Normals Flag',
  x00240073: 'Localized Deviation Probability',
  x00240074: 'Short Term Fluctuation Calculated',
  x00240075: 'Short Term Fluctuation',
  x00240076: 'Short Term Fluctuation Probability Calculated',
  x00240077: 'Short Term Fluctuation Probability',
  x00240078: 'Corrected Localized Deviation From Normal Calculated',
  x00240079: 'Corrected Localized Deviation From Normal',
  x00240080: 'Corrected Localized Deviation From Normal Probability Calculated',
  x00240081: 'Corrected Localized Deviation From Normal Probability',
  x00240083: 'Global Deviation Probability Sequence',
  x00240085: 'Localized Deviation Probability Sequence',
  x00240086: 'Foveal Sensitivity Measured',
  x00240087: 'Foveal Sensitivity',
  x00240088: 'Visual Field Test Duration',
  x00240089: 'Visual Field Test Point Sequence',
  x00240090: 'Visual Field Test Point X-Coordinate',
  x00240091: 'Visual Field Test Point Y-Coordinate',
  x00240092: 'Age Corrected Sensitivity Deviation Value',
  x00240093: 'Stimulus Results',
  x00240094: 'Sensitivity Value',
  x00240095: 'Retest Stimulus Seen',
  x00240096: 'Retest Sensitivity Value',
  x00240097: 'Visual Field Test Point Normals Sequence',
  x00240098: 'Quantified Defect',
  x00240100: 'Age Corrected Sensitivity Deviation Probability Value',
  x00240102: 'Generalized Defect Corrected Sensitivity Deviation Flag',
  x00240103: 'Generalized Defect Corrected Sensitivity Deviation Value',
  x00240104: 'Generalized Defect Corrected Sensitivity Deviation Probability Value',
  x00240105: 'Minimum Sensitivity Value',
  x00240106: 'Blind Spot Localized',
  x00240107: 'Blind Spot X-Coordinate',
  x00240108: 'Blind Spot Y-Coordinate',
  x00240110: 'Visual Acuity Measurement Sequence',
  x00240112: 'Refractive Parameters Used on Patient Sequence',
  x00240113: 'Measurement Laterality',
  x00240114: 'Ophthalmic Patient Clinical Information Left Eye Sequence',
  x00240115: 'Ophthalmic Patient Clinical Information Right Eye Sequence',
  x00240117: 'Foveal Point Normative Data Flag',
  x00240118: 'Foveal Point Probability Value',
  x00240120: 'Screening Baseline Measured',
  x00240122: 'Screening Baseline Measured Sequence',
  x00240124: 'Screening Baseline Type',
  x00240126: 'Screening Baseline Value',
  x00240202: 'Algorithm Source',
  x00240306: 'Data Set Name',
  x00240307: 'Data Set Version',
  x00240308: 'Data Set Source',
  x00240309: 'Data Set Description',
  x00240317: 'Visual Field Test Reliability Global Index Sequence',
  x00240320: 'Visual Field Global Results Index Sequence',
  x00240325: 'Data Observation Sequence',
  x00240338: 'Index Normals Flag',
  x00240341: 'Index Probability',
  x00240344: 'Index Probability Sequence',
  x00280002: 'Samples per Pixel',
  x00280003: 'Samples per Pixel Used',
  x00280004: 'Photometric Interpretation',
  x00280005: 'Image Dimensions',
  x00280006: 'Planar Configuration',
  x00280008: 'Number of Frames',
  x00280009: 'Frame Increment Pointer',
  x0028000A: 'Frame Dimension Pointer',
  x00280010: 'Rows',
  x00280011: 'Columns',
  x00280012: 'Planes',
  x00280014: 'Ultrasound Color Data Present',
  x00280020: '',
  x00280030: 'Pixel Spacing',
  x00280031: 'Zoom Factor',
  x00280032: 'Zoom Center',
  x00280034: 'Pixel Aspect Ratio',
  x00280040: 'Image Format',
  x00280050: 'Manipulated Image',
  x00280051: 'Corrected Image',
  x0028005F: 'Compression Recognition Code',
  x00280060: 'Compression Code',
  x00280061: 'Compression Originator',
  x00280062: 'Compression Label',
  x00280063: 'Compression Description',
  x00280065: 'Compression Sequence',
  x00280066: 'Compression Step Pointers',
  x00280068: 'Repeat Interval',
  x00280069: 'Bits Grouped',
  x00280070: 'Perimeter Table',
  x00280071: 'Perimeter Value',
  x00280080: 'Predictor Rows',
  x00280081: 'Predictor Columns',
  x00280082: 'Predictor Constants',
  x00280090: 'Blocked Pixels',
  x00280091: 'Block Rows',
  x00280092: 'Block Columns',
  x00280093: 'Row Overlap',
  x00280094: 'Column Overlap',
  x00280100: 'Bits Allocated',
  x00280101: 'Bits Stored',
  x00280102: 'High Bit',
  x00280103: 'Pixel Representation',
  x00280104: 'Smallest Valid Pixel Value',
  x00280105: 'Largest Valid Pixel Value',
  x00280106: 'Smallest Image Pixel Value',
  x00280107: 'Largest Image Pixel Value',
  x00280108: 'Smallest Pixel Value in Series',
  x00280109: 'Largest Pixel Value in Series',
  x00280110: 'Smallest Image Pixel Value in Plane',
  x00280111: 'Largest Image Pixel Value in Plane',
  x00280120: 'Pixel Padding Value',
  x00280121: 'Pixel Padding Range Limit',
  x00280122: 'Float Pixel Padding Value',
  x00280123: 'Double Float Pixel Padding Value',
  x00280124: 'Float Pixel Padding Range Limit',
  x00280125: 'Double Float Pixel Padding Range Limit',
  x00280200: 'Image Location',
  x00280300: 'Quality Control Image',
  x00280301: 'Burned In Annotation',
  x00280302: 'Recognizable Visual Features',
  x00280303: 'Longitudinal Temporal Information Modified',
  x00280304: 'Referenced Color Palette Instance UID',
  x00280400: 'Transform Label',
  x00280401: 'Transform Version Number',
  x00280402: 'Number of Transform Steps',
  x00280403: 'Sequence of Compressed Data',
  x00280404: 'Details of Coefficients',
  x002804x0: 'Rows For Nth Order Coefficients',
  x002804x1: 'Columns For Nth Order Coefficients',
  x002804x2: 'Coefficient Coding',
  x002804x3: 'Coefficient Coding Pointers',
  x00280700: 'DCT Label',
  x00280701: 'Data Block Description',
  x00280702: 'Data Block',
  x00280710: 'Normalization Factor Format',
  x00280720: 'Zonal Map Number Format',
  x00280721: 'Zonal Map Location',
  x00280722: 'Zonal Map Format',
  x00280730: 'Adaptive Map Format',
  x00280740: 'Code Number Format',
  x002808x0: 'Code Label',
  x002808x2: 'Number of Tables',
  x002808x3: 'Code Table Location',
  x002808x4: 'Bits For Code Word',
  x002808x8: 'Image Data Location',
  x00280A02: 'Pixel Spacing Calibration Type',
  x00280A04: 'Pixel Spacing Calibration Description',
  x00281040: 'Pixel Intensity Relationship',
  x00281041: 'Pixel Intensity Relationship Sign',
  x00281050: 'Window Center',
  x00281051: 'Window Width',
  x00281052: 'Rescale Intercept',
  x00281053: 'Rescale Slope',
  x00281054: 'Rescale Type',
  x00281055: 'Window Center & Width Explanation',
  x00281056: 'VOI LUT Function',
  x00281080: 'Gray Scale',
  x00281090: 'Recommended Viewing Mode',
  x00281100: 'Gray Lookup Table Descriptor',
  x00281101: 'Red Palette Color Lookup Table Descriptor',
  x00281102: 'Green Palette Color Lookup Table Descriptor',
  x00281103: 'Blue Palette Color Lookup Table Descriptor',
  x00281104: 'Alpha Palette Color Lookup Table Descriptor',
  x00281111: 'Large Red Palette Color Lookup Table Descriptor',
  x00281112: 'Large Green Palette Color Lookup Table Descriptor',
  x00281113: 'Large Blue Palette Color Lookup Table Descriptor',
  x00281199: 'Palette Color Lookup Table UID',
  x00281200: 'Gray Lookup Table Data',
  x00281201: 'Red Palette Color Lookup Table Data',
  x00281202: 'Green Palette Color Lookup Table Data',
  x00281203: 'Blue Palette Color Lookup Table Data',
  x00281204: 'Alpha Palette Color Lookup Table Data',
  x00281211: 'Large Red Palette Color Lookup Table Data',
  x00281212: 'Large Green Palette Color Lookup Table Data',
  x00281213: 'Large Blue Palette Color Lookup Table Data',
  x00281214: 'Large Palette Color Lookup Table UID',
  x00281221: 'Segmented Red Palette Color Lookup Table Data',
  x00281222: 'Segmented Green Palette Color Lookup Table Data',
  x00281223: 'Segmented Blue Palette Color Lookup Table Data',
  x00281224: 'Segmented Alpha Palette Color Lookup Table Data',
  x00281300: 'Breast Implant Present',
  x00281350: 'Partial View',
  x00281351: 'Partial View Description',
  x00281352: 'Partial View Code Sequence',
  x0028135A: 'Spatial Locations Preserved',
  x00281401: 'Data Frame Assignment Sequence',
  x00281402: 'Data Path Assignment',
  x00281403: 'Bits Mapped to Color Lookup Table',
  x00281404: 'Blending LUT 1 Sequence',
  x00281405: 'Blending LUT 1 Transfer Function',
  x00281406: 'Blending Weight Constant',
  x00281407: 'Blending Lookup Table Descriptor',
  x00281408: 'Blending Lookup Table Data',
  x0028140B: 'Enhanced Palette Color Lookup Table Sequence',
  x0028140C: 'Blending LUT 2 Sequence',
  x0028140D: 'Blending LUT 2 Transfer Function',
  x0028140E: 'Data Path ID',
  x0028140F: 'RGB LUT Transfer Function',
  x00281410: 'Alpha LUT Transfer Function',
  x00282000: 'ICC Profile',
  x00282002: 'Color Space',
  x00282110: 'Lossy Image Compression',
  x00282112: 'Lossy Image Compression Ratio',
  x00282114: 'Lossy Image Compression Method',
  x00283000: 'Modality LUT Sequence',
  x00283002: 'LUT Descriptor',
  x00283003: 'LUT Explanation',
  x00283004: 'Modality LUT Type',
  x00283006: 'LUT Data',
  x00283010: 'VOI LUT Sequence',
  x00283110: 'Softcopy VOI LUT Sequence',
  x00284000: 'Image Presentation Comments',
  x00285000: 'Bi-Plane Acquisition Sequence',
  x00286010: 'Representative Frame Number',
  x00286020: 'Frame Numbers of Interest (FOI)',
  x00286022: 'Frame of Interest Description',
  x00286023: 'Frame of Interest Type',
  x00286030: 'Mask Pointer(s)',
  x00286040: 'R Wave Pointer',
  x00286100: 'Mask Subtraction Sequence',
  x00286101: 'Mask Operation',
  x00286102: 'Applicable Frame Range',
  x00286110: 'Mask Frame Numbers',
  x00286112: 'Contrast Frame Averaging',
  x00286114: 'Mask Sub-pixel Shift',
  x00286120: 'TID Offset',
  x00286190: 'Mask Operation Explanation',
  x00287000: 'Equipment Administrator Sequence',
  x00287001: 'Number of Display Subsystems',
  x00287002: 'Current Configuration ID',
  x00287003: 'Display Subsystem ID',
  x00287004: 'Display Subsystem Name',
  x00287005: 'Display Subsystem Description',
  x00287006: 'System Status',
  x00287007: 'System Status Comment',
  x00287008: 'Target Luminance Characteristics Sequence',
  x00287009: 'Luminance Characteristics ID',
  x0028700A: 'Display Subsystem Configuration Sequence',
  x0028700B: 'Configuration ID',
  x0028700C: 'Configuration Name',
  x0028700D: 'Configuration Description',
  x0028700E: 'Referenced Target Luminance Characteristics ID',
  x0028700F: 'QA Results Sequence',
  x00287010: 'Display Subsystem QA Results Sequence',
  x00287011: 'Configuration QA Results Sequence',
  x00287012: 'Measurement Equipment Sequence',
  x00287013: 'Measurement Functions',
  x00287014: 'Measurement Equipment Type',
  x00287015: 'Visual Evaluation Result Sequence',
  x00287016: 'Display Calibration Result Sequence',
  x00287017: 'DDL Value',
  x00287018: 'CIExy White Point',
  x00287019: 'Display Function Type',
  x0028701A: 'Gamma Value',
  x0028701B: 'Number of Luminance Points',
  x0028701C: 'Luminance Response Sequence',
  x0028701D: 'Target Minimum Luminance',
  x0028701E: 'Target Maximum Luminance',
  x0028701F: 'Luminance Value',
  x00287020: 'Luminance Response Description',
  x00287021: 'White Point Flag',
  x00287022: 'Display Device Type Code Sequence',
  x00287023: 'Display Subsystem Sequence',
  x00287024: 'Luminance Result Sequence',
  x00287025: 'Ambient Light Value Source',
  x00287026: 'Measured Characteristics',
  x00287027: 'Luminance Uniformity Result Sequence',
  x00287028: 'Visual Evaluation Test Sequence',
  x00287029: 'Test Result',
  x0028702A: 'Test Result Comment',
  x0028702B: 'Test Image Validation',
  x0028702C: 'Test Pattern Code Sequence',
  x0028702D: 'Measurement Pattern Code Sequence',
  x0028702E: 'Visual Evaluation Method Code Sequence',
  x00287FE0: 'Pixel Data Provider URL',
  x00289001: 'Data Point Rows',
  x00289002: 'Data Point Columns',
  x00289003: 'Signal Domain Columns',
  x00289099: 'Largest Monochrome Pixel Value',
  x00289108: 'Data Representation',
  x00289110: 'Pixel Measures Sequence',
  x00289132: 'Frame VOI LUT Sequence',
  x00289145: 'Pixel Value Transformation Sequence',
  x00289235: 'Signal Domain Rows',
  x00289411: 'Display Filter Percentage',
  x00289415: 'Frame Pixel Shift Sequence',
  x00289416: 'Subtraction Item ID',
  x00289422: 'Pixel Intensity Relationship LUT Sequence',
  x00289443: 'Frame Pixel Data Properties Sequence',
  x00289444: 'Geometrical Properties',
  x00289445: 'Geometric Maximum Distortion',
  x00289446: 'Image Processing Applied',
  x00289454: 'Mask Selection Mode',
  x00289474: 'LUT Function',
  x00289478: 'Mask Visibility Percentage',
  x00289501: 'Pixel Shift Sequence',
  x00289502: 'Region Pixel Shift Sequence',
  x00289503: 'Vertices of the Region',
  x00289505: 'Multi-frame Presentation Sequence',
  x00289506: 'Pixel Shift Frame Range',
  x00289507: 'LUT Frame Range',
  x00289520: 'Image to Equipment Mapping Matrix',
  x00289537: 'Equipment Coordinate System Identification',
  x0032000A: 'Study Status ID',
  x0032000C: 'Study Priority ID',
  x00320012: 'Study ID Issuer',
  x00320032: 'Study Verified Date',
  x00320033: 'Study Verified Time',
  x00320034: 'Study Read Date',
  x00320035: 'Study Read Time',
  x00321000: 'Scheduled Study Start Date',
  x00321001: 'Scheduled Study Start Time',
  x00321010: 'Scheduled Study Stop Date',
  x00321011: 'Scheduled Study Stop Time',
  x00321020: 'Scheduled Study Location',
  x00321021: 'Scheduled Study Location AE Title',
  x00321030: 'Reason for Study',
  x00321031: 'Requesting Physician Identification Sequence',
  x00321032: 'Requesting Physician',
  x00321033: 'Requesting Service',
  x00321034: 'Requesting Service Code Sequence',
  x00321040: 'Study Arrival Date',
  x00321041: 'Study Arrival Time',
  x00321050: 'Study Completion Date',
  x00321051: 'Study Completion Time',
  x00321055: 'Study Component Status ID',
  x00321060: 'Requested Procedure Description',
  x00321064: 'Requested Procedure Code Sequence',
  x00321070: 'Requested Contrast Agent',
  x00324000: 'Study Comments',
  x00380004: 'Referenced Patient Alias Sequence',
  x00380008: 'Visit Status ID',
  x00380010: 'Admission ID',
  x00380011: 'Issuer of Admission ID',
  x00380014: 'Issuer of Admission ID Sequence',
  x00380016: 'Route of Admissions',
  x0038001A: 'Scheduled Admission Date',
  x0038001B: 'Scheduled Admission Time',
  x0038001C: 'Scheduled Discharge Date',
  x0038001D: 'Scheduled Discharge Time',
  x0038001E: 'Scheduled Patient Institution Residence',
  x00380020: 'Admitting Date',
  x00380021: 'Admitting Time',
  x00380030: 'Discharge Date',
  x00380032: 'Discharge Time',
  x00380040: 'Discharge Diagnosis Description',
  x00380044: 'Discharge Diagnosis Code Sequence',
  x00380050: 'Special Needs',
  x00380060: 'Service Episode ID',
  x00380061: 'Issuer of Service Episode ID',
  x00380062: 'Service Episode Description',
  x00380064: 'Issuer of Service Episode ID Sequence',
  x00380100: 'Pertinent Documents Sequence',
  x00380101: 'Pertinent Resources Sequence',
  x00380102: 'Resource Description',
  x00380300: 'Current Patient Location',
  x00380400: 'Patient`s Institution Residence',
  x00380500: 'Patient State',
  x00380502: 'Patient Clinical Trial Participation Sequence',
  x00384000: 'Visit Comments',
  x003A0004: 'Waveform Originality',
  x003A0005: 'Number of Waveform Channels',
  x003A0010: 'Number of Waveform Samples',
  x003A001A: 'Sampling Frequency',
  x003A0020: 'Multiplex Group Label',
  x003A0200: 'Channel Definition Sequence',
  x003A0202: 'Waveform Channel Number',
  x003A0203: 'Channel Label',
  x003A0205: 'Channel Status',
  x003A0208: 'Channel Source Sequence',
  x003A0209: 'Channel Source Modifiers Sequence',
  x003A020A: 'Source Waveform Sequence',
  x003A020C: 'Channel Derivation Description',
  x003A0210: 'Channel Sensitivity',
  x003A0211: 'Channel Sensitivity Units Sequence',
  x003A0212: 'Channel Sensitivity Correction Factor',
  x003A0213: 'Channel Baseline',
  x003A0214: 'Channel Time Skew',
  x003A0215: 'Channel Sample Skew',
  x003A0218: 'Channel Offset',
  x003A021A: 'Waveform Bits Stored',
  x003A0220: 'Filter Low Frequency',
  x003A0221: 'Filter High Frequency',
  x003A0222: 'Notch Filter Frequency',
  x003A0223: 'Notch Filter Bandwidth',
  x003A0230: 'Waveform Data Display Scale',
  x003A0231: 'Waveform Display Background CIELab Value',
  x003A0240: 'Waveform Presentation Group Sequence',
  x003A0241: 'Presentation Group Number',
  x003A0242: 'Channel Display Sequence',
  x003A0244: 'Channel Recommended Display CIELab Value',
  x003A0245: 'Channel Position',
  x003A0246: 'Display Shading Flag',
  x003A0247: 'Fractional Channel Display Scale',
  x003A0248: 'Absolute Channel Display Scale',
  x003A0300: 'Multiplexed Audio Channels Description Code Sequence',
  x003A0301: 'Channel Identification Code',
  x003A0302: 'Channel Mode',
  x00400001: 'Scheduled Station AE Title',
  x00400002: 'Scheduled Procedure Step Start Date',
  x00400003: 'Scheduled Procedure Step Start Time',
  x00400004: 'Scheduled Procedure Step End Date',
  x00400005: 'Scheduled Procedure Step End Time',
  x00400006: 'Scheduled Performing Physician`s Name',
  x00400007: 'Scheduled Procedure Step Description',
  x00400008: 'Scheduled Protocol Code Sequence',
  x00400009: 'Scheduled Procedure Step ID',
  x0040000A: 'Stage Code Sequence',
  x0040000B: 'Scheduled Performing Physician Identification Sequence',
  x00400010: 'Scheduled Station Name',
  x00400011: 'Scheduled Procedure Step Location',
  x00400012: 'Pre-Medication',
  x00400020: 'Scheduled Procedure Step Status',
  x00400026: 'Order Placer Identifier Sequence',
  x00400027: 'Order Filler Identifier Sequence',
  x00400031: 'Local Namespace Entity ID',
  x00400032: 'Universal Entity ID',
  x00400033: 'Universal Entity ID Type',
  x00400035: 'Identifier Type Code',
  x00400036: 'Assigning Facility Sequence',
  x00400039: 'Assigning Jurisdiction Code Sequence',
  x0040003A: 'Assigning Agency or Department Code Sequence',
  x00400100: 'Scheduled Procedure Step Sequence',
  x00400220: 'Referenced Non-Image Composite SOP Instance Sequence',
  x00400241: 'Performed Station AE Title',
  x00400242: 'Performed Station Name',
  x00400243: 'Performed Location',
  x00400244: 'Performed Procedure Step Start Date',
  x00400245: 'Performed Procedure Step Start Time',
  x00400250: 'Performed Procedure Step End Date',
  x00400251: 'Performed Procedure Step End Time',
  x00400252: 'Performed Procedure Step Status',
  x00400253: 'Performed Procedure Step ID',
  x00400254: 'Performed Procedure Step Description',
  x00400255: 'Performed Procedure Type Description',
  x00400260: 'Performed Protocol Code Sequence',
  x00400261: 'Performed Protocol Type',
  x00400270: 'Scheduled Step Attributes Sequence',
  x00400275: 'Request Attributes Sequence',
  x00400280: 'Comments on the Performed Procedure Step',
  x00400281: 'Performed Procedure Step Discontinuation Reason Code Sequence',
  x00400293: 'Quantity Sequence',
  x00400294: 'Quantity',
  x00400295: 'Measuring Units Sequence',
  x00400296: 'Billing Item Sequence',
  x00400300: 'Total Time of Fluoroscopy',
  x00400301: 'Total Number of Exposures',
  x00400302: 'Entrance Dose',
  x00400303: 'Exposed Area',
  x00400306: 'Distance Source to Entrance',
  x00400307: 'Distance Source to Support',
  x0040030E: 'Exposure Dose Sequence',
  x00400310: 'Comments on Radiation Dose',
  x00400312: 'X-Ray Output',
  x00400314: 'Half Value Layer',
  x00400316: 'Organ Dose',
  x00400318: 'Organ Exposed',
  x00400320: 'Billing Procedure Step Sequence',
  x00400321: 'Film Consumption Sequence',
  x00400324: 'Billing Supplies and Devices Sequence',
  x00400330: 'Referenced Procedure Step Sequence',
  x00400340: 'Performed Series Sequence',
  x00400400: 'Comments on the Scheduled Procedure Step',
  x00400440: 'Protocol Context Sequence',
  x00400441: 'Content Item Modifier Sequence',
  x00400500: 'Scheduled Specimen Sequence',
  x0040050A: 'Specimen Accession Number',
  x00400512: 'Container Identifier',
  x00400513: 'Issuer of the Container Identifier Sequence',
  x00400515: 'Alternate Container Identifier Sequence',
  x00400518: 'Container Type Code Sequence',
  x0040051A: 'Container Description',
  x00400520: 'Container Component Sequence',
  x00400550: 'Specimen Sequence',
  x00400551: 'Specimen Identifier',
  x00400552: 'Specimen Description Sequence (Trial)',
  x00400553: 'Specimen Description (Trial)',
  x00400554: 'Specimen UID',
  x00400555: 'Acquisition Context Sequence',
  x00400556: 'Acquisition Context Description',
  x0040059A: 'Specimen Type Code Sequence',
  x00400560: 'Specimen Description Sequence',
  x00400562: 'Issuer of the Specimen Identifier Sequence',
  x00400600: 'Specimen Short Description',
  x00400602: 'Specimen Detailed Description',
  x00400610: 'Specimen Preparation Sequence',
  x00400612: 'Specimen Preparation Step Content Item Sequence',
  x00400620: 'Specimen Localization Content Item Sequence',
  x004006FA: 'Slide Identifier',
  x0040071A: 'Image Center Point Coordinates Sequence',
  x0040072A: 'X Offset in Slide Coordinate System',
  x0040073A: 'Y Offset in Slide Coordinate System',
  x0040074A: 'Z Offset in Slide Coordinate System',
  x004008D8: 'Pixel Spacing Sequence',
  x004008DA: 'Coordinate System Axis Code Sequence',
  x004008EA: 'Measurement Units Code Sequence',
  x004009F8: 'Vital Stain Code Sequence (Trial)',
  x00401001: 'Requested Procedure ID',
  x00401002: 'Reason for the Requested Procedure',
  x00401003: 'Requested Procedure Priority',
  x00401004: 'Patient Transport Arrangements',
  x00401005: 'Requested Procedure Location',
  x00401006: 'Placer Order Number / Procedure',
  x00401007: 'Filler Order Number / Procedure',
  x00401008: 'Confidentiality Code',
  x00401009: 'Reporting Priority',
  x0040100A: 'Reason for Requested Procedure Code Sequence',
  x00401010: 'Names of Intended Recipients of Results',
  x00401011: 'Intended Recipients of Results Identification Sequence',
  x00401012: 'Reason For Performed Procedure Code Sequence',
  x00401060: 'Requested Procedure Description (Trial)',
  x00401101: 'Person Identification Code Sequence',
  x00401102: 'Person`s Address',
  x00401103: 'Person`s Telephone Numbers',
  x00401104: 'Person`s Telecom Information',
  x00401400: 'Requested Procedure Comments',
  x00402001: 'Reason for the Imaging Service Request',
  x00402004: 'Issue Date of Imaging Service Request',
  x00402005: 'Issue Time of Imaging Service Request',
  x00402006: 'Placer Order Number / Imaging Service Request (Retired)',
  x00402007: 'Filler Order Number / Imaging Service Request (Retired)',
  x00402008: 'Order Entered By',
  x00402009: 'Order Enterer`s Location',
  x00402010: 'Order Callback Phone Number',
  x00402011: 'Order Callback Telecom Information',
  x00402016: 'Placer Order Number / Imaging Service Request',
  x00402017: 'Filler Order Number / Imaging Service Request',
  x00402400: 'Imaging Service Request Comments',
  x00403001: 'Confidentiality Constraint on Patient Data Description',
  x00404001: 'General Purpose Scheduled Procedure Step Status',
  x00404002: 'General Purpose Performed Procedure Step Status',
  x00404003: 'General Purpose Scheduled Procedure Step Priority',
  x00404004: 'Scheduled Processing Applications Code Sequence',
  x00404005: 'Scheduled Procedure Step Start DateTime',
  x00404006: 'Multiple Copies Flag',
  x00404007: 'Performed Processing Applications Code Sequence',
  x00404009: 'Human Performer Code Sequence',
  x00404010: 'Scheduled Procedure Step Modification DateTime',
  x00404011: 'Expected Completion DateTime',
  x00404015: 'Resulting General Purpose Performed Procedure Steps Sequence',
  x00404016: 'Referenced General Purpose Scheduled Procedure Step Sequence',
  x00404018: 'Scheduled Workitem Code Sequence',
  x00404019: 'Performed Workitem Code Sequence',
  x00404020: 'Input Availability Flag',
  x00404021: 'Input Information Sequence',
  x00404022: 'Relevant Information Sequence',
  x00404023: 'Referenced General Purpose Scheduled Procedure Step Transaction UID',
  x00404025: 'Scheduled Station Name Code Sequence',
  x00404026: 'Scheduled Station Class Code Sequence',
  x00404027: 'Scheduled Station Geographic Location Code Sequence',
  x00404028: 'Performed Station Name Code Sequence',
  x00404029: 'Performed Station Class Code Sequence',
  x00404030: 'Performed Station Geographic Location Code Sequence',
  x00404031: 'Requested Subsequent Workitem Code Sequence',
  x00404032: 'Non-DICOM Output Code Sequence',
  x00404033: 'Output Information Sequence',
  x00404034: 'Scheduled Human Performers Sequence',
  x00404035: 'Actual Human Performers Sequence',
  x00404036: 'Human Performer`s Organization',
  x00404037: 'Human Performer`s Name',
  x00404040: 'Raw Data Handling',
  x00404041: 'Input Readiness State',
  x00404050: 'Performed Procedure Step Start DateTime',
  x00404051: 'Performed Procedure Step End DateTime',
  x00404052: 'Procedure Step Cancellation DateTime',
  x00404070: 'Output Destination Sequence',
  x00404071: 'DICOM Storage Sequence',
  x00404072: 'STOW-RS Storage Sequence',
  x00404073: 'Storage URL',
  x00404074: 'XDS Storage Sequence',
  x00408302: 'Entrance Dose in mGy',
  x00409092: 'Parametric Map Frame Type Sequence',
  x00409094: 'Referenced Image Real World Value Mapping Sequence',
  x00409096: 'Real World Value Mapping Sequence',
  x00409098: 'Pixel Value Mapping Code Sequence',
  x00409210: 'LUT Label',
  x00409211: 'Real World Value Last Value Mapped',
  x00409212: 'Real World Value LUT Data',
  x00409213: 'Double Float Real World Value Last Value Mapped',
  x00409214: 'Double Float Real World Value First Value Mapped',
  x00409216: 'Real World Value First Value Mapped',
  x00409220: 'Quantity Definition Sequence',
  x00409224: 'Real World Value Intercept',
  x00409225: 'Real World Value Slope',
  x0040A007: 'Findings Flag (Trial)',
  x0040A010: 'Relationship Type',
  x0040A020: 'Findings Sequence (Trial)',
  x0040A021: 'Findings Group UID (Trial)',
  x0040A022: 'Referenced Findings Group UID (Trial)',
  x0040A023: 'Findings Group Recording Date (Trial)',
  x0040A024: 'Findings Group Recording Time (Trial)',
  x0040A026: 'Findings Source Category Code Sequence (Trial)',
  x0040A027: 'Verifying Organization',
  x0040A028: 'Documenting Organization Identifier Code Sequence (Trial)',
  x0040A030: 'Verification DateTime',
  x0040A032: 'Observation DateTime',
  x0040A040: 'Value Type',
  x0040A043: 'Concept Name Code Sequence',
  x0040A047: 'Measurement Precision Description (Trial)',
  x0040A050: 'Continuity Of Content',
  x0040A057: 'Urgency or Priority Alerts (Trial)',
  x0040A060: 'Sequencing Indicator (Trial)',
  x0040A066: 'Document Identifier Code Sequence (Trial)',
  x0040A067: 'Document Author (Trial)',
  x0040A068: 'Document Author Identifier Code Sequence (Trial)',
  x0040A070: 'Identifier Code Sequence (Trial)',
  x0040A073: 'Verifying Observer Sequence',
  x0040A074: 'Object Binary Identifier (Trial)',
  x0040A075: 'Verifying Observer Name',
  x0040A076: 'Documenting Observer Identifier Code Sequence (Trial)',
  x0040A078: 'Author Observer Sequence',
  x0040A07A: 'Participant Sequence',
  x0040A07C: 'Custodial Organization Sequence',
  x0040A080: 'Participation Type',
  x0040A082: 'Participation DateTime',
  x0040A084: 'Observer Type',
  x0040A085: 'Procedure Identifier Code Sequence (Trial)',
  x0040A088: 'Verifying Observer Identification Code Sequence',
  x0040A089: 'Object Directory Binary Identifier (Trial)',
  x0040A090: 'Equivalent CDA Document Sequence',
  x0040A0B0: 'Referenced Waveform Channels',
  x0040A110: 'Date of Document or Verbal Transaction (Trial)',
  x0040A112: 'Time of Document Creation or Verbal Transaction (Trial)',
  x0040A120: 'DateTime',
  x0040A121: 'Date',
  x0040A122: 'Time',
  x0040A123: 'Person Name',
  x0040A124: 'UID',
  x0040A125: 'Report Status ID (Trial)',
  x0040A130: 'Temporal Range Type',
  x0040A132: 'Referenced Sample Positions',
  x0040A136: 'Referenced Frame Numbers',
  x0040A138: 'Referenced Time Offsets',
  x0040A13A: 'Referenced DateTime',
  x0040A160: 'Text Value',
  x0040A161: 'Floating Point Value',
  x0040A162: 'Rational Numerator Value',
  x0040A163: 'Rational Denominator Value',
  x0040A167: 'Observation Category Code Sequence (Trial)',
  x0040A168: 'Concept Code Sequence',
  x0040A16A: 'Bibliographic Citation (Trial)',
  x0040A170: 'Purpose of Reference Code Sequence',
  x0040A171: 'Observation UID',
  x0040A172: 'Referenced Observation UID (Trial)',
  x0040A173: 'Referenced Observation Class (Trial)',
  x0040A174: 'Referenced Object Observation Class (Trial)',
  x0040A180: 'Annotation Group Number',
  x0040A192: 'Observation Date (Trial)',
  x0040A193: 'Observation Time (Trial)',
  x0040A194: 'Measurement Automation (Trial)',
  x0040A195: 'Modifier Code Sequence',
  x0040A224: 'Identification Description (Trial)',
  x0040A290: 'Coordinates Set Geometric Type (Trial)',
  x0040A296: 'Algorithm Code Sequence (Trial)',
  x0040A297: 'Algorithm Description (Trial)',
  x0040A29A: 'Pixel Coordinates Set (Trial)',
  x0040A300: 'Measured Value Sequence',
  x0040A301: 'Numeric Value Qualifier Code Sequence',
  x0040A307: 'Current Observer (Trial)',
  x0040A30A: 'Numeric Value',
  x0040A313: 'Referenced Accession Sequence (Trial)',
  x0040A33A: 'Report Status Comment (Trial)',
  x0040A340: 'Procedure Context Sequence (Trial)',
  x0040A352: 'Verbal Source (Trial)',
  x0040A353: 'Address (Trial)',
  x0040A354: 'Telephone Number (Trial)',
  x0040A358: 'Verbal Source Identifier Code Sequence (Trial)',
  x0040A360: 'Predecessor Documents Sequence',
  x0040A370: 'Referenced Request Sequence',
  x0040A372: 'Performed Procedure Code Sequence',
  x0040A375: 'Current Requested Procedure Evidence Sequence',
  x0040A380: 'Report Detail Sequence (Trial)',
  x0040A385: 'Pertinent Other Evidence Sequence',
  x0040A390: 'HL7 Structured Document Reference Sequence',
  x0040A402: 'Observation Subject UID (Trial)',
  x0040A403: 'Observation Subject Class (Trial)',
  x0040A404: 'Observation Subject Type Code Sequence (Trial)',
  x0040A491: 'Completion Flag',
  x0040A492: 'Completion Flag Description',
  x0040A493: 'Verification Flag',
  x0040A494: 'Archive Requested',
  x0040A496: 'Preliminary Flag',
  x0040A504: 'Content Template Sequence',
  x0040A525: 'Identical Documents Sequence',
  x0040A600: 'Observation Subject Context Flag (Trial)',
  x0040A601: 'Observer Context Flag (Trial)',
  x0040A603: 'Procedure Context Flag (Trial)',
  x0040A730: 'Content Sequence',
  x0040A731: 'Relationship Sequence (Trial)',
  x0040A732: 'Relationship Type Code Sequence (Trial)',
  x0040A744: 'Language Code Sequence (Trial)',
  x0040A992: 'Uniform Resource Locator (Trial)',
  x0040B020: 'Waveform Annotation Sequence',
  x0040DB00: 'Template Identifier',
  x0040DB06: 'Template Version',
  x0040DB07: 'Template Local Version',
  x0040DB0B: 'Template Extension Flag',
  x0040DB0C: 'Template Extension Organization UID',
  x0040DB0D: 'Template Extension Creator UID',
  x0040DB73: 'Referenced Content Item Identifier',
  x0040E001: 'HL7 Instance Identifier',
  x0040E004: 'HL7 Document Effective Time',
  x0040E006: 'HL7 Document Type Code Sequence',
  x0040E008: 'Document Class Code Sequence',
  x0040E010: 'Retrieve URI',
  x0040E011: 'Retrieve Location UID',
  x0040E020: 'Type of Instances',
  x0040E021: 'DICOM Retrieval Sequence',
  x0040E022: 'DICOM Media Retrieval Sequence',
  x0040E023: 'WADO Retrieval Sequence',
  x0040E024: 'XDS Retrieval Sequence',
  x0040E025: 'WADO-RS Retrieval Sequence',
  x0040E030: 'Repository Unique ID',
  x0040E031: 'Home Community ID',
  x00420010: 'Document Title',
  x00420011: 'Encapsulated Document',
  x00420012: 'MIME Type of Encapsulated Document',
  x00420013: 'Source Instance Sequence',
  x00420014: 'List of MIME Types',
  x00440001: 'Product Package Identifier',
  x00440002: 'Substance Administration Approval',
  x00440003: 'Approval Status Further Description',
  x00440004: 'Approval Status DateTime',
  x00440007: 'Product Type Code Sequence',
  x00440008: 'Product Name',
  x00440009: 'Product Description',
  x0044000A: 'Product Lot Identifier',
  x0044000B: 'Product Expiration DateTime',
  x00440010: 'Substance Administration DateTime',
  x00440011: 'Substance Administration Notes',
  x00440012: 'Substance Administration Device ID',
  x00440013: 'Product Parameter Sequence',
  x00440019: 'Substance Administration Parameter Sequence',
  x00460012: 'Lens Description',
  x00460014: 'Right Lens Sequence',
  x00460015: 'Left Lens Sequence',
  x00460016: 'Unspecified Laterality Lens Sequence',
  x00460018: 'Cylinder Sequence',
  x00460028: 'Prism Sequence',
  x00460030: 'Horizontal Prism Power',
  x00460032: 'Horizontal Prism Base',
  x00460034: 'Vertical Prism Power',
  x00460036: 'Vertical Prism Base',
  x00460038: 'Lens Segment Type',
  x00460040: 'Optical Transmittance',
  x00460042: 'Channel Width',
  x00460044: 'Pupil Size',
  x00460046: 'Corneal Size',
  x00460050: 'Autorefraction Right Eye Sequence',
  x00460052: 'Autorefraction Left Eye Sequence',
  x00460060: 'Distance Pupillary Distance',
  x00460062: 'Near Pupillary Distance',
  x00460063: 'Intermediate Pupillary Distance',
  x00460064: 'Other Pupillary Distance',
  x00460070: 'Keratometry Right Eye Sequence',
  x00460071: 'Keratometry Left Eye Sequence',
  x00460074: 'Steep Keratometric Axis Sequence',
  x00460075: 'Radius of Curvature',
  x00460076: 'Keratometric Power',
  x00460077: 'Keratometric Axis',
  x00460080: 'Flat Keratometric Axis Sequence',
  x00460092: 'Background Color',
  x00460094: 'Optotype',
  x00460095: 'Optotype Presentation',
  x00460097: 'Subjective Refraction Right Eye Sequence',
  x00460098: 'Subjective Refraction Left Eye Sequence',
  x00460100: 'Add Near Sequence',
  x00460101: 'Add Intermediate Sequence',
  x00460102: 'Add Other Sequence',
  x00460104: 'Add Power',
  x00460106: 'Viewing Distance',
  x00460121: 'Visual Acuity Type Code Sequence',
  x00460122: 'Visual Acuity Right Eye Sequence',
  x00460123: 'Visual Acuity Left Eye Sequence',
  x00460124: 'Visual Acuity Both Eyes Open Sequence',
  x00460125: 'Viewing Distance Type',
  x00460135: 'Visual Acuity Modifiers',
  x00460137: 'Decimal Visual Acuity',
  x00460139: 'Optotype Detailed Definition',
  x00460145: 'Referenced Refractive Measurements Sequence',
  x00460146: 'Sphere Power',
  x00460147: 'Cylinder Power',
  x00460201: 'Corneal Topography Surface',
  x00460202: 'Corneal Vertex Location',
  x00460203: 'Pupil Centroid X-Coordinate',
  x00460204: 'Pupil Centroid Y-Coordinate',
  x00460205: 'Equivalent Pupil Radius',
  x00460207: 'Corneal Topography Map Type Code Sequence',
  x00460208: 'Vertices of the Outline of Pupil',
  x00460210: 'Corneal Topography Mapping Normals Sequence',
  x00460211: 'Maximum Corneal Curvature Sequence',
  x00460212: 'Maximum Corneal Curvature',
  x00460213: 'Maximum Corneal Curvature Location',
  x00460215: 'Minimum Keratometric Sequence',
  x00460218: 'Simulated Keratometric Cylinder Sequence',
  x00460220: 'Average Corneal Power',
  x00460224: 'Corneal I-S Value',
  x00460227: 'Analyzed Area',
  x00460230: 'Surface Regularity Index',
  x00460232: 'Surface Asymmetry Index',
  x00460234: 'Corneal Eccentricity Index',
  x00460236: 'Keratoconus Prediction Index',
  x00460238: 'Decimal Potential Visual Acuity',
  x00460242: 'Corneal Topography Map Quality Evaluation',
  x00460244: 'Source Image Corneal Processed Data Sequence',
  x00460247: 'Corneal Point Location',
  x00460248: 'Corneal Point Estimated',
  x00460249: 'Axial Power',
  x00460250: 'Tangential Power',
  x00460251: 'Refractive Power',
  x00460252: 'Relative Elevation',
  x00460253: 'Corneal Wavefront',
  x00480001: 'Imaged Volume Width',
  x00480002: 'Imaged Volume Height',
  x00480003: 'Imaged Volume Depth',
  x00480006: 'Total Pixel Matrix Columns',
  x00480007: 'Total Pixel Matrix Rows',
  x00480008: 'Total Pixel Matrix Origin Sequence',
  x00480010: 'Specimen Label in Image',
  x00480011: 'Focus Method',
  x00480012: 'Extended Depth of Field',
  x00480013: 'Number of Focal Planes',
  x00480014: 'Distance Between Focal Planes',
  x00480015: 'Recommended Absent Pixel CIELab Value',
  x00480100: 'Illuminator Type Code Sequence',
  x00480102: 'Image Orientation (Slide)',
  x00480105: 'Optical Path Sequence',
  x00480106: 'Optical Path Identifier',
  x00480107: 'Optical Path Description',
  x00480108: 'Illumination Color Code Sequence',
  x00480110: 'Specimen Reference Sequence',
  x00480111: 'Condenser Lens Power',
  x00480112: 'Objective Lens Power',
  x00480113: 'Objective Lens Numerical Aperture',
  x00480120: 'Palette Color Lookup Table Sequence',
  x00480200: 'Referenced Image Navigation Sequence',
  x00480201: 'Top Left Hand Corner of Localizer Area',
  x00480202: 'Bottom Right Hand Corner of Localizer Area',
  x00480207: 'Optical Path Identification Sequence',
  x0048021A: 'Plane Position (Slide) Sequence',
  x0048021E: 'Column Position In Total Image Pixel Matrix',
  x0048021F: 'Row Position In Total Image Pixel Matrix',
  x00480301: 'Pixel Origin Interpretation',
  x00500004: 'Calibration Image',
  x00500010: 'Device Sequence',
  x00500012: 'Container Component Type Code Sequence',
  x00500013: 'Container Component Thickness',
  x00500014: 'Device Length',
  x00500015: 'Container Component Width',
  x00500016: 'Device Diameter',
  x00500017: 'Device Diameter Units',
  x00500018: 'Device Volume',
  x00500019: 'Inter-Marker Distance',
  x0050001A: 'Container Component Material',
  x0050001B: 'Container Component ID',
  x0050001C: 'Container Component Length',
  x0050001D: 'Container Component Diameter',
  x0050001E: 'Container Component Description',
  x00500020: 'Device Description',
  x00520001: 'Contrast/Bolus Ingredient Percent by Volume',
  x00520002: 'OCT Focal Distance',
  x00520003: 'Beam Spot Size',
  x00520004: 'Effective Refractive Index',
  x00520006: 'OCT Acquisition Domain',
  x00520007: 'OCT Optical Center Wavelength',
  x00520008: 'Axial Resolution',
  x00520009: 'Ranging Depth',
  x00520011: 'A-line Rate',
  x00520012: 'A-lines Per Frame',
  x00520013: 'Catheter Rotational Rate',
  x00520014: 'A-line Pixel Spacing',
  x00520016: 'Mode of Percutaneous Access Sequence',
  x00520025: 'Intravascular OCT Frame Type Sequence',
  x00520026: 'OCT Z Offset Applied',
  x00520027: 'Intravascular Frame Content Sequence',
  x00520028: 'Intravascular Longitudinal Distance',
  x00520029: 'Intravascular OCT Frame Content Sequence',
  x00520030: 'OCT Z Offset Correction',
  x00520031: 'Catheter Direction of Rotation',
  x00520033: 'Seam Line Location',
  x00520034: 'First A-line Location',
  x00520036: 'Seam Line Index',
  x00520038: 'Number of Padded A-lines',
  x00520039: 'Interpolation Type',
  x0052003A: 'Refractive Index Applied',
  x00540010: 'Energy Window Vector',
  x00540011: 'Number of Energy Windows',
  x00540012: 'Energy Window Information Sequence',
  x00540013: 'Energy Window Range Sequence',
  x00540014: 'Energy Window Lower Limit',
  x00540015: 'Energy Window Upper Limit',
  x00540016: 'Radiopharmaceutical Information Sequence',
  x00540017: 'Residual Syringe Counts',
  x00540018: 'Energy Window Name',
  x00540020: 'Detector Vector',
  x00540021: 'Number of Detectors',
  x00540022: 'Detector Information Sequence',
  x00540030: 'Phase Vector',
  x00540031: 'Number of Phases',
  x00540032: 'Phase Information Sequence',
  x00540033: 'Number of Frames in Phase',
  x00540036: 'Phase Delay',
  x00540038: 'Pause Between Frames',
  x00540039: 'Phase Description',
  x00540050: 'Rotation Vector',
  x00540051: 'Number of Rotations',
  x00540052: 'Rotation Information Sequence',
  x00540053: 'Number of Frames in Rotation',
  x00540060: 'R-R Interval Vector',
  x00540061: 'Number of R-R Intervals',
  x00540062: 'Gated Information Sequence',
  x00540063: 'Data Information Sequence',
  x00540070: 'Time Slot Vector',
  x00540071: 'Number of Time Slots',
  x00540072: 'Time Slot Information Sequence',
  x00540073: 'Time Slot Time',
  x00540080: 'Slice Vector',
  x00540081: 'Number of Slices',
  x00540090: 'Angular View Vector',
  x00540100: 'Time Slice Vector',
  x00540101: 'Number of Time Slices',
  x00540200: 'Start Angle',
  x00540202: 'Type of Detector Motion',
  x00540210: 'Trigger Vector',
  x00540211: 'Number of Triggers in Phase',
  x00540220: 'View Code Sequence',
  x00540222: 'View Modifier Code Sequence',
  x00540300: 'Radionuclide Code Sequence',
  x00540302: 'Administration Route Code Sequence',
  x00540304: 'Radiopharmaceutical Code Sequence',
  x00540306: 'Calibration Data Sequence',
  x00540308: 'Energy Window Number',
  x00540400: 'Image ID',
  x00540410: 'Patient Orientation Code Sequence',
  x00540412: 'Patient Orientation Modifier Code Sequence',
  x00540414: 'Patient Gantry Relationship Code Sequence',
  x00540500: 'Slice Progression Direction',
  x00540501: 'Scan Progression Direction',
  x00541000: 'Series Type',
  x00541001: 'Units',
  x00541002: 'Counts Source',
  x00541004: 'Reprojection Method',
  x00541006: 'SUV Type',
  x00541100: 'Randoms Correction Method',
  x00541101: 'Attenuation Correction Method',
  x00541102: 'Decay Correction',
  x00541103: 'Reconstruction Method',
  x00541104: 'Detector Lines of Response Used',
  x00541105: 'Scatter Correction Method',
  x00541200: 'Axial Acceptance',
  x00541201: 'Axial Mash',
  x00541202: 'Transverse Mash',
  x00541203: 'Detector Element Size',
  x00541210: 'Coincidence Window Width',
  x00541220: 'Secondary Counts Type',
  x00541300: 'Frame Reference Time',
  x00541310: 'Primary (Prompts) Counts Accumulated',
  x00541311: 'Secondary Counts Accumulated',
  x00541320: 'Slice Sensitivity Factor',
  x00541321: 'Decay Factor',
  x00541322: 'Dose Calibration Factor',
  x00541323: 'Scatter Fraction Factor',
  x00541324: 'Dead Time Factor',
  x00541330: 'Image Index',
  x00541400: 'Counts Included',
  x00541401: 'Dead Time Correction Flag',
  x00603000: 'Histogram Sequence',
  x00603002: 'Histogram Number of Bins',
  x00603004: 'Histogram First Bin Value',
  x00603006: 'Histogram Last Bin Value',
  x00603008: 'Histogram Bin Width',
  x00603010: 'Histogram Explanation',
  x00603020: 'Histogram Data',
  x00620001: 'Segmentation Type',
  x00620002: 'Segment Sequence',
  x00620003: 'Segmented Property Category Code Sequence',
  x00620004: 'Segment Number',
  x00620005: 'Segment Label',
  x00620006: 'Segment Description',
  x00620008: 'Segment Algorithm Type',
  x00620009: 'Segment Algorithm Name',
  x0062000A: 'Segment Identification Sequence',
  x0062000B: 'Referenced Segment Number',
  x0062000C: 'Recommended Display Grayscale Value',
  x0062000D: 'Recommended Display CIELab Value',
  x0062000E: 'Maximum Fractional Value',
  x0062000F: 'Segmented Property Type Code Sequence',
  x00620010: 'Segmentation Fractional Type',
  x00620011: 'Segmented Property Type Modifier Code Sequence',
  x00620012: 'Used Segments Sequence',
  x00620020: 'Tracking ID',
  x00620021: 'Tracking UID',
  x00640002: 'Deformable Registration Sequence',
  x00640003: 'Source Frame of Reference UID',
  x00640005: 'Deformable Registration Grid Sequence',
  x00640007: 'Grid Dimensions',
  x00640008: 'Grid Resolution',
  x00640009: 'Vector Grid Data',
  x0064000F: 'Pre Deformation Matrix Registration Sequence',
  x00640010: 'Post Deformation Matrix Registration Sequence',
  x00660001: 'Number of Surfaces',
  x00660002: 'Surface Sequence',
  x00660003: 'Surface Number',
  x00660004: 'Surface Comments',
  x00660009: 'Surface Processing',
  x0066000A: 'Surface Processing Ratio',
  x0066000B: 'Surface Processing Description',
  x0066000C: 'Recommended Presentation Opacity',
  x0066000D: 'Recommended Presentation Type',
  x0066000E: 'Finite Volume',
  x00660010: 'Manifold',
  x00660011: 'Surface Points Sequence',
  x00660012: 'Surface Points Normals Sequence',
  x00660013: 'Surface Mesh Primitives Sequence',
  x00660015: 'Number of Surface Points',
  x00660016: 'Point Coordinates Data',
  x00660017: 'Point Position Accuracy',
  x00660018: 'Mean Point Distance',
  x00660019: 'Maximum Point Distance',
  x0066001A: 'Points Bounding Box Coordinates',
  x0066001B: 'Axis of Rotation',
  x0066001C: 'Center of Rotation',
  x0066001E: 'Number of Vectors',
  x0066001F: 'Vector Dimensionality',
  x00660020: 'Vector Accuracy',
  x00660021: 'Vector Coordinate Data',
  x00660023: 'Triangle Point Index List',
  x00660024: 'Edge Point Index List',
  x00660025: 'Vertex Point Index List',
  x00660026: 'Triangle Strip Sequence',
  x00660027: 'Triangle Fan Sequence',
  x00660028: 'Line Sequence',
  x00660029: 'Primitive Point Index List',
  x0066002A: 'Surface Count',
  x0066002B: 'Referenced Surface Sequence',
  x0066002C: 'Referenced Surface Number',
  x0066002D: 'Segment Surface Generation Algorithm Identification Sequence',
  x0066002E: 'Segment Surface Source Instance Sequence',
  x0066002F: 'Algorithm Family Code Sequence',
  x00660030: 'Algorithm Name Code Sequence',
  x00660031: 'Algorithm Version',
  x00660032: 'Algorithm Parameters',
  x00660034: 'Facet Sequence',
  x00660035: 'Surface Processing Algorithm Identification Sequence',
  x00660036: 'Algorithm Name',
  x00660037: 'Recommended Point Radius',
  x00660038: 'Recommended Line Thickness',
  x00660040: 'Long Primitive Point Index List',
  x00660041: 'Long Triangle Point Index List',
  x00660042: 'Long Edge Point Index List',
  x00660043: 'Long Vertex Point Index List',
  x00660101: 'Track Set Sequence',
  x00660102: 'Track Sequence',
  x00660103: 'Recommended Display CIELab Value List',
  x00660104: 'Tracking Algorithm Identification Sequence',
  x00660105: 'Track Set Number',
  x00660106: 'Track Set Label',
  x00660107: 'Track Set Description',
  x00660108: 'Track Set Anatomical Type Code Sequence',
  x00660121: 'Measurements Sequence',
  x00660124: 'Track Set Statistics Sequence',
  x00660125: 'Floating Point Values',
  x00660129: 'Track Point Index List',
  x00660130: 'Track Statistics Sequence',
  x00660132: 'Measurement Values Sequence',
  x00660133: 'Diffusion Acquisition Code Sequence',
  x00660134: 'Diffusion Model Code Sequence',
  x00686210: 'Implant Size',
  x00686221: 'Implant Template Version',
  x00686222: 'Replaced Implant Template Sequence',
  x00686223: 'Implant Type',
  x00686224: 'Derivation Implant Template Sequence',
  x00686225: 'Original Implant Template Sequence',
  x00686226: 'Effective DateTime',
  x00686230: 'Implant Target Anatomy Sequence',
  x00686260: 'Information From Manufacturer Sequence',
  x00686265: 'Notification From Manufacturer Sequence',
  x00686270: 'Information Issue DateTime',
  x00686280: 'Information Summary',
  x006862A0: 'Implant Regulatory Disapproval Code Sequence',
  x006862A5: 'Overall Template Spatial Tolerance',
  x006862C0: 'HPGL Document Sequence',
  x006862D0: 'HPGL Document ID',
  x006862D5: 'HPGL Document Label',
  x006862E0: 'View Orientation Code Sequence',
  x006862F0: 'View Orientation Modifier',
  x006862F2: 'HPGL Document Scaling',
  x00686300: 'HPGL Document',
  x00686310: 'HPGL Contour Pen Number',
  x00686320: 'HPGL Pen Sequence',
  x00686330: 'HPGL Pen Number',
  x00686340: 'HPGL Pen Label',
  x00686345: 'HPGL Pen Description',
  x00686346: 'Recommended Rotation Point',
  x00686347: 'Bounding Rectangle',
  x00686350: 'Implant Template 3D Model Surface Number',
  x00686360: 'Surface Model Description Sequence',
  x00686380: 'Surface Model Label',
  x00686390: 'Surface Model Scaling Factor',
  x006863A0: 'Materials Code Sequence',
  x006863A4: 'Coating Materials Code Sequence',
  x006863A8: 'Implant Type Code Sequence',
  x006863AC: 'Fixation Method Code Sequence',
  x006863B0: 'Mating Feature Sets Sequence',
  x006863C0: 'Mating Feature Set ID',
  x006863D0: 'Mating Feature Set Label',
  x006863E0: 'Mating Feature Sequence',
  x006863F0: 'Mating Feature ID',
  x00686400: 'Mating Feature Degree of Freedom Sequence',
  x00686410: 'Degree of Freedom ID',
  x00686420: 'Degree of Freedom Type',
  x00686430: '2D Mating Feature Coordinates Sequence',
  x00686440: 'Referenced HPGL Document ID',
  x00686450: '2D Mating Point',
  x00686460: '2D Mating Axes',
  x00686470: '2D Degree of Freedom Sequence',
  x00686490: '3D Degree of Freedom Axis',
  x006864A0: 'Range of Freedom',
  x006864C0: '3D Mating Point',
  x006864D0: '3D Mating Axes',
  x006864F0: '2D Degree of Freedom Axis',
  x00686500: 'Planning Landmark Point Sequence',
  x00686510: 'Planning Landmark Line Sequence',
  x00686520: 'Planning Landmark Plane Sequence',
  x00686530: 'Planning Landmark ID',
  x00686540: 'Planning Landmark Description',
  x00686545: 'Planning Landmark Identification Code Sequence',
  x00686550: '2D Point Coordinates Sequence',
  x00686560: '2D Point Coordinates',
  x00686590: '3D Point Coordinates',
  x006865A0: '2D Line Coordinates Sequence',
  x006865B0: '2D Line Coordinates',
  x006865D0: '3D Line Coordinates',
  x006865E0: '2D Plane Coordinates Sequence',
  x006865F0: '2D Plane Intersection',
  x00686610: '3D Plane Origin',
  x00686620: '3D Plane Normal',
  x00700001: 'Graphic Annotation Sequence',
  x00700002: 'Graphic Layer',
  x00700003: 'Bounding Box Annotation Units',
  x00700004: 'Anchor Point Annotation Units',
  x00700005: 'Graphic Annotation Units',
  x00700006: 'Unformatted Text Value',
  x00700008: 'Text Object Sequence',
  x00700009: 'Graphic Object Sequence',
  x00700010: 'Bounding Box Top Left Hand Corner',
  x00700011: 'Bounding Box Bottom Right Hand Corner',
  x00700012: 'Bounding Box Text Horizontal Justification',
  x00700014: 'Anchor Point',
  x00700015: 'Anchor Point Visibility',
  x00700020: 'Graphic Dimensions',
  x00700021: 'Number of Graphic Points',
  x00700022: 'Graphic Data',
  x00700023: 'Graphic Type',
  x00700024: 'Graphic Filled',
  x00700040: 'Image Rotation (Retired)',
  x00700041: 'Image Horizontal Flip',
  x00700042: 'Image Rotation',
  x00700050: 'Displayed Area Top Left Hand Corner (Trial)',
  x00700051: 'Displayed Area Bottom Right Hand Corner (Trial)',
  x00700052: 'Displayed Area Top Left Hand Corner',
  x00700053: 'Displayed Area Bottom Right Hand Corner',
  x0070005A: 'Displayed Area Selection Sequence',
  x00700060: 'Graphic Layer Sequence',
  x00700062: 'Graphic Layer Order',
  x00700066: 'Graphic Layer Recommended Display Grayscale Value',
  x00700067: 'Graphic Layer Recommended Display RGB Value',
  x00700068: 'Graphic Layer Description',
  x00700080: 'Content Label',
  x00700081: 'Content Description',
  x00700082: 'Presentation Creation Date',
  x00700083: 'Presentation Creation Time',
  x00700084: 'Content Creator`s Name',
  x00700086: 'Content Creator`s Identification Code Sequence',
  x00700087: 'Alternate Content Description Sequence',
  x00700100: 'Presentation Size Mode',
  x00700101: 'Presentation Pixel Spacing',
  x00700102: 'Presentation Pixel Aspect Ratio',
  x00700103: 'Presentation Pixel Magnification Ratio',
  x00700207: 'Graphic Group Label',
  x00700208: 'Graphic Group Description',
  x00700209: 'Compound Graphic Sequence',
  x00700226: 'Compound Graphic Instance ID',
  x00700227: 'Font Name',
  x00700228: 'Font Name Type',
  x00700229: 'CSS Font Name',
  x00700230: 'Rotation Angle',
  x00700231: 'Text Style Sequence',
  x00700232: 'Line Style Sequence',
  x00700233: 'Fill Style Sequence',
  x00700234: 'Graphic Group Sequence',
  x00700241: 'Text Color CIELab Value',
  x00700242: 'Horizontal Alignment',
  x00700243: 'Vertical Alignment',
  x00700244: 'Shadow Style',
  x00700245: 'Shadow Offset X',
  x00700246: 'Shadow Offset Y',
  x00700247: 'Shadow Color CIELab Value',
  x00700248: 'Underlined',
  x00700249: 'Bold',
  x00700250: 'Italic',
  x00700251: 'Pattern On Color CIELab Value',
  x00700252: 'Pattern Off Color CIELab Value',
  x00700253: 'Line Thickness',
  x00700254: 'Line Dashing Style',
  x00700255: 'Line Pattern',
  x00700256: 'Fill Pattern',
  x00700257: 'Fill Mode',
  x00700258: 'Shadow Opacity',
  x00700261: 'Gap Length',
  x00700262: 'Diameter of Visibility',
  x00700273: 'Rotation Point',
  x00700274: 'Tick Alignment',
  x00700278: 'Show Tick Label',
  x00700279: 'Tick Label Alignment',
  x00700282: 'Compound Graphic Units',
  x00700284: 'Pattern On Opacity',
  x00700285: 'Pattern Off Opacity',
  x00700287: 'Major Ticks Sequence',
  x00700288: 'Tick Position',
  x00700289: 'Tick Label',
  x00700294: 'Compound Graphic Type',
  x00700295: 'Graphic Group ID',
  x00700306: 'Shape Type',
  x00700308: 'Registration Sequence',
  x00700309: 'Matrix Registration Sequence',
  x0070030A: 'Matrix Sequence',
  x0070030B: 'Frame of Reference to Displayed Coordinate System Transformation Matrix',
  x0070030C: 'Frame of Reference Transformation Matrix Type',
  x0070030D: 'Registration Type Code Sequence',
  x0070030F: 'Fiducial Description',
  x00700310: 'Fiducial Identifier',
  x00700311: 'Fiducial Identifier Code Sequence',
  x00700312: 'Contour Uncertainty Radius',
  x00700314: 'Used Fiducials Sequence',
  x00700318: 'Graphic Coordinates Data Sequence',
  x0070031A: 'Fiducial UID',
  x0070031C: 'Fiducial Set Sequence',
  x0070031E: 'Fiducial Sequence',
  x00700401: 'Graphic Layer Recommended Display CIELab Value',
  x00700402: 'Blending Sequence',
  x00700403: 'Relative Opacity',
  x00700404: 'Referenced Spatial Registration Sequence',
  x00700405: 'Blending Position',
  x00701101: 'Presentation Display Collection UID',
  x00701102: 'Presentation Sequence Collection UID',
  x00701103: 'Presentation Sequence Position Index',
  x00701104: 'Rendered Image Reference Sequence',
  x00701201: 'Volumetric Presentation State Input Sequence',
  x00701202: 'Presentation Input Type',
  x00701203: 'Input Sequence Position Index',
  x00701204: 'Crop',
  x00701205: 'Cropping Specification Index',
  x00701206: 'Compositing Method',
  x00701207: 'Volumetric Presentation Input Number',
  x00701208: 'Image Volume Geometry',
  x00701301: 'Volume Cropping Sequence',
  x00701302: 'Volume Cropping Method',
  x00701303: 'Bounding Box Crop',
  x00701304: 'Oblique Cropping Plane Sequence',
  x00701305: 'Plane',
  x00701306: 'Plane Normal',
  x00701309: 'Cropping Specification Number',
  x00701501: 'Multi-Planar Reconstruction Style',
  x00701502: 'MPR Thickness Type',
  x00701503: 'MPR Slab Thickness',
  x00701505: 'MPR Top Left Hand Corner',
  x00701507: 'MPR View Width Direction',
  x00701508: 'MPR View Width',
  x0070150C: 'Number of Volumetric Curve Points',
  x0070150D: 'Volumetric Curve Points',
  x00701511: 'MPR View Height Direction',
  x00701512: 'MPR View Height',
  x00701801: 'Presentation State Classification Component Sequence',
  x00701802: 'Component Type',
  x00701803: 'Component Input Sequence',
  x00701804: 'Volumetric Presentation Input Index',
  x00701805: 'Presentation State Compositor Component Sequence',
  x00701806: 'Weighting Transfer Function Sequence',
  x00701807: 'Weighting Lookup Table Descriptor',
  x00701808: 'Weighting Lookup Table Data',
  x00701901: 'Volumetric Annotation Sequence',
  x00701903: 'Referenced Structured Context Sequence',
  x00701904: 'Referenced Content Item',
  x00701905: 'Volumetric Presentation Input Annotation Sequence',
  x00701907: 'Annotation Clipping',
  x00701A01: 'Presentation Animation Style',
  x00701A03: 'Recommended Animation Rate',
  x00701A04: 'Animation Curve Sequence',
  x00701A05: 'Animation Step Size',
  x00720002: 'Hanging Protocol Name',
  x00720004: 'Hanging Protocol Description',
  x00720006: 'Hanging Protocol Level',
  x00720008: 'Hanging Protocol Creator',
  x0072000A: 'Hanging Protocol Creation DateTime',
  x0072000C: 'Hanging Protocol Definition Sequence',
  x0072000E: 'Hanging Protocol User Identification Code Sequence',
  x00720010: 'Hanging Protocol User Group Name',
  x00720012: 'Source Hanging Protocol Sequence',
  x00720014: 'Number of Priors Referenced',
  x00720020: 'Image Sets Sequence',
  x00720022: 'Image Set Selector Sequence',
  x00720024: 'Image Set Selector Usage Flag',
  x00720026: 'Selector Attribute',
  x00720028: 'Selector Value Number',
  x00720030: 'Time Based Image Sets Sequence',
  x00720032: 'Image Set Number',
  x00720034: 'Image Set Selector Category',
  x00720038: 'Relative Time',
  x0072003A: 'Relative Time Units',
  x0072003C: 'Abstract Prior Value',
  x0072003E: 'Abstract Prior Code Sequence',
  x00720040: 'Image Set Label',
  x00720050: 'Selector Attribute VR',
  x00720052: 'Selector Sequence Pointer',
  x00720054: 'Selector Sequence Pointer Private Creator',
  x00720056: 'Selector Attribute Private Creator',
  x0072005E: 'Selector AE Value',
  x0072005F: 'Selector AS Value',
  x00720060: 'Selector AT Value',
  x00720061: 'Selector DA Value',
  x00720062: 'Selector CS Value',
  x00720063: 'Selector DT Value',
  x00720064: 'Selector IS Value',
  x00720065: 'Selector OB Value',
  x00720066: 'Selector LO Value',
  x00720067: 'Selector OF Value',
  x00720068: 'Selector LT Value',
  x00720069: 'Selector OW Value',
  x0072006A: 'Selector PN Value',
  x0072006B: 'Selector TM Value',
  x0072006C: 'Selector SH Value',
  x0072006D: 'Selector UN Value',
  x0072006E: 'Selector ST Value',
  x0072006F: 'Selector UC Value',
  x00720070: 'Selector UT Value',
  x00720071: 'Selector UR Value',
  x00720072: 'Selector DS Value',
  x00720073: 'Selector OD Value',
  x00720074: 'Selector FD Value',
  x00720075: 'Selector OL Value',
  x00720076: 'Selector FL Value',
  x00720078: 'Selector UL Value',
  x0072007A: 'Selector US Value',
  x0072007C: 'Selector SL Value',
  x0072007E: 'Selector SS Value',
  x0072007F: 'Selector UI Value',
  x00720080: 'Selector Code Sequence Value',
  x00720100: 'Number of Screens',
  x00720102: 'Nominal Screen Definition Sequence',
  x00720104: 'Number of Vertical Pixels',
  x00720106: 'Number of Horizontal Pixels',
  x00720108: 'Display Environment Spatial Position',
  x0072010A: 'Screen Minimum Grayscale Bit Depth',
  x0072010C: 'Screen Minimum Color Bit Depth',
  x0072010E: 'Application Maximum Repaint Time',
  x00720200: 'Display Sets Sequence',
  x00720202: 'Display Set Number',
  x00720203: 'Display Set Label',
  x00720204: 'Display Set Presentation Group',
  x00720206: 'Display Set Presentation Group Description',
  x00720208: 'Partial Data Display Handling',
  x00720210: 'Synchronized Scrolling Sequence',
  x00720212: 'Display Set Scrolling Group',
  x00720214: 'Navigation Indicator Sequence',
  x00720216: 'Navigation Display Set',
  x00720218: 'Reference Display Sets',
  x00720300: 'Image Boxes Sequence',
  x00720302: 'Image Box Number',
  x00720304: 'Image Box Layout Type',
  x00720306: 'Image Box Tile Horizontal Dimension',
  x00720308: 'Image Box Tile Vertical Dimension',
  x00720310: 'Image Box Scroll Direction',
  x00720312: 'Image Box Small Scroll Type',
  x00720314: 'Image Box Small Scroll Amount',
  x00720316: 'Image Box Large Scroll Type',
  x00720318: 'Image Box Large Scroll Amount',
  x00720320: 'Image Box Overlap Priority',
  x00720330: 'Cine Relative to Real-Time',
  x00720400: 'Filter Operations Sequence',
  x00720402: 'Filter-by Category',
  x00720404: 'Filter-by Attribute Presence',
  x00720406: 'Filter-by Operator',
  x00720420: 'Structured Display Background CIELab Value',
  x00720421: 'Empty Image Box CIELab Value',
  x00720422: 'Structured Display Image Box Sequence',
  x00720424: 'Structured Display Text Box Sequence',
  x00720427: 'Referenced First Frame Sequence',
  x00720430: 'Image Box Synchronization Sequence',
  x00720432: 'Synchronized Image Box List',
  x00720434: 'Type of Synchronization',
  x00720500: 'Blending Operation Type',
  x00720510: 'Reformatting Operation Type',
  x00720512: 'Reformatting Thickness',
  x00720514: 'Reformatting Interval',
  x00720516: 'Reformatting Operation Initial View Direction',
  x00720520: '3D Rendering Type',
  x00720600: 'Sorting Operations Sequence',
  x00720602: 'Sort-by Category',
  x00720604: 'Sorting Direction',
  x00720700: 'Display Set Patient Orientation',
  x00720702: 'VOI Type',
  x00720704: 'Pseudo-Color Type',
  x00720705: 'Pseudo-Color Palette Instance Reference Sequence',
  x00720706: 'Show Grayscale Inverted',
  x00720710: 'Show Image True Size Flag',
  x00720712: 'Show Graphic Annotation Flag',
  x00720714: 'Show Patient Demographics Flag',
  x00720716: 'Show Acquisition Techniques Flag',
  x00720717: 'Display Set Horizontal Justification',
  x00720718: 'Display Set Vertical Justification',
  x00740120: 'Continuation Start Meterset',
  x00740121: 'Continuation End Meterset',
  x00741000: 'Procedure Step State',
  x00741002: 'Procedure Step Progress Information Sequence',
  x00741004: 'Procedure Step Progress',
  x00741006: 'Procedure Step Progress Description',
  x00741008: 'Procedure Step Communications URI Sequence',
  x0074100A: 'Contact URI',
  x0074100C: 'Contact Display Name',
  x0074100E: 'Procedure Step Discontinuation Reason Code Sequence',
  x00741020: 'Beam Task Sequence',
  x00741022: 'Beam Task Type',
  x00741024: 'Beam Order Index (Trial)',
  x00741025: 'Autosequence Flag',
  x00741026: 'Table Top Vertical Adjusted Position',
  x00741027: 'Table Top Longitudinal Adjusted Position',
  x00741028: 'Table Top Lateral Adjusted Position',
  x0074102A: 'Patient Support Adjusted Angle',
  x0074102B: 'Table Top Eccentric Adjusted Angle',
  x0074102C: 'Table Top Pitch Adjusted Angle',
  x0074102D: 'Table Top Roll Adjusted Angle',
  x00741030: 'Delivery Verification Image Sequence',
  x00741032: 'Verification Image Timing',
  x00741034: 'Double Exposure Flag',
  x00741036: 'Double Exposure Ordering',
  x00741038: 'Double Exposure Meterset (Trial)',
  x0074103A: 'Double Exposure Field Delta (Trial)',
  x00741040: 'Related Reference RT Image Sequence',
  x00741042: 'General Machine Verification Sequence',
  x00741044: 'Conventional Machine Verification Sequence',
  x00741046: 'Ion Machine Verification Sequence',
  x00741048: 'Failed Attributes Sequence',
  x0074104A: 'Overridden Attributes Sequence',
  x0074104C: 'Conventional Control Point Verification Sequence',
  x0074104E: 'Ion Control Point Verification Sequence',
  x00741050: 'Attribute Occurrence Sequence',
  x00741052: 'Attribute Occurrence Pointer',
  x00741054: 'Attribute Item Selector',
  x00741056: 'Attribute Occurrence Private Creator',
  x00741057: 'Selector Sequence Pointer Items',
  x00741200: 'Scheduled Procedure Step Priority',
  x00741202: 'Worklist Label',
  x00741204: 'Procedure Step Label',
  x00741210: 'Scheduled Processing Parameters Sequence',
  x00741212: 'Performed Processing Parameters Sequence',
  x00741216: 'Unified Procedure Step Performed Procedure Sequence',
  x00741220: 'Related Procedure Step Sequence',
  x00741222: 'Procedure Step Relationship Type',
  x00741224: 'Replaced Procedure Step Sequence',
  x00741230: 'Deletion Lock',
  x00741234: 'Receiving AE',
  x00741236: 'Requesting AE',
  x00741238: 'Reason for Cancellation',
  x00741242: 'SCP Status',
  x00741244: 'Subscription List Status',
  x00741246: 'Unified Procedure Step List Status',
  x00741324: 'Beam Order Index',
  x00741338: 'Double Exposure Meterset',
  x0074133A: 'Double Exposure Field Delta',
  x00741401: 'Brachy Task Sequence',
  x00741402: 'Continuation Start Total Reference Air Kerma',
  x00741403: 'Continuation End Total Reference Air Kerma',
  x00741404: 'Continuation Pulse Number',
  x00741405: 'Channel Delivery Order Sequence',
  x00741406: 'Referenced Channel Number',
  x00741407: 'Start Cumulative Time Weight',
  x00741408: 'End Cumulative Time Weight',
  x00741409: 'Omitted Channel Sequence',
  x0074140A: 'Reason for Channel Omission',
  x0074140B: 'Reason for Channel Omission Description',
  x0074140C: 'Channel Delivery Order Index',
  x0074140D: 'Channel Delivery Continuation Sequence',
  x0074140E: 'Omitted Application Setup Sequence',
  x00760001: 'Implant Assembly Template Name',
  x00760003: 'Implant Assembly Template Issuer',
  x00760006: 'Implant Assembly Template Version',
  x00760008: 'Replaced Implant Assembly Template Sequence',
  x0076000A: 'Implant Assembly Template Type',
  x0076000C: 'Original Implant Assembly Template Sequence',
  x0076000E: 'Derivation Implant Assembly Template Sequence',
  x00760010: 'Implant Assembly Template Target Anatomy Sequence',
  x00760020: 'Procedure Type Code Sequence',
  x00760030: 'Surgical Technique',
  x00760032: 'Component Types Sequence',
  x00760034: 'Component Type Code Sequence',
  x00760036: 'Exclusive Component Type',
  x00760038: 'Mandatory Component Type',
  x00760040: 'Component Sequence',
  x00760055: 'Component ID',
  x00760060: 'Component Assembly Sequence',
  x00760070: 'Component 1 Referenced ID',
  x00760080: 'Component 1 Referenced Mating Feature Set ID',
  x00760090: 'Component 1 Referenced Mating Feature ID',
  x007600A0: 'Component 2 Referenced ID',
  x007600B0: 'Component 2 Referenced Mating Feature Set ID',
  x007600C0: 'Component 2 Referenced Mating Feature ID',
  x00780001: 'Implant Template Group Name',
  x00780010: 'Implant Template Group Description',
  x00780020: 'Implant Template Group Issuer',
  x00780024: 'Implant Template Group Version',
  x00780026: 'Replaced Implant Template Group Sequence',
  x00780028: 'Implant Template Group Target Anatomy Sequence',
  x0078002A: 'Implant Template Group Members Sequence',
  x0078002E: 'Implant Template Group Member ID',
  x00780050: '3D Implant Template Group Member Matching Point',
  x00780060: '3D Implant Template Group Member Matching Axes',
  x00780070: 'Implant Template Group Member Matching 2D Coordinates Sequence',
  x00780090: '2D Implant Template Group Member Matching Point',
  x007800A0: '2D Implant Template Group Member Matching Axes',
  x007800B0: 'Implant Template Group Variation Dimension Sequence',
  x007800B2: 'Implant Template Group Variation Dimension Name',
  x007800B4: 'Implant Template Group Variation Dimension Rank Sequence',
  x007800B6: 'Referenced Implant Template Group Member ID',
  x007800B8: 'Implant Template Group Variation Dimension Rank',
  x00800001: 'Surface Scan Acquisition Type Code Sequence',
  x00800002: 'Surface Scan Mode Code Sequence',
  x00800003: 'Registration Method Code Sequence',
  x00800004: 'Shot Duration Time',
  x00800005: 'Shot Offset Time',
  x00800006: 'Surface Point Presentation Value Data',
  x00800007: 'Surface Point Color CIELab Value Data',
  x00800008: 'UV Mapping Sequence',
  x00800009: 'Texture Label',
  x00800010: 'U Value Data',
  x00800011: 'V Value Data',
  x00800012: 'Referenced Texture Sequence',
  x00800013: 'Referenced Surface Data Sequence',
  x00820001: 'Assessment Summary',
  x00820003: 'Assessment Summary Description',
  x00820004: 'Assessed SOP Instance Sequence',
  x00820005: 'Referenced Comparison SOP Instance Sequence',
  x00820006: 'Number of Assessment Observations',
  x00820007: 'Assessment Observations Sequence',
  x00820008: 'Observation Significance',
  x0082000A: 'Observation Description',
  x0082000C: 'Structured Constraint Observation Sequence',
  x00820010: 'Assessed Attribute Value Sequence',
  x00820016: 'Assessment Set ID',
  x00820017: 'Assessment Requester Sequence',
  x00820018: 'Selector Attribute Name',
  x00820019: 'Selector Attribute Keyword',
  x00820021: 'Assessment Type Code Sequence',
  x00820022: 'Observation Basis Code Sequence',
  x00820023: 'Assessment Label',
  x00820032: 'Constraint Type',
  x00820033: 'Specification Selection Guidance',
  x00820034: 'Constraint Value Sequence',
  x00820035: 'Recommended Default Value Sequence',
  x00820036: 'Constraint Violation Significance',
  x00820037: 'Constraint Violation Condition',
  x00880130: 'Storage Media File-set ID',
  x00880140: 'Storage Media File-set UID',
  x00880200: 'Icon Image Sequence',
  x00880904: 'Topic Title',
  x00880906: 'Topic Subject',
  x00880910: 'Topic Author',
  x00880912: 'Topic Keywords',
  x01000410: 'SOP Instance Status',
  x01000420: 'SOP Authorization DateTime',
  x01000424: 'SOP Authorization Comment',
  x01000426: 'Authorization Equipment Certification Number',
  x04000005: 'MAC ID Number',
  x04000010: 'MAC Calculation Transfer Syntax UID',
  x04000015: 'MAC Algorithm',
  x04000020: 'Data Elements Signed',
  x04000100: 'Digital Signature UID',
  x04000105: 'Digital Signature DateTime',
  x04000110: 'Certificate Type',
  x04000115: 'Certificate of Signer',
  x04000120: 'Signature',
  x04000305: 'Certified Timestamp Type',
  x04000310: 'Certified Timestamp',
  x04000315: '',
  x04000401: 'Digital Signature Purpose Code Sequence',
  x04000402: 'Referenced Digital Signature Sequence',
  x04000403: 'Referenced SOP Instance MAC Sequence',
  x04000404: 'MAC',
  x04000500: 'Encrypted Attributes Sequence',
  x04000510: 'Encrypted Content Transfer Syntax UID',
  x04000520: 'Encrypted Content',
  x04000550: 'Modified Attributes Sequence',
  x04000561: 'Original Attributes Sequence',
  x04000562: 'Attribute Modification DateTime',
  x04000563: 'Modifying System',
  x04000564: 'Source of Previous Values',
  x04000565: 'Reason for the Attribute Modification',
  x1000xxx0: 'Escape Triplet',
  x1000xxx1: 'Run Length Triplet',
  x1000xxx2: 'Huffman Table Size',
  x1000xxx3: 'Huffman Table Triplet',
  x1000xxx4: 'Shift Table Size',
  x1000xxx5: 'Shift Table Triplet',
  x1010xxxx: 'Zonal Map',
  x20000010: 'Number of Copies',
  x2000001E: 'Printer Configuration Sequence',
  x20000020: 'Print Priority',
  x20000030: 'Medium Type',
  x20000040: 'Film Destination',
  x20000050: 'Film Session Label',
  x20000060: 'Memory Allocation',
  x20000061: 'Maximum Memory Allocation',
  x20000062: 'Color Image Printing Flag',
  x20000063: 'Collation Flag',
  x20000065: 'Annotation Flag',
  x20000067: 'Image Overlay Flag',
  x20000069: 'Presentation LUT Flag',
  x2000006A: 'Image Box Presentation LUT Flag',
  x200000A0: 'Memory Bit Depth',
  x200000A1: 'Printing Bit Depth',
  x200000A2: 'Media Installed Sequence',
  x200000A4: 'Other Media Available Sequence',
  x200000A8: 'Supported Image Display Formats Sequence',
  x20000500: 'Referenced Film Box Sequence',
  x20000510: 'Referenced Stored Print Sequence',
  x20100010: 'Image Display Format',
  x20100030: 'Annotation Display Format ID',
  x20100040: 'Film Orientation',
  x20100050: 'Film Size ID',
  x20100052: 'Printer Resolution ID',
  x20100054: 'Default Printer Resolution ID',
  x20100060: 'Magnification Type',
  x20100080: 'Smoothing Type',
  x201000A6: 'Default Magnification Type',
  x201000A7: 'Other Magnification Types Available',
  x201000A8: 'Default Smoothing Type',
  x201000A9: 'Other Smoothing Types Available',
  x20100100: 'Border Density',
  x20100110: 'Empty Image Density',
  x20100120: 'Min Density',
  x20100130: 'Max Density',
  x20100140: 'Trim',
  x20100150: 'Configuration Information',
  x20100152: 'Configuration Information Description',
  x20100154: 'Maximum Collated Films',
  x2010015E: 'Illumination',
  x20100160: 'Reflected Ambient Light',
  x20100376: 'Printer Pixel Spacing',
  x20100500: 'Referenced Film Session Sequence',
  x20100510: 'Referenced Image Box Sequence',
  x20100520: 'Referenced Basic Annotation Box Sequence',
  x20200010: 'Image Box Position',
  x20200020: 'Polarity',
  x20200030: 'Requested Image Size',
  x20200040: 'Requested Decimate/Crop Behavior',
  x20200050: 'Requested Resolution ID',
  x202000A0: 'Requested Image Size Flag',
  x202000A2: 'Decimate/Crop Result',
  x20200110: 'Basic Grayscale Image Sequence',
  x20200111: 'Basic Color Image Sequence',
  x20200130: 'Referenced Image Overlay Box Sequence',
  x20200140: 'Referenced VOI LUT Box Sequence',
  x20300010: 'Annotation Position',
  x20300020: 'Text String',
  x20400010: 'Referenced Overlay Plane Sequence',
  x20400011: 'Referenced Overlay Plane Groups',
  x20400020: 'Overlay Pixel Data Sequence',
  x20400060: 'Overlay Magnification Type',
  x20400070: 'Overlay Smoothing Type',
  x20400072: 'Overlay or Image Magnification',
  x20400074: 'Magnify to Number of Columns',
  x20400080: 'Overlay Foreground Density',
  x20400082: 'Overlay Background Density',
  x20400090: 'Overlay Mode',
  x20400100: 'Threshold Density',
  x20400500: 'Referenced Image Box Sequence (Retired)',
  x20500010: 'Presentation LUT Sequence',
  x20500020: 'Presentation LUT Shape',
  x20500500: 'Referenced Presentation LUT Sequence',
  x21000010: 'Print Job ID',
  x21000020: 'Execution Status',
  x21000030: 'Execution Status Info',
  x21000040: 'Creation Date',
  x21000050: 'Creation Time',
  x21000070: 'Originator',
  x21000140: 'Destination AE',
  x21000160: 'Owner ID',
  x21000170: 'Number of Films',
  x21000500: 'Referenced Print Job Sequence (Pull Stored Print)',
  x21100010: 'Printer Status',
  x21100020: 'Printer Status Info',
  x21100030: 'Printer Name',
  x21100099: 'Print Queue ID',
  x21200010: 'Queue Status',
  x21200050: 'Print Job Description Sequence',
  x21200070: 'Referenced Print Job Sequence',
  x21300010: 'Print Management Capabilities Sequence',
  x21300015: 'Printer Characteristics Sequence',
  x21300030: 'Film Box Content Sequence',
  x21300040: 'Image Box Content Sequence',
  x21300050: 'Annotation Content Sequence',
  x21300060: 'Image Overlay Box Content Sequence',
  x21300080: 'Presentation LUT Content Sequence',
  x213000A0: 'Proposed Study Sequence',
  x213000C0: 'Original Image Sequence',
  x22000001: 'Label Using Information Extracted From Instances',
  x22000002: 'Label Text',
  x22000003: 'Label Style Selection',
  x22000004: 'Media Disposition',
  x22000005: 'Barcode Value',
  x22000006: 'Barcode Symbology',
  x22000007: 'Allow Media Splitting',
  x22000008: 'Include Non-DICOM Objects',
  x22000009: 'Include Display Application',
  x2200000A: 'Preserve Composite Instances After Media Creation',
  x2200000B: 'Total Number of Pieces of Media Created',
  x2200000C: 'Requested Media Application Profile',
  x2200000D: 'Referenced Storage Media Sequence',
  x2200000E: 'Failure Attributes',
  x2200000F: 'Allow Lossy Compression',
  x22000020: 'Request Priority',
  x30020002: 'RT Image Label',
  x30020003: 'RT Image Name',
  x30020004: 'RT Image Description',
  x3002000A: 'Reported Values Origin',
  x3002000C: 'RT Image Plane',
  x3002000D: 'X-Ray Image Receptor Translation',
  x3002000E: 'X-Ray Image Receptor Angle',
  x30020010: 'RT Image Orientation',
  x30020011: 'Image Plane Pixel Spacing',
  x30020012: 'RT Image Position',
  x30020020: 'Radiation Machine Name',
  x30020022: 'Radiation Machine SAD',
  x30020024: 'Radiation Machine SSD',
  x30020026: 'RT Image SID',
  x30020028: 'Source to Reference Object Distance',
  x30020029: 'Fraction Number',
  x30020030: 'Exposure Sequence',
  x30020032: 'Meterset Exposure',
  x30020034: 'Diaphragm Position',
  x30020040: 'Fluence Map Sequence',
  x30020041: 'Fluence Data Source',
  x30020042: 'Fluence Data Scale',
  x30020050: 'Primary Fluence Mode Sequence',
  x30020051: 'Fluence Mode',
  x30020052: 'Fluence Mode ID',
  x30040001: 'DVH Type',
  x30040002: 'Dose Units',
  x30040004: 'Dose Type',
  x30040005: 'Spatial Transform of Dose',
  x30040006: 'Dose Comment',
  x30040008: 'Normalization Point',
  x3004000A: 'Dose Summation Type',
  x3004000C: 'Grid Frame Offset Vector',
  x3004000E: 'Dose Grid Scaling',
  x30040010: 'RT Dose ROI Sequence',
  x30040012: 'Dose Value',
  x30040014: 'Tissue Heterogeneity Correction',
  x30040040: 'DVH Normalization Point',
  x30040042: 'DVH Normalization Dose Value',
  x30040050: 'DVH Sequence',
  x30040052: 'DVH Dose Scaling',
  x30040054: 'DVH Volume Units',
  x30040056: 'DVH Number of Bins',
  x30040058: 'DVH Data',
  x30040060: 'DVH Referenced ROI Sequence',
  x30040062: 'DVH ROI Contribution Type',
  x30040070: 'DVH Minimum Dose',
  x30040072: 'DVH Maximum Dose',
  x30040074: 'DVH Mean Dose',
  x30060002: 'Structure Set Label',
  x30060004: 'Structure Set Name',
  x30060006: 'Structure Set Description',
  x30060008: 'Structure Set Date',
  x30060009: 'Structure Set Time',
  x30060010: 'Referenced Frame of Reference Sequence',
  x30060012: 'RT Referenced Study Sequence',
  x30060014: 'RT Referenced Series Sequence',
  x30060016: 'Contour Image Sequence',
  x30060018: 'Predecessor Structure Set Sequence',
  x30060020: 'Structure Set ROI Sequence',
  x30060022: 'ROI Number',
  x30060024: 'Referenced Frame of Reference UID',
  x30060026: 'ROI Name',
  x30060028: 'ROI Description',
  x3006002A: 'ROI Display Color',
  x3006002C: 'ROI Volume',
  x30060030: 'RT Related ROI Sequence',
  x30060033: 'RT ROI Relationship',
  x30060036: 'ROI Generation Algorithm',
  x30060038: 'ROI Generation Description',
  x30060039: 'ROI Contour Sequence',
  x30060040: 'Contour Sequence',
  x30060042: 'Contour Geometric Type',
  x30060044: 'Contour Slab Thickness',
  x30060045: 'Contour Offset Vector',
  x30060046: 'Number of Contour Points',
  x30060048: 'Contour Number',
  x30060049: 'Attached Contours',
  x30060050: 'Contour Data',
  x30060080: 'RT ROI Observations Sequence',
  x30060082: 'Observation Number',
  x30060084: 'Referenced ROI Number',
  x30060085: 'ROI Observation Label',
  x30060086: 'RT ROI Identification Code Sequence',
  x30060088: 'ROI Observation Description',
  x300600A0: 'Related RT ROI Observations Sequence',
  x300600A4: 'RT ROI Interpreted Type',
  x300600A6: 'ROI Interpreter',
  x300600B0: 'ROI Physical Properties Sequence',
  x300600B2: 'ROI Physical Property',
  x300600B4: 'ROI Physical Property Value',
  x300600B6: 'ROI Elemental Composition Sequence',
  x300600B7: 'ROI Elemental Composition Atomic Number',
  x300600B8: 'ROI Elemental Composition Atomic Mass Fraction',
  x300600B9: 'Additional RT ROI Identification Code Sequence',
  x300600C0: 'Frame of Reference Relationship Sequence',
  x300600C2: 'Related Frame of Reference UID',
  x300600C4: 'Frame of Reference Transformation Type',
  x300600C6: 'Frame of Reference Transformation Matrix',
  x300600C8: 'Frame of Reference Transformation Comment',
  x30080010: 'Measured Dose Reference Sequence',
  x30080012: 'Measured Dose Description',
  x30080014: 'Measured Dose Type',
  x30080016: 'Measured Dose Value',
  x30080020: 'Treatment Session Beam Sequence',
  x30080021: 'Treatment Session Ion Beam Sequence',
  x30080022: 'Current Fraction Number',
  x30080024: 'Treatment Control Point Date',
  x30080025: 'Treatment Control Point Time',
  x3008002A: 'Treatment Termination Status',
  x3008002B: 'Treatment Termination Code',
  x3008002C: 'Treatment Verification Status',
  x30080030: 'Referenced Treatment Record Sequence',
  x30080032: 'Specified Primary Meterset',
  x30080033: 'Specified Secondary Meterset',
  x30080036: 'Delivered Primary Meterset',
  x30080037: 'Delivered Secondary Meterset',
  x3008003A: 'Specified Treatment Time',
  x3008003B: 'Delivered Treatment Time',
  x30080040: 'Control Point Delivery Sequence',
  x30080041: 'Ion Control Point Delivery Sequence',
  x30080042: 'Specified Meterset',
  x30080044: 'Delivered Meterset',
  x30080045: 'Meterset Rate Set',
  x30080046: 'Meterset Rate Delivered',
  x30080047: 'Scan Spot Metersets Delivered',
  x30080048: 'Dose Rate Delivered',
  x30080050: 'Treatment Summary Calculated Dose Reference Sequence',
  x30080052: 'Cumulative Dose to Dose Reference',
  x30080054: 'First Treatment Date',
  x30080056: 'Most Recent Treatment Date',
  x3008005A: 'Number of Fractions Delivered',
  x30080060: 'Override Sequence',
  x30080061: 'Parameter Sequence Pointer',
  x30080062: 'Override Parameter Pointer',
  x30080063: 'Parameter Item Index',
  x30080064: 'Measured Dose Reference Number',
  x30080065: 'Parameter Pointer',
  x30080066: 'Override Reason',
  x30080068: 'Corrected Parameter Sequence',
  x3008006A: 'Correction Value',
  x30080070: 'Calculated Dose Reference Sequence',
  x30080072: 'Calculated Dose Reference Number',
  x30080074: 'Calculated Dose Reference Description',
  x30080076: 'Calculated Dose Reference Dose Value',
  x30080078: 'Start Meterset',
  x3008007A: 'End Meterset',
  x30080080: 'Referenced Measured Dose Reference Sequence',
  x30080082: 'Referenced Measured Dose Reference Number',
  x30080090: 'Referenced Calculated Dose Reference Sequence',
  x30080092: 'Referenced Calculated Dose Reference Number',
  x300800A0: 'Beam Limiting Device Leaf Pairs Sequence',
  x300800B0: 'Recorded Wedge Sequence',
  x300800C0: 'Recorded Compensator Sequence',
  x300800D0: 'Recorded Block Sequence',
  x300800E0: 'Treatment Summary Measured Dose Reference Sequence',
  x300800F0: 'Recorded Snout Sequence',
  x300800F2: 'Recorded Range Shifter Sequence',
  x300800F4: 'Recorded Lateral Spreading Device Sequence',
  x300800F6: 'Recorded Range Modulator Sequence',
  x30080100: 'Recorded Source Sequence',
  x30080105: 'Source Serial Number',
  x30080110: 'Treatment Session Application Setup Sequence',
  x30080116: 'Application Setup Check',
  x30080120: 'Recorded Brachy Accessory Device Sequence',
  x30080122: 'Referenced Brachy Accessory Device Number',
  x30080130: 'Recorded Channel Sequence',
  x30080132: 'Specified Channel Total Time',
  x30080134: 'Delivered Channel Total Time',
  x30080136: 'Specified Number of Pulses',
  x30080138: 'Delivered Number of Pulses',
  x3008013A: 'Specified Pulse Repetition Interval',
  x3008013C: 'Delivered Pulse Repetition Interval',
  x30080140: 'Recorded Source Applicator Sequence',
  x30080142: 'Referenced Source Applicator Number',
  x30080150: 'Recorded Channel Shield Sequence',
  x30080152: 'Referenced Channel Shield Number',
  x30080160: 'Brachy Control Point Delivered Sequence',
  x30080162: 'Safe Position Exit Date',
  x30080164: 'Safe Position Exit Time',
  x30080166: 'Safe Position Return Date',
  x30080168: 'Safe Position Return Time',
  x30080171: 'Pulse Specific Brachy Control Point Delivered Sequence',
  x30080172: 'Pulse Number',
  x30080173: 'Brachy Pulse Control Point Delivered Sequence',
  x30080200: 'Current Treatment Status',
  x30080202: 'Treatment Status Comment',
  x30080220: 'Fraction Group Summary Sequence',
  x30080223: 'Referenced Fraction Number',
  x30080224: 'Fraction Group Type',
  x30080230: 'Beam Stopper Position',
  x30080240: 'Fraction Status Summary Sequence',
  x30080250: 'Treatment Date',
  x30080251: 'Treatment Time',
  x300A0002: 'RT Plan Label',
  x300A0003: 'RT Plan Name',
  x300A0004: 'RT Plan Description',
  x300A0006: 'RT Plan Date',
  x300A0007: 'RT Plan Time',
  x300A0009: 'Treatment Protocols',
  x300A000A: 'Plan Intent',
  x300A000B: 'Treatment Sites',
  x300A000C: 'RT Plan Geometry',
  x300A000E: 'Prescription Description',
  x300A0010: 'Dose Reference Sequence',
  x300A0012: 'Dose Reference Number',
  x300A0013: 'Dose Reference UID',
  x300A0014: 'Dose Reference Structure Type',
  x300A0015: 'Nominal Beam Energy Unit',
  x300A0016: 'Dose Reference Description',
  x300A0018: 'Dose Reference Point Coordinates',
  x300A001A: 'Nominal Prior Dose',
  x300A0020: 'Dose Reference Type',
  x300A0021: 'Constraint Weight',
  x300A0022: 'Delivery Warning Dose',
  x300A0023: 'Delivery Maximum Dose',
  x300A0025: 'Target Minimum Dose',
  x300A0026: 'Target Prescription Dose',
  x300A0027: 'Target Maximum Dose',
  x300A0028: 'Target Underdose Volume Fraction',
  x300A002A: 'Organ at Risk Full-volume Dose',
  x300A002B: 'Organ at Risk Limit Dose',
  x300A002C: 'Organ at Risk Maximum Dose',
  x300A002D: 'Organ at Risk Overdose Volume Fraction',
  x300A0040: 'Tolerance Table Sequence',
  x300A0042: 'Tolerance Table Number',
  x300A0043: 'Tolerance Table Label',
  x300A0044: 'Gantry Angle Tolerance',
  x300A0046: 'Beam Limiting Device Angle Tolerance',
  x300A0048: 'Beam Limiting Device Tolerance Sequence',
  x300A004A: 'Beam Limiting Device Position Tolerance',
  x300A004B: 'Snout Position Tolerance',
  x300A004C: 'Patient Support Angle Tolerance',
  x300A004E: 'Table Top Eccentric Angle Tolerance',
  x300A004F: 'Table Top Pitch Angle Tolerance',
  x300A0050: 'Table Top Roll Angle Tolerance',
  x300A0051: 'Table Top Vertical Position Tolerance',
  x300A0052: 'Table Top Longitudinal Position Tolerance',
  x300A0053: 'Table Top Lateral Position Tolerance',
  x300A0055: 'RT Plan Relationship',
  x300A0070: 'Fraction Group Sequence',
  x300A0071: 'Fraction Group Number',
  x300A0072: 'Fraction Group Description',
  x300A0078: 'Number of Fractions Planned',
  x300A0079: 'Number of Fraction Pattern Digits Per Day',
  x300A007A: 'Repeat Fraction Cycle Length',
  x300A007B: 'Fraction Pattern',
  x300A0080: 'Number of Beams',
  x300A0082: 'Beam Dose Specification Point',
  x300A0084: 'Beam Dose',
  x300A0086: 'Beam Meterset',
  x300A0088: 'Beam Dose Point Depth',
  x300A0089: 'Beam Dose Point Equivalent Depth',
  x300A008A: 'Beam Dose Point SSD',
  x300A008B: 'Beam Dose Meaning',
  x300A008C: 'Beam Dose Verification Control Point Sequence',
  x300A008D: 'Average Beam Dose Point Depth',
  x300A008E: 'Average Beam Dose Point Equivalent Depth',
  x300A008F: 'Average Beam Dose Point SSD',
  x300A0090: 'Beam Dose Type',
  x300A0091: 'Alternate Beam Dose',
  x300A0092: 'Alternate Beam Dose Type',
  x300A00A0: 'Number of Brachy Application Setups',
  x300A00A2: 'Brachy Application Setup Dose Specification Point',
  x300A00A4: 'Brachy Application Setup Dose',
  x300A00B0: 'Beam Sequence',
  x300A00B2: 'Treatment Machine Name',
  x300A00B3: 'Primary Dosimeter Unit',
  x300A00B4: 'Source-Axis Distance',
  x300A00B6: 'Beam Limiting Device Sequence',
  x300A00B8: 'RT Beam Limiting Device Type',
  x300A00BA: 'Source to Beam Limiting Device Distance',
  x300A00BB: 'Isocenter to Beam Limiting Device Distance',
  x300A00BC: 'Number of Leaf/Jaw Pairs',
  x300A00BE: 'Leaf Position Boundaries',
  x300A00C0: 'Beam Number',
  x300A00C2: 'Beam Name',
  x300A00C3: 'Beam Description',
  x300A00C4: 'Beam Type',
  x300A00C5: 'Beam Delivery Duration Limit',
  x300A00C6: 'Radiation Type',
  x300A00C7: 'High-Dose Technique Type',
  x300A00C8: 'Reference Image Number',
  x300A00CA: 'Planned Verification Image Sequence',
  x300A00CC: 'Imaging Device-Specific Acquisition Parameters',
  x300A00CE: 'Treatment Delivery Type',
  x300A00D0: 'Number of Wedges',
  x300A00D1: 'Wedge Sequence',
  x300A00D2: 'Wedge Number',
  x300A00D3: 'Wedge Type',
  x300A00D4: 'Wedge ID',
  x300A00D5: 'Wedge Angle',
  x300A00D6: 'Wedge Factor',
  x300A00D7: 'Total Wedge Tray Water-Equivalent Thickness',
  x300A00D8: 'Wedge Orientation',
  x300A00D9: 'Isocenter to Wedge Tray Distance',
  x300A00DA: 'Source to Wedge Tray Distance',
  x300A00DB: 'Wedge Thin Edge Position',
  x300A00DC: 'Bolus ID',
  x300A00DD: 'Bolus Description',
  x300A00DE: 'Effective Wedge Angle',
  x300A00E0: 'Number of Compensators',
  x300A00E1: 'Material ID',
  x300A00E2: 'Total Compensator Tray Factor',
  x300A00E3: 'Compensator Sequence',
  x300A00E4: 'Compensator Number',
  x300A00E5: 'Compensator ID',
  x300A00E6: 'Source to Compensator Tray Distance',
  x300A00E7: 'Compensator Rows',
  x300A00E8: 'Compensator Columns',
  x300A00E9: 'Compensator Pixel Spacing',
  x300A00EA: 'Compensator Position',
  x300A00EB: 'Compensator Transmission Data',
  x300A00EC: 'Compensator Thickness Data',
  x300A00ED: 'Number of Boli',
  x300A00EE: 'Compensator Type',
  x300A00EF: 'Compensator Tray ID',
  x300A00F0: 'Number of Blocks',
  x300A00F2: 'Total Block Tray Factor',
  x300A00F3: 'Total Block Tray Water-Equivalent Thickness',
  x300A00F4: 'Block Sequence',
  x300A00F5: 'Block Tray ID',
  x300A00F6: 'Source to Block Tray Distance',
  x300A00F7: 'Isocenter to Block Tray Distance',
  x300A00F8: 'Block Type',
  x300A00F9: 'Accessory Code',
  x300A00FA: 'Block Divergence',
  x300A00FB: 'Block Mounting Position',
  x300A00FC: 'Block Number',
  x300A00FE: 'Block Name',
  x300A0100: 'Block Thickness',
  x300A0102: 'Block Transmission',
  x300A0104: 'Block Number of Points',
  x300A0106: 'Block Data',
  x300A0107: 'Applicator Sequence',
  x300A0108: 'Applicator ID',
  x300A0109: 'Applicator Type',
  x300A010A: 'Applicator Description',
  x300A010C: 'Cumulative Dose Reference Coefficient',
  x300A010E: 'Final Cumulative Meterset Weight',
  x300A0110: 'Number of Control Points',
  x300A0111: 'Control Point Sequence',
  x300A0112: 'Control Point Index',
  x300A0114: 'Nominal Beam Energy',
  x300A0115: 'Dose Rate Set',
  x300A0116: 'Wedge Position Sequence',
  x300A0118: 'Wedge Position',
  x300A011A: 'Beam Limiting Device Position Sequence',
  x300A011C: 'Leaf/Jaw Positions',
  x300A011E: 'Gantry Angle',
  x300A011F: 'Gantry Rotation Direction',
  x300A0120: 'Beam Limiting Device Angle',
  x300A0121: 'Beam Limiting Device Rotation Direction',
  x300A0122: 'Patient Support Angle',
  x300A0123: 'Patient Support Rotation Direction',
  x300A0124: 'Table Top Eccentric Axis Distance',
  x300A0125: 'Table Top Eccentric Angle',
  x300A0126: 'Table Top Eccentric Rotation Direction',
  x300A0128: 'Table Top Vertical Position',
  x300A0129: 'Table Top Longitudinal Position',
  x300A012A: 'Table Top Lateral Position',
  x300A012C: 'Isocenter Position',
  x300A012E: 'Surface Entry Point',
  x300A0130: 'Source to Surface Distance',
  x300A0131: 'Average Beam Dose Point Source to External Contour Distance',
  x300A0132: 'Source to External Contour Distance',
  x300A0133: 'External Contour Entry Point',
  x300A0134: 'Cumulative Meterset Weight',
  x300A0140: 'Table Top Pitch Angle',
  x300A0142: 'Table Top Pitch Rotation Direction',
  x300A0144: 'Table Top Roll Angle',
  x300A0146: 'Table Top Roll Rotation Direction',
  x300A0148: 'Head Fixation Angle',
  x300A014A: 'Gantry Pitch Angle',
  x300A014C: 'Gantry Pitch Rotation Direction',
  x300A014E: 'Gantry Pitch Angle Tolerance',
  x300A0150: 'Fixation Eye',
  x300A0151: 'Chair Head Frame Position',
  x300A0152: 'Head Fixation Angle Tolerance',
  x300A0153: 'Chair Head Frame Position Tolerance',
  x300A0154: 'Fixation Light Azimuthal Angle Tolerance',
  x300A0155: 'Fixation Light Polar Angle Tolerance',
  x300A0180: 'Patient Setup Sequence',
  x300A0182: 'Patient Setup Number',
  x300A0183: 'Patient Setup Label',
  x300A0184: 'Patient Additional Position',
  x300A0190: 'Fixation Device Sequence',
  x300A0192: 'Fixation Device Type',
  x300A0194: 'Fixation Device Label',
  x300A0196: 'Fixation Device Description',
  x300A0198: 'Fixation Device Position',
  x300A0199: 'Fixation Device Pitch Angle',
  x300A019A: 'Fixation Device Roll Angle',
  x300A01A0: 'Shielding Device Sequence',
  x300A01A2: 'Shielding Device Type',
  x300A01A4: 'Shielding Device Label',
  x300A01A6: 'Shielding Device Description',
  x300A01A8: 'Shielding Device Position',
  x300A01B0: 'Setup Technique',
  x300A01B2: 'Setup Technique Description',
  x300A01B4: 'Setup Device Sequence',
  x300A01B6: 'Setup Device Type',
  x300A01B8: 'Setup Device Label',
  x300A01BA: 'Setup Device Description',
  x300A01BC: 'Setup Device Parameter',
  x300A01D0: 'Setup Reference Description',
  x300A01D2: 'Table Top Vertical Setup Displacement',
  x300A01D4: 'Table Top Longitudinal Setup Displacement',
  x300A01D6: 'Table Top Lateral Setup Displacement',
  x300A0200: 'Brachy Treatment Technique',
  x300A0202: 'Brachy Treatment Type',
  x300A0206: 'Treatment Machine Sequence',
  x300A0210: 'Source Sequence',
  x300A0212: 'Source Number',
  x300A0214: 'Source Type',
  x300A0216: 'Source Manufacturer',
  x300A0218: 'Active Source Diameter',
  x300A021A: 'Active Source Length',
  x300A021B: 'Source Model ID',
  x300A021C: 'Source Description',
  x300A0222: 'Source Encapsulation Nominal Thickness',
  x300A0224: 'Source Encapsulation Nominal Transmission',
  x300A0226: 'Source Isotope Name',
  x300A0228: 'Source Isotope Half Life',
  x300A0229: 'Source Strength Units',
  x300A022A: 'Reference Air Kerma Rate',
  x300A022B: 'Source Strength',
  x300A022C: 'Source Strength Reference Date',
  x300A022E: 'Source Strength Reference Time',
  x300A0230: 'Application Setup Sequence',
  x300A0232: 'Application Setup Type',
  x300A0234: 'Application Setup Number',
  x300A0236: 'Application Setup Name',
  x300A0238: 'Application Setup Manufacturer',
  x300A0240: 'Template Number',
  x300A0242: 'Template Type',
  x300A0244: 'Template Name',
  x300A0250: 'Total Reference Air Kerma',
  x300A0260: 'Brachy Accessory Device Sequence',
  x300A0262: 'Brachy Accessory Device Number',
  x300A0263: 'Brachy Accessory Device ID',
  x300A0264: 'Brachy Accessory Device Type',
  x300A0266: 'Brachy Accessory Device Name',
  x300A026A: 'Brachy Accessory Device Nominal Thickness',
  x300A026C: 'Brachy Accessory Device Nominal Transmission',
  x300A0280: 'Channel Sequence',
  x300A0282: 'Channel Number',
  x300A0284: 'Channel Length',
  x300A0286: 'Channel Total Time',
  x300A0288: 'Source Movement Type',
  x300A028A: 'Number of Pulses',
  x300A028C: 'Pulse Repetition Interval',
  x300A0290: 'Source Applicator Number',
  x300A0291: 'Source Applicator ID',
  x300A0292: 'Source Applicator Type',
  x300A0294: 'Source Applicator Name',
  x300A0296: 'Source Applicator Length',
  x300A0298: 'Source Applicator Manufacturer',
  x300A029C: 'Source Applicator Wall Nominal Thickness',
  x300A029E: 'Source Applicator Wall Nominal Transmission',
  x300A02A0: 'Source Applicator Step Size',
  x300A02A2: 'Transfer Tube Number',
  x300A02A4: 'Transfer Tube Length',
  x300A02B0: 'Channel Shield Sequence',
  x300A02B2: 'Channel Shield Number',
  x300A02B3: 'Channel Shield ID',
  x300A02B4: 'Channel Shield Name',
  x300A02B8: 'Channel Shield Nominal Thickness',
  x300A02BA: 'Channel Shield Nominal Transmission',
  x300A02C8: 'Final Cumulative Time Weight',
  x300A02D0: 'Brachy Control Point Sequence',
  x300A02D2: 'Control Point Relative Position',
  x300A02D4: 'Control Point 3D Position',
  x300A02D6: 'Cumulative Time Weight',
  x300A02E0: 'Compensator Divergence',
  x300A02E1: 'Compensator Mounting Position',
  x300A02E2: 'Source to Compensator Distance',
  x300A02E3: 'Total Compensator Tray Water-Equivalent Thickness',
  x300A02E4: 'Isocenter to Compensator Tray Distance',
  x300A02E5: 'Compensator Column Offset',
  x300A02E6: 'Isocenter to Compensator Distances',
  x300A02E7: 'Compensator Relative Stopping Power Ratio',
  x300A02E8: 'Compensator Milling Tool Diameter',
  x300A02EA: 'Ion Range Compensator Sequence',
  x300A02EB: 'Compensator Description',
  x300A0302: 'Radiation Mass Number',
  x300A0304: 'Radiation Atomic Number',
  x300A0306: 'Radiation Charge State',
  x300A0308: 'Scan Mode',
  x300A0309: 'Modulated Scan Mode Type',
  x300A030A: 'Virtual Source-Axis Distances',
  x300A030C: 'Snout Sequence',
  x300A030D: 'Snout Position',
  x300A030F: 'Snout ID',
  x300A0312: 'Number of Range Shifters',
  x300A0314: 'Range Shifter Sequence',
  x300A0316: 'Range Shifter Number',
  x300A0318: 'Range Shifter ID',
  x300A0320: 'Range Shifter Type',
  x300A0322: 'Range Shifter Description',
  x300A0330: 'Number of Lateral Spreading Devices',
  x300A0332: 'Lateral Spreading Device Sequence',
  x300A0334: 'Lateral Spreading Device Number',
  x300A0336: 'Lateral Spreading Device ID',
  x300A0338: 'Lateral Spreading Device Type',
  x300A033A: 'Lateral Spreading Device Description',
  x300A033C: 'Lateral Spreading Device Water Equivalent Thickness',
  x300A0340: 'Number of Range Modulators',
  x300A0342: 'Range Modulator Sequence',
  x300A0344: 'Range Modulator Number',
  x300A0346: 'Range Modulator ID',
  x300A0348: 'Range Modulator Type',
  x300A034A: 'Range Modulator Description',
  x300A034C: 'Beam Current Modulation ID',
  x300A0350: 'Patient Support Type',
  x300A0352: 'Patient Support ID',
  x300A0354: 'Patient Support Accessory Code',
  x300A0355: 'Tray Accessory Code',
  x300A0356: 'Fixation Light Azimuthal Angle',
  x300A0358: 'Fixation Light Polar Angle',
  x300A035A: 'Meterset Rate',
  x300A0360: 'Range Shifter Settings Sequence',
  x300A0362: 'Range Shifter Setting',
  x300A0364: 'Isocenter to Range Shifter Distance',
  x300A0366: 'Range Shifter Water Equivalent Thickness',
  x300A0370: 'Lateral Spreading Device Settings Sequence',
  x300A0372: 'Lateral Spreading Device Setting',
  x300A0374: 'Isocenter to Lateral Spreading Device Distance',
  x300A0380: 'Range Modulator Settings Sequence',
  x300A0382: 'Range Modulator Gating Start Value',
  x300A0384: 'Range Modulator Gating Stop Value',
  x300A0386: 'Range Modulator Gating Start Water Equivalent Thickness',
  x300A0388: 'Range Modulator Gating Stop Water Equivalent Thickness',
  x300A038A: 'Isocenter to Range Modulator Distance',
  x300A0390: 'Scan Spot Tune ID',
  x300A0391: 'Scan Spot Prescribed Indices',
  x300A0392: 'Number of Scan Spot Positions',
  x300A0393: 'Scan Spot Reordered',
  x300A0394: 'Scan Spot Position Map',
  x300A0395: 'Scan Spot Reordering Allowed',
  x300A0396: 'Scan Spot Meterset Weights',
  x300A0398: 'Scanning Spot Size',
  x300A039A: 'Number of Paintings',
  x300A03A0: 'Ion Tolerance Table Sequence',
  x300A03A2: 'Ion Beam Sequence',
  x300A03A4: 'Ion Beam Limiting Device Sequence',
  x300A03A6: 'Ion Block Sequence',
  x300A03A8: 'Ion Control Point Sequence',
  x300A03AA: 'Ion Wedge Sequence',
  x300A03AC: 'Ion Wedge Position Sequence',
  x300A0401: 'Referenced Setup Image Sequence',
  x300A0402: 'Setup Image Comment',
  x300A0410: 'Motion Synchronization Sequence',
  x300A0412: 'Control Point Orientation',
  x300A0420: 'General Accessory Sequence',
  x300A0421: 'General Accessory ID',
  x300A0422: 'General Accessory Description',
  x300A0423: 'General Accessory Type',
  x300A0424: 'General Accessory Number',
  x300A0425: 'Source to General Accessory Distance',
  x300A0431: 'Applicator Geometry Sequence',
  x300A0432: 'Applicator Aperture Shape',
  x300A0433: 'Applicator Opening',
  x300A0434: 'Applicator Opening X',
  x300A0435: 'Applicator Opening Y',
  x300A0436: 'Source to Applicator Mounting Position Distance',
  x300A0440: 'Number of Block Slab Items',
  x300A0441: 'Block Slab Sequence',
  x300A0442: 'Block Slab Thickness',
  x300A0443: 'Block Slab Number',
  x300A0450: 'Device Motion Control Sequence',
  x300A0451: 'Device Motion Execution Mode',
  x300A0452: 'Device Motion Observation Mode',
  x300A0453: 'Device Motion Parameter Code Sequence',
  x300A0501: 'Distal Depth Fraction',
  x300A0502: 'Distal Depth',
  x300A0503: 'Nominal Range Modulation Fractions',
  x300A0504: 'Nominal Range Modulated Region Depths',
  x300A0505: 'Depth Dose Parameters Sequence',
  x300A0506: 'Delivered Depth Dose Parameters Sequence',
  x300A0507: 'Delivered Distal Depth Fraction',
  x300A0508: 'Delivered Distal Depth',
  x300A0509: 'Delivered Nominal Range Modulation Fractions',
  x300A0510: 'Delivered Nominal Range Modulated Region Depths',
  x300A0511: 'Delivered Reference Dose Definition',
  x300A0512: 'Reference Dose Definition',
  x300C0002: 'Referenced RT Plan Sequence',
  x300C0004: 'Referenced Beam Sequence',
  x300C0006: 'Referenced Beam Number',
  x300C0007: 'Referenced Reference Image Number',
  x300C0008: 'Start Cumulative Meterset Weight',
  x300C0009: 'End Cumulative Meterset Weight',
  x300C000A: 'Referenced Brachy Application Setup Sequence',
  x300C000C: 'Referenced Brachy Application Setup Number',
  x300C000E: 'Referenced Source Number',
  x300C0020: 'Referenced Fraction Group Sequence',
  x300C0022: 'Referenced Fraction Group Number',
  x300C0040: 'Referenced Verification Image Sequence',
  x300C0042: 'Referenced Reference Image Sequence',
  x300C0050: 'Referenced Dose Reference Sequence',
  x300C0051: 'Referenced Dose Reference Number',
  x300C0055: 'Brachy Referenced Dose Reference Sequence',
  x300C0060: 'Referenced Structure Set Sequence',
  x300C006A: 'Referenced Patient Setup Number',
  x300C0080: 'Referenced Dose Sequence',
  x300C00A0: 'Referenced Tolerance Table Number',
  x300C00B0: 'Referenced Bolus Sequence',
  x300C00C0: 'Referenced Wedge Number',
  x300C00D0: 'Referenced Compensator Number',
  x300C00E0: 'Referenced Block Number',
  x300C00F0: 'Referenced Control Point Index',
  x300C00F2: 'Referenced Control Point Sequence',
  x300C00F4: 'Referenced Start Control Point Index',
  x300C00F6: 'Referenced Stop Control Point Index',
  x300C0100: 'Referenced Range Shifter Number',
  x300C0102: 'Referenced Lateral Spreading Device Number',
  x300C0104: 'Referenced Range Modulator Number',
  x300C0111: 'Omitted Beam Task Sequence',
  x300C0112: 'Reason for Omission',
  x300C0113: 'Reason for Omission Description',
  x300E0002: 'Approval Status',
  x300E0004: 'Review Date',
  x300E0005: 'Review Time',
  x300E0008: 'Reviewer Name',
  x40000010: 'Arbitrary',
  x40004000: 'Text Comments',
  x40080040: 'Results ID',
  x40080042: 'Results ID Issuer',
  x40080050: 'Referenced Interpretation Sequence',
  x400800FF: 'Report Production Status (Trial)',
  x40080100: 'Interpretation Recorded Date',
  x40080101: 'Interpretation Recorded Time',
  x40080102: 'Interpretation Recorder',
  x40080103: 'Reference to Recorded Sound',
  x40080108: 'Interpretation Transcription Date',
  x40080109: 'Interpretation Transcription Time',
  x4008010A: 'Interpretation Transcriber',
  x4008010B: 'Interpretation Text',
  x4008010C: 'Interpretation Author',
  x40080111: 'Interpretation Approver Sequence',
  x40080112: 'Interpretation Approval Date',
  x40080113: 'Interpretation Approval Time',
  x40080114: 'Physician Approving Interpretation',
  x40080115: 'Interpretation Diagnosis Description',
  x40080117: 'Interpretation Diagnosis Code Sequence',
  x40080118: 'Results Distribution List Sequence',
  x40080119: 'Distribution Name',
  x4008011A: 'Distribution Address',
  x40080200: 'Interpretation ID',
  x40080202: 'Interpretation ID Issuer',
  x40080210: 'Interpretation Type ID',
  x40080212: 'Interpretation Status ID',
  x40080300: 'Impressions',
  x40084000: 'Results Comments',
  x40100001: 'Low Energy Detectors',
  x40100002: 'High Energy Detectors',
  x40100004: 'Detector Geometry Sequence',
  x40101001: 'Threat ROI Voxel Sequence',
  x40101004: 'Threat ROI Base',
  x40101005: 'Threat ROI Extents',
  x40101006: 'Threat ROI Bitmap',
  x40101007: 'Route Segment ID',
  x40101008: 'Gantry Type',
  x40101009: 'OOI Owner Type',
  x4010100A: 'Route Segment Sequence',
  x40101010: 'Potential Threat Object ID',
  x40101011: 'Threat Sequence',
  x40101012: 'Threat Category',
  x40101013: 'Threat Category Description',
  x40101014: 'ATD Ability Assessment',
  x40101015: 'ATD Assessment Flag',
  x40101016: 'ATD Assessment Probability',
  x40101017: 'Mass',
  x40101018: 'Density',
  x40101019: 'Z Effective',
  x4010101A: 'Boarding Pass ID',
  x4010101B: 'Center of Mass',
  x4010101C: 'Center of PTO',
  x4010101D: 'Bounding Polygon',
  x4010101E: 'Route Segment Start Location ID',
  x4010101F: 'Route Segment End Location ID',
  x40101020: 'Route Segment Location ID Type',
  x40101021: 'Abort Reason',
  x40101023: 'Volume of PTO',
  x40101024: 'Abort Flag',
  x40101025: 'Route Segment Start Time',
  x40101026: 'Route Segment End Time',
  x40101027: 'TDR Type',
  x40101028: 'International Route Segment',
  x40101029: 'Threat Detection Algorithm and Version',
  x4010102A: 'Assigned Location',
  x4010102B: 'Alarm Decision Time',
  x40101031: 'Alarm Decision',
  x40101033: 'Number of Total Objects',
  x40101034: 'Number of Alarm Objects',
  x40101037: 'PTO Representation Sequence',
  x40101038: 'ATD Assessment Sequence',
  x40101039: 'TIP Type',
  x4010103A: 'Version',
  x40101041: 'OOI Owner Creation Time',
  x40101042: 'OOI Type',
  x40101043: 'OOI Size',
  x40101044: 'Acquisition Status',
  x40101045: 'Basis Materials Code Sequence',
  x40101046: 'Phantom Type',
  x40101047: 'OOI Owner Sequence',
  x40101048: 'Scan Type',
  x40101051: 'Itinerary ID',
  x40101052: 'Itinerary ID Type',
  x40101053: 'Itinerary ID Assigning Authority',
  x40101054: 'Route ID',
  x40101055: 'Route ID Assigning Authority',
  x40101056: 'Inbound Arrival Type',
  x40101058: 'Carrier ID',
  x40101059: 'Carrier ID Assigning Authority',
  x40101060: 'Source Orientation',
  x40101061: 'Source Position',
  x40101062: 'Belt Height',
  x40101064: 'Algorithm Routing Code Sequence',
  x40101067: 'Transport Classification',
  x40101068: 'OOI Type Descriptor',
  x40101069: 'Total Processing Time',
  x4010106C: 'Detector Calibration Data',
  x4010106D: 'Additional Screening Performed',
  x4010106E: 'Additional Inspection Selection Criteria',
  x4010106F: 'Additional Inspection Method Sequence',
  x40101070: 'AIT Device Type',
  x40101071: 'QR Measurements Sequence',
  x40101072: 'Target Material Sequence',
  x40101073: 'SNR Threshold',
  x40101075: 'Image Scale Representation',
  x40101076: 'Referenced PTO Sequence',
  x40101077: 'Referenced TDR Instance Sequence',
  x40101078: 'PTO Location Description',
  x40101079: 'Anomaly Locator Indicator Sequence',
  x4010107A: 'Anomaly Locator Indicator',
  x4010107B: 'PTO Region Sequence',
  x4010107C: 'Inspection Selection Criteria',
  x4010107D: 'Secondary Inspection Method Sequence',
  x4010107E: 'PRCS to RCS Orientation',
  x4FFE0001: 'MAC Parameters Sequence',
  x50xx0005: 'Curve Dimensions',
  x50xx0010: 'Number of Points',
  x50xx0020: 'Type of Data',
  x50xx0022: 'Curve Description',
  x50xx0030: 'Axis Units',
  x50xx0040: 'Axis Labels',
  x50xx0103: 'Data Value Representation',
  x50xx0104: 'Minimum Coordinate Value',
  x50xx0105: 'Maximum Coordinate Value',
  x50xx0106: 'Curve Range',
  x50xx0110: 'Curve Data Descriptor',
  x50xx0112: 'Coordinate Start Value',
  x50xx0114: 'Coordinate Step Value',
  x50xx1001: 'Curve Activation Layer',
  x50xx2000: 'Audio Type',
  x50xx2002: 'Audio Sample Format',
  x50xx2004: 'Number of Channels',
  x50xx2006: 'Number of Samples',
  x50xx2008: 'Sample Rate',
  x50xx200A: 'Total Time',
  x50xx200C: 'Audio Sample Data',
  x50xx200E: 'Audio Comments',
  x50xx2500: 'Curve Label',
  x50xx2600: 'Curve Referenced Overlay Sequence',
  x50xx2610: 'Curve Referenced Overlay Group',
  x50xx3000: 'Curve Data',
  x52009229: 'Shared Functional Groups Sequence',
  x52009230: 'Per-frame Functional Groups Sequence',
  x54000100: 'Waveform Sequence',
  x54000110: 'Channel Minimum Value',
  x54000112: 'Channel Maximum Value',
  x54001004: 'Waveform Bits Allocated',
  x54001006: 'Waveform Sample Interpretation',
  x5400100A: 'Waveform Padding Value',
  x54001010: 'Waveform Data',
  x56000010: 'First Order Phase Correction Angle',
  x56000020: 'Spectroscopy Data',
  x60xx0010: 'Overlay Rows',
  x60xx0011: 'Overlay Columns',
  x60xx0012: 'Overlay Planes',
  x60xx0015: 'Number of Frames in Overlay',
  x60xx0022: 'Overlay Description',
  x60xx0040: 'Overlay Type',
  x60xx0045: 'Overlay Subtype',
  x60xx0050: 'Overlay Origin',
  x60xx0051: 'Image Frame Origin',
  x60xx0052: 'Overlay Plane Origin',
  x60xx0060: 'Overlay Compression Code',
  x60xx0061: 'Overlay Compression Originator',
  x60xx0062: 'Overlay Compression Label',
  x60xx0063: 'Overlay Compression Description',
  x60xx0066: 'Overlay Compression Step Pointers',
  x60xx0068: 'Overlay Repeat Interval',
  x60xx0069: 'Overlay Bits Grouped',
  x60xx0100: 'Overlay Bits Allocated',
  x60xx0102: 'Overlay Bit Position',
  x60xx0110: 'Overlay Format',
  x60xx0200: 'Overlay Location',
  x60xx0800: 'Overlay Code Label',
  x60xx0802: 'Overlay Number of Tables',
  x60xx0803: 'Overlay Code Table Location',
  x60xx0804: 'Overlay Bits For Code Word',
  x60xx1001: 'Overlay Activation Layer',
  x60xx1100: 'Overlay Descriptor - Gray',
  x60xx1101: 'Overlay Descriptor - Red',
  x60xx1102: 'Overlay Descriptor - Green',
  x60xx1103: 'Overlay Descriptor - Blue',
  x60xx1200: 'Overlays - Gray',
  x60xx1201: 'Overlays - Red',
  x60xx1202: 'Overlays - Green',
  x60xx1203: 'Overlays - Blue',
  x60xx1301: 'ROI Area',
  x60xx1302: 'ROI Mean',
  x60xx1303: 'ROI Standard Deviation',
  x60xx1500: 'Overlay Label',
  x60xx3000: 'Overlay Data',
  x60xx4000: 'Overlay Comments',
  x7FE00008: 'Float Pixel Data',
  x7FE00009: 'Double Float Pixel Data',
  x7FE00010: 'Pixel Data',
  x7FE00020: 'Coefficients SDVN',
  x7FE00030: 'Coefficients SDHN',
  x7FE00040: 'Coefficients SDDN',
  x7Fxx0010: 'Variable Pixel Data',
  x7Fxx0011: 'Variable Next Data Group',
  x7Fxx0020: 'Variable Coefficients SDVN',
  x7Fxx0030: 'Variable Coefficients SDHN',
  x7Fxx0040: 'Variable Coefficients SDDN',
  xFFFAFFFA: 'Digital Signatures Sequence',
  xFFFCFFFC: 'Data Set Trailing Padding',
  xFFFEE000: 'Item',
  xFFFEE00D: 'Item Delimitation Item',
  xFFFEE0DD: 'Sequence Delimitation Item',
};
