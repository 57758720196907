export enum StudyStatus {
  RECEIVED = 0,
  SAVED = 1,
  UPLOADED_T0_S3 = 2,
  UPLOADED_T0_IPFS = 3,
  MINT_PENDING = 4,
  MINT_COMPLETED = 5,
  MINT_PENDING_PATIENT = 6,
  ERROR = 99,
}
