import React, { ReactElement } from 'react';
import styles from './StudiesCardsList.module.css';
import StudyCard from '../StudyCard/StudyCard';
import { StudyInterface } from '../../shared/models/study';
import config from '../../config';
import StudyCardLoading from '../StudyCard/StudyCardLoading';
import { useUserContext } from '../../shared/context/user.context';

const StudiesCardsList = ({
  studies,
  patientView,
  loadingGhost,
  inProviderPatients,
}: {
  studies?: StudyInterface[];
  patientView?: boolean;
  loadingGhost?: boolean;
  inProviderPatients?: boolean;
}): ReactElement => {
  const { user } = useUserContext();
  return (
    <div className={styles.root}>
      {loadingGhost &&
        [...Array(config.customization.studiesPerPage)].map((_, index) => (
          <StudyCardLoading patientView={patientView} key={index} />
        ))}
      {!loadingGhost &&
        Boolean(studies?.length) &&
        studies
          .filter((study) => typeof study.data !== 'string')
          .map((study) => (
            <StudyCard
              study={study}
              key={study.id}
              patientView={patientView ?? Number(user?.id) === study?.userId}
              inProviderPatients={inProviderPatients}
            />
          ))}
    </div>
  );
};

export default StudiesCardsList;
