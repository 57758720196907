import React, { ReactElement } from 'react';
import styles from './SectionDouble.module.css';

interface SectionDoubleProps {
  leftSide: ReactElement;
  rightSide: ReactElement;
}

const SectionDouble = ({ leftSide, rightSide }: SectionDoubleProps): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.inner}>{leftSide}</div>
      </div>
      <div className={styles.right}>
        <div className={styles.inner}>{rightSide}</div>
      </div>
    </div>
  );
};

export default SectionDouble;
