const detectDicomDateFormat = (dicomDob: string, formDob: Date | string): string => {
  if (!(formDob instanceof Date)) {
    formDob = new Date(formDob);
  }

  const year = formDob.getFullYear().toString();
  const month = (formDob.getMonth() + 1).toString().padStart(2, '0');
  const day = formDob.getDate().toString().padStart(2, '0');

  let formatDetected = dicomDob;

  if (formatDetected.includes(year)) formatDetected = formatDetected.replace(year, 'YYYY');
  if (formatDetected.includes(month)) formatDetected = formatDetected.replace(month, 'MM');
  if (formatDetected.includes(day)) formatDetected = formatDetected.replace(day, 'DD');

  if (/^[YMD]+$/.test(formatDetected)) {
    return formatDetected;
  } else {
    console.error(`Couldn't detect date format`);
    return '';
  }
};

export const convertDateStringFromFormat = (dateString: string, format: string): Date => {
  const yearIndex = format.indexOf('YYYY');
  const monthIndex = format.indexOf('MM');
  const dayIndex = format.indexOf('DD');

  const year = dateString.slice(yearIndex, yearIndex + 4);
  const month = dateString.slice(monthIndex, monthIndex + 2);
  const day = dateString.slice(dayIndex, dayIndex + 2);

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export default detectDicomDateFormat;
