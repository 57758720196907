import React from 'react';
import cx from 'classnames';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as FileError } from '../../assets/fileError.svg';
import { ReactComponent as Xicon } from '../../assets/xIcon.svg';
import styles from './FileItem.module.css';
import formatFileSize from '../../shared/utils/formatFileSize';
import { FormFile } from '../../shared/hooks/useRelease';
import ProgressBar from '../ProgressBar/ProgressBar';

const FileItem = ({
  formFile,
  onRemove,
  removeDisabled,
  errors,
}: {
  formFile: FormFile;
  onRemove: () => void;
  removeDisabled?: boolean;
  errors?: string[];
}) => {
  return (
    <div className={cx(styles.root, errors?.length && styles.errorFile)}>
      <div className={styles.inner}>
        <div className={styles.icon}>
          {formFile.loading && errors?.length === 0 && 'Loading'}
          {Boolean(errors?.length) && <FileError width={21.5} height='100%' />}
          {!errors?.length && <FileIcon width={21.5} height='100%' />}
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{formFile.file.name}</div>
          {!errors?.length && (
            <div className={styles.size}>{formatFileSize(formFile.file.size)}</div>
          )}
          {(Boolean(errors?.length) || formFile.error) && (
            <div className={styles.errors}>
              {errors.map((error, index) => (
                <p key={error + index} className={styles.error}>
                  {error}
                </p>
              ))}
              <p className={styles.error}>{formFile.error}</p>
            </div>
          )}
          {formFile.progress && <ProgressBar progress={formFile.progress} />}
        </div>
        <div className={styles.remove} onClick={onRemove}>
          {!removeDisabled && <Xicon width={13} height='100%' />}
        </div>
      </div>
    </div>
  );
};

export default FileItem;
