import React, { ReactElement, useCallback, useState } from 'react';
import {
  FileEarmarkRichtext,
  FileEarmarkPdf,
  FileEarmarkZip,
  FileEarmarkMedical,
  CloudDownload,
  Eye,
} from 'react-bootstrap-icons';
import cx from 'classnames';
import styles from './StudyFile.module.css';
import { StudyFileInterface } from '../../shared/models/file';
import Button from '../Button/Button';
import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import DicomViewerModal from '../DicomViewer/DicomViewerModal';

type StudyFileProps = {
  file?: StudyFileInterface;
  key?: string | number;
  loadingGhost?: boolean;
};

const StudyFile = ({ file, loadingGhost }: StudyFileProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleDownload = useCallback(() => {
    if (!file) return;
    setLoading(true);
    axiosInstance({
      method: 'GET',
      url: config.api.studyIdFileDownload.replace(':id', String(file.id)),
      responseType: 'blob',
      timeout: config.customization.axiosStudyUploadTimeout,
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setDownloadProgress(percentCompleted);
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => {
        setLoading(false);
        setDownloadProgress(0);
      });
  }, [file]);

  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex !== -1 ? filename.substring(lastDotIndex + 1).toLowerCase() : '';
  };

  const fileExtension = getFileExtension(
    loadingGhost && !file ? 'lorem ipsum dolor sit amet.dcm' : file?.filename,
  );

  const renderFileIcon = () => {
    switch (fileExtension) {
      case 'dcm':
        return <FileEarmarkMedical />;
      case 'pdf':
        return <FileEarmarkPdf />;
      case 'zip':
        return <FileEarmarkZip />;
      default:
        return <FileEarmarkRichtext />;
    }
  };

  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div className={styles.left}>
        <div className={styles.icon}>{renderFileIcon()}</div>
        <div className={styles.name}>
          {loadingGhost ? 'lorem ipsum dolor sit amet.dcm' : file.filename}
        </div>
      </div>
      <div className={styles.right}>
        <Button
          style='naked'
          icon={<CloudDownload />}
          loadingGhost={loadingGhost}
          onClick={handleDownload}
          loading={loading}
          percentageLoaded={downloadProgress}
        >
          Download
        </Button>
        {fileExtension === 'dcm' && (
          <Button
            style='naked'
            icon={<Eye />}
            loadingGhost={loadingGhost}
            modal={{
              title: file?.filename,
              contents: <DicomViewerModal file={file} />,
              bigModal: true,
            }}
          >
            View
          </Button>
        )}
      </div>
    </div>
  );
};

export default StudyFile;
