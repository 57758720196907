import React, { ReactElement } from 'react';
import cs from 'classnames';

import style from './StatusCell.module.css';

import { ReactComponent as ErrorSVG } from '../../assets/error.svg';
import { ReactComponent as ProcessingSVG } from '../../assets/processing.svg';
import { ReactComponent as MintedSVG } from '../../assets/minted.svg';
import { StudyStatus } from '../../shared/enum/study';

const StatusCell = (status: { cell: StudyStatus }): ReactElement => {
  const renderStatusIcon = () => {
    switch (status.cell as number) {
      case StudyStatus.RECEIVED:
      case StudyStatus.SAVED:
      case StudyStatus.UPLOADED_T0_IPFS:
      case StudyStatus.UPLOADED_T0_S3:
      case StudyStatus.MINT_PENDING:
      case StudyStatus.MINT_PENDING_PATIENT:
        return <ProcessingSVG />;
      case StudyStatus.MINT_COMPLETED:
        return <MintedSVG />;
      default:
        return <ErrorSVG />;
    }
  };

  const renderStatusValue = () => {
    switch (status.cell) {
      case StudyStatus.RECEIVED:
      case StudyStatus.SAVED:
      case StudyStatus.UPLOADED_T0_IPFS:
      case StudyStatus.UPLOADED_T0_S3:
      case StudyStatus.MINT_PENDING:
        return 'Processing';
      case StudyStatus.MINT_PENDING_PATIENT:
        return 'Pending Confirmation';
      case StudyStatus.MINT_COMPLETED:
        return 'Minted';
      default:
        return 'Error';
    }
  };

  return (
    <div
      className={cs([
        style.root,
        renderStatusValue() === 'Processing' && style.processing,
        renderStatusValue() === 'Pending Confirmation' && style.processing,
        renderStatusValue() === 'Error' && style.error,
        renderStatusValue() === 'Minted' && style.completed,
      ])}
    >
      <div className={style.icon}>{renderStatusIcon()}</div>
      <div className={style.label}>{renderStatusValue()}</div>
    </div>
  );
};

export default StatusCell;
