import React, { ReactElement } from 'react';
import styles from './PatientsList.module.css';
import { UserInterface } from '../../shared/models/userType';
import PatientListItem from './PatientListItem';
import config from '../../config';

const PatientsList = ({
  patients,
  loadingGhost,
}: {
  patients?: UserInterface[];
  loadingGhost?: boolean;
}): ReactElement => {
  return (
    <div className={styles.root}>
      {loadingGhost &&
        [...Array(config.customization.patientsPerPage / 2)].map((_, index) => (
          <PatientListItem loadingGhost key={index} />
        ))}
      {!loadingGhost &&
        Boolean(patients?.length) &&
        patients.map((patient) => <PatientListItem patient={patient} key={patient.id} />)}
    </div>
  );
};

export default PatientsList;
