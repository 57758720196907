import { useState } from 'react';
import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AuthError } from '../enum/authErrors';
import messages from '../staticText/messages';
import { authAccess } from '../services/auth.service';
import { AccesCodeLoginType, DecodedAccessToken } from '../models/authType';

interface ExternaSessionHook {
  loading: boolean;
  handleExternalSesssionForm: (values: AccesCodeLoginType) => Promise<void>;
  errorMessage: string | undefined;
  releaseSessionData: ReleaseSessionDataInterface | undefined;
  setReleaseSessionData: React.Dispatch<
    React.SetStateAction<ReleaseSessionDataInterface | undefined>
  >;
}

export interface ReleaseSessionDataInterface {
  token?: string;
  accessCode?: string;
  user?: {
    birthday?: string;
    firstName?: string;
    lastName?: string;
  };
}

const useExternalSession = (): ExternaSessionHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [releaseSessionData, setReleaseSessionData] = useState<ReleaseSessionDataInterface>();

  const handleExternalSesssionForm = async (values: AccesCodeLoginType) => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      console.log(values);
      const response = await authAccess(values);
      const token = response.data.accessToken;
      const decodedToken: DecodedAccessToken = jwtDecode(token);
      setReleaseSessionData({
        token: token,
        accessCode: values.code,
        user: {
          firstName: decodedToken.patientFirstName,
          lastName: decodedToken.patientLastName,
          birthday: values.birthday,
        },
      });
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === AuthError.INVALID_CREDENTIALS) {
          setErrorMessage(messages.invalidCodeOrBirthday);
        } else if (error.response.data.message === AuthError.EXPIRED_ACCESS_CODE) {
          setErrorMessage(messages.expiredAccessCode);
        } else {
          setErrorMessage(messages.errorOccured);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleExternalSesssionForm,
    errorMessage,
    releaseSessionData,
    setReleaseSessionData,
  };
};

export default useExternalSession;
