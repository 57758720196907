import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import cx from 'classnames';
import styles from './Filters.module.css';
import { FilterInterface } from '../../shared/models/filter';
import InputField from '../Form/InputField';
import FilterInput from '../FilterInput/FilterInput';

type FiltersProp = {
  setFiltersValue: (field: string, newVal: string) => void;
  filters: FilterInterface[];
  lateralContent?: ReactNode;
};

const Filters = ({ setFiltersValue, filters, lateralContent }: FiltersProp) => {
  return (
    <div className={cx(styles.root, lateralContent && styles.withLateralContent)}>
      {lateralContent && <div className={styles.lateralContent}>{lateralContent}</div>}
      <Formik initialValues={{}} onSubmit={() => {}}>
        <div className={styles.wrapper}>
          {filters.map((filter) => {
            if (filter.type === 'select') {
              return (
                <div key={filter.name}>
                  <InputField
                    name={filter.name}
                    type={filter.type}
                    options={filter.options}
                    classNames={styles.select}
                    onChange={setFiltersValue}
                  />
                </div>
              );
            }
            return (
              <div key={filter.name} className={styles.filterBox}>
                {filter.label && <div className={styles.filterTopPlaceholder}>{filter.label}</div>}
                <FilterInput
                  icon={filter.icon}
                  name={filter.name}
                  type={filter.type}
                  onChange={setFiltersValue}
                  classNames={styles.input}
                  placeholder={filter.placeholder}
                />
              </div>
            );
          })}
        </div>
      </Formik>
    </div>
  );
};

export default Filters;
