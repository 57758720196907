import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './Table.module.css';
import { TableColumnInterface } from '../../shared/models/table';

const Table = ({
  tableData,
  columns,
  tableHeaderRenderer,
  tableCellRenderer,
  fetchLoading,
}: {
  tableData: Record<string, string>[];
  columns: TableColumnInterface[];
  tableHeaderRenderer: (column: TableColumnInterface) => ReactElement;
  tableCellRenderer: (
    row: Record<string, string>,
    column: TableColumnInterface,
  ) => ReactElement | string;
  fetchLoading?: boolean;
}): ReactElement => {
  return (
    <table className={styles.tableContainer} cellSpacing={0} cellPadding={5}>
      {tableData && Boolean(tableData.length) && (
        <thead>
          <tr className={styles.headerRow}>
            {columns.map((column) => (
              <th
                key={column.key}
                className={styles.headerItem}
                style={{
                  ...(column.minWidth && { minWidth: `${column.minWidth}` }),
                  ...(column.width && { width: `${column.width}` }),
                }}
              >
                <div className={styles.headerCell}>{tableHeaderRenderer(column)}</div>
              </th>
            ))}
          </tr>
        </thead>
      )}
      {tableData && Boolean(tableData.length) && (
        <tbody>
          {tableData.map((row, index) => (
            <tr
              key={row.id ?? index}
              className={cx(styles.tableRow, fetchLoading && styles.loadingGhost)}
            >
              {columns.map((column) => (
                <td key={column.key} className={styles.tableItem}>
                  <div className={cx(styles.cell, fetchLoading && styles.cellGhost)}>
                    {!fetchLoading && tableCellRenderer(row, column)}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default Table;
