import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ReactComponent as Xicon } from '../../assets/xIcon.svg';
import styles from './Modal.module.css';
import Button from '../Button/Button';
import { ModalInterface } from '../../shared/models/modal';

type ModalProps = {
  toggleView: () => void;
  modal: ModalInterface;
};

const Modal = ({ toggleView, modal }: ModalProps): ReactElement => {
  const onClose = () => {
    modal.onCancel && modal.onCancel();
    toggleView();
  };

  const onSubmit = async () => {
    try {
      await modal.onSubmit();
      toggleView();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.overlay} onClick={toggleView} />
      <div className={cx(styles.box, modal.bigModal && styles.bigModal)}>
        <div className={styles.topPart}>
          <div className={styles.title}>{modal.title}</div>
          <div className={styles.close}>
            <button onClick={toggleView} className={styles.closeBtn}>
              <Xicon width={17} height='100%' />
            </button>
          </div>
        </div>
        <div className={styles.body}>
          {modal.contents}
          {(Boolean(modal.onCancel) || Boolean(modal.onSubmit)) && modal.submitText && (
            <div className={styles.buttons}>
              {Boolean(modal.onCancel) && (
                <Button type='button' style='naked' disabled={modal.loading} onClick={onClose}>
                  Cancel
                </Button>
              )}
              {Boolean(modal.onSubmit) && (
                <Button
                  onClick={onSubmit}
                  type='button'
                  style='navy'
                  loading={modal.loading}
                  disabled={modal.loading}
                >
                  {modal.submitText}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
