import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { StudyRequestAccessResponseInterface } from '../models/dicom';

export const getAccessInfoById = async (
  id: string,
): Promise<StudyRequestAccessResponseInterface> => {
  const response = await axiosInstance.get(config.api.accessStudyById.replace(':id', id));
  return response.data;
};
