import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './Tag.module.css';
import { ActivityType } from '../../shared/enum/activity';

interface TagProps {
  type: 'new' | ActivityType;
  small?: boolean;
  loadingGhost?: boolean;
  alternativeTagText?: string;
}

const Tag = ({ type, small, loadingGhost, alternativeTagText }: TagProps): ReactElement => {
  const tagText = (): string => {
    if (alternativeTagText) {
      return alternativeTagText;
    }
    if (loadingGhost) {
      return '- - - - - - - - - - -';
    }
    switch (type) {
      case 'new':
        return 'New';
      case ActivityType.RELEASE:
        return 'Release';
      case ActivityType.SHARE:
        return 'Share';
      case ActivityType.SHARED:
        return 'Share';
      case ActivityType.REVOKE:
        return 'Revoke access';
      case ActivityType.REVOKED:
        return 'Revoke access';
    }
  };

  return (
    <div
      className={cx(
        styles.root,
        type === 'new' && styles.new,
        type === ActivityType.RELEASE && styles.release,
        (type === ActivityType.SHARE || type === ActivityType.SHARED) && styles.share,
        (type === ActivityType.REVOKE || type === ActivityType.REVOKED) && styles.revoke,
        small && styles.small,
        loadingGhost && styles.loadingGhost,
      )}
    >
      {tagText()}
    </div>
  );
};

export default Tag;
