import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { InfoSquare, Calendar, PersonBoundingBox } from 'react-bootstrap-icons';

import styles from './releaseHistory.module.css';
import Filters from '../../components/Filters/Filters';
import useStudy from '../../shared/hooks/useStudy';
import Pagination from '../../components/Pagination/Pagination';
import config from '../../config';
import { FilterInterface } from '../../shared/models/filter';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import StudyDate from '../../components/Table/StudyDate';
import StatusCell from '../../components/Table/StatusCell';
import StudyAccess from '../../components/Table/StudyAccess';
import { StudyStatus } from '../../shared/enum/study';
import ExternalFlag from '../../components/ExternalFlag/ExternalFlag';
import { TableColumnInterface } from '../../shared/models/table';
import Table from '../../components/Table/Table';
import Section from '../../components/Section/Section';
import HistoryTabsButtons from '../../components/HistoryTabsButtons/HistoryTabsButtons';

const ReleaseHistoryPage = (): ReactElement => {
  const { fetchStudiesHistory, studiesHistory, page, onPageChange, fetchLoading } = useStudy();

  const [filterParams, setFilterParams] = useState<Record<string, string>>({});
  const [, setRefreshTimer] = useState(config.customization.autoRefreshTimerInSeconds);

  const handleFilterFieldChange = useCallback(
    (field: string, newVal?: string) => {
      setFilterParams((prevData) => {
        const newData = { ...prevData };
        if (!newVal) {
          delete newData[field];
        } else {
          newData[field] = newVal;
        }
        return newData;
      });
      onPageChange({ selected: 0 });
    },
    [onPageChange],
  );

  useEffect(() => {
    fetchStudiesHistory({ page, filters: filterParams });
  }, [fetchStudiesHistory, page, filterParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTimer((prevTimer) => {
        if (prevTimer === 1) {
          fetchStudiesHistory({ page, filters: filterParams, isAutoRefresh: true });
          return config.customization.autoRefreshTimerInSeconds;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [fetchStudiesHistory, page, filterParams]);

  const filters: FilterInterface[] = [
    {
      name: 'birthday',
      type: FieldTypes.DATE,
      label: 'Date of birth',
    },
    {
      name: 'uploadedDate',
      type: FieldTypes.DATE,
      label: 'Uploaded date',
    },
  ];

  const columns: TableColumnInterface[] = [
    {
      header: 'Study',
      key: 'studyNameAndDate',
    },
    {
      header: 'Patient',
      key: 'patient',
      minWidth: '200px',
    },
    {
      header: 'Date of birth',
      key: 'birthday',
    },
    {
      header: 'Uploaded date',
      key: 'uploadedDate',
    },
    {
      header: 'Status',
      key: 'status',
      width: '110px',
    },
    {
      header: '',
      key: 'action',
      width: '160px',
    },
  ];

  const tableData = React.useMemo(
    () => (fetchLoading ? Array(8).fill({}) : studiesHistory?.data),
    [fetchLoading, studiesHistory?.data],
  );

  const tableCellRenderer = (
    row: Record<string, string>,
    column: TableColumnInterface,
  ): ReactElement | string => {
    let isError: boolean = false;

    if (
      (row.status && Number(row.status) === StudyStatus.ERROR) ||
      !Object.values(StudyStatus).includes(row.status)
    ) {
      isError = true;
    }

    switch (column.key) {
      case 'uploadedDate':
        return <StudyDate cell={row[column.key]} />;

      case 'status':
        return <StatusCell cell={Number(row[column.key])} />;

      case 'action':
        return (
          Number(row.status) === StudyStatus.MINT_COMPLETED &&
          !isError && (
            <StudyAccess
              buttons={[
                {
                  label: 'View study',
                  url: config.routes.studyId.replace(':id', row[column.key] as string),
                },
              ]}
            />
          )
        );

      case 'studyNameAndDate':
        return (
          <>
            <div className={styles.studyName}>
              <span>{row.studyName}</span>
              <span>
                {row.externalProviderName && (
                  <ExternalFlag
                    providerName={row.externalProviderName}
                    requestedDate={row.externalRequestDate}
                  />
                )}
              </span>
            </div>
            <small>
              <Calendar className={styles.dateSvg} data-tooltip-id='studyDate' /> {row.studyDate}
            </small>
            {row.physician && (
              <small>
                <PersonBoundingBox
                  className={styles.physicianSvg}
                  data-tooltip-id='physicianName'
                />{' '}
                {row.physician}
              </small>
            )}
          </>
        );

      case 'patient':
        return (
          <>
            <div>{row.patient}</div>
            <small>{row.patientEmail}</small>
          </>
        );

      default:
        return row[column.key];
    }
  };

  const tableHeaderRenderer = (column: TableColumnInterface): ReactElement => {
    return (
      <>
        <span>{column.header}</span>
        <div>
          {column.key === 'status' && (
            <>
              <div data-tooltip-id='tooltipStatusInfo'>
                <InfoSquare />
              </div>
              <Tooltip id='tooltipStatusInfo'>
                <div className={styles.statusInfoTooltipInner}>
                  <h3>Legend:</h3>
                  <StatusCell cell={StudyStatus.MINT_PENDING} />
                  <div>Your study is being processed. Please wait.</div>
                  <StatusCell cell={StudyStatus.ERROR} />
                  <div>
                    Upload failed: There was an error processing your study. Please try again or
                    contact support.
                  </div>
                  <StatusCell cell={StudyStatus.MINT_COMPLETED} />
                  <div>Your study has been successfully uploaded and minted.</div>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Section flexSpacing>
      <Filters
        lateralContent={
          <>
            <HistoryTabsButtons />
            {/* <AutoRefreshTimerDisplay refreshingNow={autoRefreshLoading} timeLeft={refreshTimer} /> */}
          </>
        }
        setFiltersValue={handleFilterFieldChange}
        filters={filters}
      />
      <Table
        tableHeaderRenderer={tableHeaderRenderer}
        tableCellRenderer={tableCellRenderer}
        tableData={tableData}
        columns={columns}
        fetchLoading={fetchLoading}
      />
      {studiesHistory?.meta &&
        studiesHistory?.meta.totalItems / config.customization.historyStudiesPerPage > 1 && (
          <Pagination
            meta={studiesHistory.meta}
            page={page}
            onPageChange={onPageChange}
            perPage={config.customization.historyStudiesPerPage}
          />
        )}
      <Tooltip
        id='tooltipInfoEncriptedData'
        content='The data is Encripted. It will be shown here after mint process.'
      />
      <Tooltip id='studyDate' content='Study date' />
      <Tooltip id='physicianName' content='Physician name' />
    </Section>
  );
};

export default ReleaseHistoryPage;
