import React, { ReactElement } from 'react';
import { Buildings } from 'react-bootstrap-icons';

import styles from './ProviderHome.module.css';

import { useUserContext } from '../../shared/context/user.context';
import { getFirstLetter } from '../../shared/utils/getFirstLetter';
import Section from '../Section/Section';

const ProviderHome = (): ReactElement => {
  const { userData } = useUserContext();

  return (
    <>
      <Section>
        <div className={styles.header}>
          {userData?.data?.firstName && (
            <div className={styles.user}>
              <p>
                Welcome, {userData?.data?.firstName} {getFirstLetter(userData?.data?.lastName)}.
              </p>
              {userData?.data?.institutionName && (
                <div className={styles.insitution}>
                  <Buildings width={13} height={13} />
                  {userData?.data?.institutionName}
                </div>
              )}
            </div>
          )}
        </div>
        Home!
      </Section>
    </>
  );
};

export default ProviderHome;
