import React from 'react';
import { InfoSquareFill, ExclamationTriangleFill } from 'react-bootstrap-icons';
import styles from './DisclaimerBox.module.css';
import { DisclaimerBoxStyle } from '../../shared/enum/disclaimerBoxStyles';

type DisclaimerBoxProps = {
  type: DisclaimerBoxStyle;
  content: string;
};

const DisclaimerBox = ({ type, content }: DisclaimerBoxProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        {type === DisclaimerBoxStyle.INFO && <InfoSquareFill />}
        {type === DisclaimerBoxStyle.WARNING && <ExclamationTriangleFill />}
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default DisclaimerBox;
