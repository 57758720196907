import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './style.module.css';

import Section from '../Section/Section';
import Button from '../Button/Button';
import config from '../../config';
import { ReactComponent as ConfirmProcessingSvg } from '../../assets/check_circle.svg';
import { ReactComponent as ErrorProcessingSvg } from '../../assets/error_circle.svg';
import { ReactComponent as ReviewProcessingSvg } from '../../assets/review_account.svg';
import useRegister from '../../shared/hooks/useRegister';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import StatusScreen from './StatusScreen';
import { UserRoles } from '../../shared/enum/userRoles';
import { useUserContext } from '../../shared/context/user.context';
import ConfirmText from '../ConfirmText/ConfirmText';

const ConfirmScreen = (): ReactElement => {
  const {
    handleConfirmRegister,
    loading,
    errorMessage,
    handleResend,
    responseStatus,
    resendEmailSent,
    loadingResendEmailSent,
  } = useRegister();
  const { logOut } = useUserContext();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const hash = queryParameters.get('hash');
  const role = parseInt(queryParameters.get('role'), 10);
  const admin = queryParameters.get('admin');
  const [confirmationDone, setConfirmationDone] = useState(false);
  const delay = 10000;
  const [countdown, setCountdown] = useState(delay / 1000);

  useEffect(() => {
    const confirmRegistration = async () => {
      if (hash) {
        setConfirmationDone(false);
        await handleConfirmRegister({ codeHash: hash }, role, admin);
        setConfirmationDone(true);
      } else {
        console.error('Missing hash in the URL parameters');
        navigate(config.routes.signIn);
      }
    };

    confirmRegistration();
  }, [hash, handleConfirmRegister, role, admin, navigate]);

  useEffect(() => {
    if (confirmationDone && !errorMessage && role === UserRoles.PATIENT) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            navigate(config.routes.signIn);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [confirmationDone, errorMessage, navigate, role]);

  const SvgIconProvider = () =>
    admin === 'true' ? (
      <ConfirmProcessingSvg width={83.33} height={83.33} />
    ) : (
      <ReviewProcessingSvg width={116} height={109} />
    );

  const TitleProvider = () =>
    admin === 'false' ? (
      <>
        We’ll <b>review</b>
        <br /> your account
      </>
    ) : (
      <>
        The <b>Provider Account</b>
        <br />
        was <b>successfully</b> created.
      </>
    );

  const MessageBodyProvider = () =>
    admin === 'false' && (
      <p>
        An admin will review your account and validate your access. This usually takes 2 to 5
        business days.
      </p>
    );

  const TitleError = () => {
    if (responseStatus === 404 || responseStatus === 400) {
      return (
        <>
          Link <b>expired</b>
        </>
      );
    }
    return (
      <>
        Not <b>Authorized</b>
      </>
    );
  };

  const ErrorBodyMessage = () => {
    if (admin === 'true') {
      switch (responseStatus) {
        case 401:
          return (
            <>
              <p>
                Please Sign In with the Admin account and press the Provider Confirmation Link in
                the email again.
              </p>
              <ButtonRow buttonText='Sign In' />
            </>
          );
        case 403:
          return (
            <>
              <p>
                Please Sign Out from the current account and Sign In with the Admin account and
                press the Provider Confirmation Link in the email again.
              </p>
              <ButtonRow buttonText='Sign Out' />
            </>
          );
        default:
          return ResendLinkMessage();
      }
    }
    return ResendLinkMessage();
  };

  const ResendLinkMessage = () => (
    <>
      <p>This link is invalid or has expired. If this was an error, contact support.</p>
      {resendEmailSent ? (
        <ConfirmText text='Email resent successfully!' />
      ) : (
        <div className={styles.row}>
          Need a new link?{' '}
          <Button
            onClick={() => handleResend({ codeHash: hash })}
            type='button'
            style='inlineButton'
            loading={loadingResendEmailSent}
          >
            Resend
          </Button>
        </div>
      )}
    </>
  );

  const ButtonRow = ({ buttonText }: { buttonText: string }) => (
    <div className={styles.row}>
      <Button classNames={styles.width100} onClick={logOut} type='button' style='navy' size='large'>
        {buttonText}
      </Button>
    </div>
  );

  const ErrorScreen = () => (
    <StatusScreen
      backButton
      backAction={() => navigate(config.routes.signIn)}
      svgIcon={<ErrorProcessingSvg width={116} height={109} />}
      title={<TitleError />}
      bodyMessage={<ErrorBodyMessage />}
    />
  );

  if (errorMessage) {
    return <ErrorScreen />;
  }

  return (
    <Section classNames={styles.confirmScreenSection}>
      {loading ? (
        <div className={styles.loadingWrapper}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          {role === UserRoles.PATIENT && (
            <StatusScreen
              alignContentLeft
              svgIcon={<ConfirmProcessingSvg width={83.33} height={83.33} />}
              title={<>You’ve successfully activated your account</>}
              bodyMessage={
                <div>
                  <Button
                    style='navy'
                    size='large'
                    classNames={styles.confirmScreenSectionBtn}
                    onClick={() => navigate(config.routes.signIn)}
                  >
                    Continue to Login
                  </Button>
                  <div className={styles.countdown}>
                    <p>Redirecting to login in {countdown}</p>
                  </div>
                </div>
              }
            />
          )}
          {role === UserRoles.PROVIDER && (
            <StatusScreen
              backButton
              backAction={() => navigate(config.routes.signIn)}
              svgIcon={<SvgIconProvider />}
              title={<TitleProvider />}
              bodyMessage={<MessageBodyProvider />}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default ConfirmScreen;