import React, { ReactElement } from 'react';

import styles from './SignIn.module.css';

import useAuth from '../../shared/hooks/useAuth';
import EmailForm from './SignInForms/EmailForm';
import OtpForm from './SignInForms/OtpForm';
import BackButton from '../../components/Button/BackButton';

const SignIn = (): ReactElement => {
  const {
    handleSignInRequest,
    handleSignIn,
    errorMessage,
    setErrorMessage,
    loading,
    userRole,
    otpSent,
    email,
    validEmail,
    handleFormReset,
    requestLoginValues,
    handleResend,
    resendEmailSent,
    loadingResendEmailSent,
  } = useAuth();

  return (
    <div className={styles.signInWrapper}>
      {validEmail && <BackButton onClick={handleFormReset} classNames={styles.backButton} />}
      {!otpSent && (
        <EmailForm
          initialValues={{
            role: userRole || 0,
            email: email || '',
            birthday: '',
            ssn: '',
          }}
          handleSubmit={handleSignInRequest}
          validEmail={validEmail}
          handleFormReset={handleFormReset}
          errorMessage={errorMessage}
          resetError={setErrorMessage}
          loading={loading}
          resendEmailSent={resendEmailSent}
          handleResend={() => handleResend(email)}
          loadingResendEmailSent={loadingResendEmailSent}
        />
      )}
      {otpSent && (
        <OtpForm
          email={email}
          handleSubmit={handleSignIn}
          resendValues={requestLoginValues}
          handleResend={() => handleSignInRequest(requestLoginValues)}
          errorMessage={errorMessage}
          resetError={setErrorMessage}
          loading={loading}
          loadingResendEmailSent={loadingResendEmailSent}
        />
      )}
    </div>
  );
};

export default SignIn;
