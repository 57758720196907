import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AxiosError } from 'axios';

import {
  DecodedToken,
  LoginAccessCodeType,
  LoginRequestType,
  RegisterResponseType,
} from '../models/authType';
import { requestLogin, login, registerResend } from '../services/auth.service';
import { useUserContext } from '../context/user.context';
import { AuthError } from '../enum/authErrors';
import { UserRoles } from '../enum/userRoles';
import config from '../../config';
import messages from '../staticText/messages';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';

interface UserDicomHook {
  loading: boolean;
  handleSignIn: (values: LoginAccessCodeType) => Promise<void>;
  errorMessage: string | undefined;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  userRole: UserRoles;
  setUserRole: Dispatch<SetStateAction<UserRoles>>;
  otpSent: boolean;
  setOtpSent: Dispatch<SetStateAction<boolean>>;
  resendEmailSent: boolean;
  setResendEmailSent: Dispatch<SetStateAction<boolean>>;
  validEmail: boolean;
  setValidEmail: Dispatch<SetStateAction<boolean>>;
  handleSignInRequest: (values: LoginRequestType) => Promise<void>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  handleFormReset: () => void;
  requestLoginValues: LoginRequestType;
  setRequestLoginValues: Dispatch<SetStateAction<LoginRequestType>>;
  handleResend: (email?: string) => Promise<RegisterResponseType>;
  loadingResendEmailSent: boolean;
}

const useAuth = (): UserDicomHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [userRole, setUserRole] = useState<UserRoles>(null);
  const [otpSent, setOtpSent] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [resendEmailSent, setResendEmailSent] = useState(false);
  const [loadingResendEmailSent, setLoadingResendEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [requestLoginValues, setRequestLoginValues] = useState<LoginRequestType>({
    email: '',
    role: null,
    birthday: '',
    ssn: '',
  });

  const navigate = useNavigate();
  const { setUser, setIsLoggedIn, refreshUserData, setAccessTokenState } = useUserContext();
  const setSessionToken = (token: string) => {
    try {
      localStorage.setItem('jwtToken', token);
      setAccessTokenState(token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormReset = () => {
    setUserRole(null);
    setEmail('');
    setValidEmail(false);
    setOtpSent(false);
  };

  const handleSignInRequest = async (values: LoginRequestType) => {
    setRequestLoginValues(values);
    const updatedValues = {
      ...values,
      ...(values.birthday && { birthday: formatDateInheritLocale(values.birthday) }),
    };
    setRequestLoginValues(updatedValues);
    setResendEmailSent(false);
    setEmail(values.email);
    try {
      setErrorMessage(undefined);
      setLoading(true);

      const response = await requestLogin(updatedValues);
      setLoading(false);
      setUserRole(response.data.role);

      if (response.data?.otp) {
        setOtpSent(true);
      }
      setValidEmail(true);
    } catch (error) {
      if (!(error instanceof AxiosError) && !error?.response?.data?.message) {
        setErrorMessage('Unexpected error');
      } else if (
        error.response.data.message === AuthError.USER_NOT_FOUND ||
        error.response.data.message === AuthError.USER_NOT_ACTIVE
      ) {
        setErrorMessage(messages.userNotFoundSingIn);
      } else if (error.response.data.message === AuthError.EMAIL_NOT_CONFIRMED) {
        setErrorMessage(messages.emailIsNotConfirmed);
      } else if (error.response.data.message === AuthError.PROVIDER_NOT_CONFIRMED) {
        setErrorMessage(messages.providerNotConfirmed);
      } else if (error.response.data.message === AuthError.INVALID_CREDENTIALS) {
        setErrorMessage(messages.wrongCredentials);
      } else {
        setErrorMessage(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (values: LoginAccessCodeType) => {
    if (!email) return;
    const dataPackage = { accessCode: values.accessCode, email: email };

    try {
      setErrorMessage(undefined);
      setLoading(true);

      const response = await login(dataPackage);
      setIsLoggedIn(true);

      const token = response.data.accessToken;
      setSessionToken(token);

      const decodedToken: DecodedToken = jwtDecode(token);
      setUser({ id: decodedToken.id, roleId: decodedToken.roleId });
      await refreshUserData();

      navigate(config.routes.home);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === AuthError.OTP_NOT_FOUND) {
          setErrorMessage('The code is invalid');
          return;
        }
        if (error.response.data.message === AuthError.OTP_NOT_ACTIVE) {
          setErrorMessage('The code is expired');
          return;
        } else {
          setErrorMessage('Unexpected error');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async (email?: string): Promise<RegisterResponseType> => {
    try {
      setResendEmailSent(false);
      setLoadingResendEmailSent(true);
      const response = await registerResend({ email });
      setResendEmailSent(true);
      setErrorMessage(undefined);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        setErrorMessage(error.response.data?.message);
        setResendEmailSent(false);
        return;
      } else {
        setErrorMessage('An error occurred during register resend request.');
        setResendEmailSent(false);
      }
    } finally {
      setLoadingResendEmailSent(false);
    }
  };

  useEffect(() => {
    if (resendEmailSent) {
      const timer = setTimeout(() => {
        setResendEmailSent(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [resendEmailSent, setResendEmailSent]);

  return {
    loading,
    handleSignIn,
    errorMessage,
    setErrorMessage,
    userRole,
    setUserRole,
    otpSent,
    setOtpSent,
    handleSignInRequest,
    email,
    setEmail,
    validEmail,
    setValidEmail,
    handleFormReset,
    requestLoginValues,
    setRequestLoginValues,
    handleResend,
    resendEmailSent,
    setResendEmailSent,
    loadingResendEmailSent,
  };
};

export default useAuth;
