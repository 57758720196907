import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../components/Section/Section';
import StudyComponent from '../../components/StudyComponent/StudyComponent';
import NotFound from '../404/notFound';
import { useUserContext } from '../../shared/context/user.context';
import useStudy from '../../shared/hooks/useStudy';
import { StudyInterface } from '../../shared/models/study';

const StudyPage = (): ReactElement => {
  const { id } = useParams();
  const [study, setStudy] = useState<StudyInterface>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const { fetchStudyById, fetchLoading } = useStudy();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchDicom = async () => {
      setNotFound(false);
      if (id) {
        const studyData = await fetchStudyById(id);
        if (studyData) setStudy(studyData);
        if (!studyData) setNotFound(true);
      }
    };
    fetchDicom();
  }, [id, fetchStudyById]);

  const patientView = useMemo((): boolean => {
    if (user?.id && Number(user?.id) === study?.userId) return true;
    return false;
  }, [study?.userId, user?.id]);

  return (
    <>
      <Section>
        {notFound && <NotFound />}
        {!notFound && (
          <StudyComponent loadingGhost={fetchLoading} patientView={patientView} study={study} />
        )}
      </Section>
    </>
  );
};

export default StudyPage;
