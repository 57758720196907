import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './LoadingAnimation.module.css';

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

const LoadingAnimation = ({
  size,
  spacingVertically,
  alignCenter,
  white,
}: {
  size: number;
  spacingVertically?: number;
  alignCenter?: boolean;
  white?: boolean;
}): ReactElement => {
  const inlineRootStyles = {
    ...(spacingVertically && {
      marginTop: `${spacingVertically}px`,
      marginBottom: `${spacingVertically}px`,
    }),
    ...(alignCenter && { textAlign: 'center' as TextAlign, width: '100%' }),
  };

  const inlineInnerStyles = {
    width: `${size}px`,
    height: `${size}px`,
    ...(alignCenter && { marginLeft: 'auto', marginRight: 'auto' }),
  };

  return (
    <div className={styles.root} style={inlineRootStyles}>
      <div className={cx(styles.inner, white && styles.white)} style={inlineInnerStyles} />
    </div>
  );
};

export default LoadingAnimation;
