import React, { ReactElement } from 'react';
import { useUserContext } from '../shared/context/user.context';
import { UserRoles } from '../shared/enum/userRoles';
import MyMedicalRecords from '../components/MyMedicalRecords/MyMedicalRecords';
import ProviderHome from '../components/ProviderHome/ProviderHome';

const HomePage = (): ReactElement => {
  const { user } = useUserContext();

  if (user?.roleId === UserRoles.PATIENT) {
    return <MyMedicalRecords />;
  }

  return <ProviderHome />;
};

export default HomePage;
