function formatDateToMmDdYyyyy(dateStr: string) {
  const date = new Date(dateStr);

  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
  const utcDay = String(date.getUTCDate()).padStart(2, '0');
  const utcYear = date.getUTCFullYear();

  const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;
  return formattedDate;
}

export default formatDateToMmDdYyyyy;
