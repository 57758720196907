import axios from 'axios';
import {
  AccesCodeLoginType,
  ConfirmRegisterType,
  LoginRequestType,
  RegisterType,
  LoginAccessCodeType,
  RegisterResendType,
} from '../models/authType';

import config from '../../config';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';
import { axiosInstance } from '../../api/AxiosInstance';

interface LoginRequestReposneType {
  data: {
    role: number;
    otp?: string;
  };
}

interface LoginReposneType {
  data: {
    accessToken: string;
  };
}

export const requestLogin = ({
  email,
  birthday,
  ssn,
}: LoginRequestType): Promise<LoginRequestReposneType> => {
  return axios.post(`${config.api.baseUrl}${config.api.loginRequest}`, {
    email: email.toLowerCase(),
    birthday,
    ssn,
  });
};

export const login = ({ email, accessCode }: LoginAccessCodeType): Promise<LoginReposneType> => {
  return axios.post(`${config.api.baseUrl}${config.api.loginPath}`, {
    email: email.toLowerCase(),
    accessCode,
  });
};

export const authAccess = ({ code, birthday }: AccesCodeLoginType) => {
  return axios.post(`${config.api.baseUrl}${config.api.accessLoginPath}`, {
    code,
    birthday: formatDateInheritLocale(birthday),
  });
};

export const register = ({ role, data }: RegisterType) => {
  return axios.post(`${config.api.baseUrl}${config.api.register}`, {
    role,
    data,
  });
};

export const registerResend = ({ email, codeHash }: RegisterResendType) => {
  return axios.post(`${config.api.baseUrl}${config.api.registerResend}`, {
    email: email.toLowerCase(),
    codeHash,
  });
};

export const confirmRegister = ({ codeHash }: ConfirmRegisterType) => {
  return axios.post(`${config.api.baseUrl}${config.api.confirmRegister}`, {
    codeHash,
  });
};

export const confirmRegisterProvider = ({ codeHash }: ConfirmRegisterType) => {
  return axiosInstance.post(config.api.registerConfirmProvider, {
    codeHash,
  });
};
