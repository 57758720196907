export enum AuthError {
  WRONG_CREDENTIALS = 'Wrong credentials',
  INVALID_CREDENTIALS = 'Invalid credentials',
  EXPIRED_ACCESS_CODE = 'Expired access code',
  USER_NOT_FOUND = 'User not found',
  USER_NOT_ACTIVE = 'User is not active',
  USER_ALREADY_EXISTS = 'User already exists',
  OTP_NOT_FOUND = 'Otp not found',
  OTP_NOT_ACTIVE = 'Otp is not active',
  OTP_NOT_MATCH = 'Otp not match for user',
  OTP_EXPIRED = 'Otp expired',
  PROVIDER_NOT_CONFIRMED = 'Provider not confirmed',
  EMAIL_NOT_CONFIRMED = 'Email is not confirmed',
  ROLE_NOT_MATCH = 'Role not match',
  BIRTHDAY_NOT_MATCH_REGISTER = 'User data not match: birthday',
}
