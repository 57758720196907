import React, { ReactElement, useEffect } from 'react';
import StudiesCardsList from '../components/StudyCardsList/StudiesCardsList';
import Section from '../components/Section/Section';
import useStudy from '../shared/hooks/useStudy';
import Pagination from '../components/Pagination/Pagination';
import config from '../config';

const SharedWithMePage = (): ReactElement => {
  const { userStudies, fetchUserStudies, fetchLoading, page, onPageChange } = useStudy();

  useEffect(() => {
    fetchUserStudies({ sharedWithMe: true, page });
  }, [fetchUserStudies, page]);

  return (
    <Section>
      {fetchLoading && <StudiesCardsList loadingGhost />}
      {userStudies && !fetchLoading && <StudiesCardsList studies={userStudies.data} />}
      {!fetchLoading && userStudies?.data.length === 0 && 'No studies found'}
      {userStudies?.meta &&
        userStudies?.meta.totalItems / config.customization.studiesPerPage > 1 && (
          <Pagination
            meta={userStudies.meta}
            page={page}
            onPageChange={onPageChange}
            perPage={config.customization.studiesPerPage}
          />
        )}
    </Section>
  );
};

export default SharedWithMePage;
