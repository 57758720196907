import React, { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';
import cx from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import styles from './Pagination.module.css';
import { PaginationMeta } from '../../shared/models/paginatedResponse';

interface PaginationProps {
  meta: PaginationMeta;
  page: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  perPage: number;
}

const Pagination = ({ meta, page, onPageChange, perPage }: PaginationProps): ReactElement => {
  return (
    <div className={styles.root}>
      <ReactPaginate
        previousLabel={<ChevronLeft />}
        previousClassName={cx(styles.prev, page === 0 && styles.hide)}
        nextLabel={page > 0 ? <ChevronRight /> : 'Next page'}
        nextClassName={cx(
          styles.next,
          page === Math.floor(meta.totalItems / perPage) && styles.hide,
        )}
        breakLabel={'...'}
        pageCount={Math.ceil(meta.totalItems / perPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName={styles.pagination}
        activeClassName={styles.active}
        initialPage={page}
        pageLinkClassName={styles.page}
      />
    </div>
  );
};

export default Pagination;
