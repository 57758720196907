import React, { ReactElement } from 'react';
import AuthPageWrapper from '../login/AuthPageWrapper';
import ReleaseRecordPage from '../releaseRecord/releaseRecord';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../components/Form/InputField';
import useExternalSession from '../../shared/hooks/useExternal';
import styles from './external.module.css';
import Button from '../../components/Button/Button';
import messages from '../../shared/staticText/messages';

const ExternalRelease = (): ReactElement => {
  const {
    handleExternalSesssionForm,
    errorMessage,
    loading,
    releaseSessionData,
    setReleaseSessionData,
  } = useExternalSession();

  const validationSchema = Yup.object({
    code: Yup.string().min(6, messages.minLenght6).required(messages.requiredField),
    birthday: Yup.date().required(messages.requiredField).max(new Date(), messages.onlyPastDates),
  });

  const onCancel = () => {
    setReleaseSessionData(undefined);
  };

  if (releaseSessionData) {
    return (
      <PageWrapper noRows noValidation>
        <ReleaseRecordPage
          isExternal
          releaseSessionData={releaseSessionData}
          onCancelSession={onCancel}
        />
      </PageWrapper>
    );
  }

  return (
    <AuthPageWrapper>
      <div className={styles.externalWrapper}>
        <Formik
          initialValues={{
            code: '',
            birthday: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleExternalSesssionForm}
        >
          <Form className={styles.signInForm}>
            <h2 className={styles.formTitle}>
              Release medical records
              <br /> to <span>MediMint</span>
            </h2>
            <p>Please check your email for the access code.</p>
            <InputField
              label='Access Code'
              name='code'
              type='text'
              maxLength={6}
              isCodeField
              required
            />
            <InputField label='Patient’s date of birth' name='birthday' type='date' required />
            {errorMessage && <p className={styles.erorr}>{errorMessage}</p>}
            <Button style='navy' size='large' type='submit' loading={loading}>
              Continue
            </Button>
          </Form>
        </Formik>
      </div>
    </AuthPageWrapper>
  );
};

export default ExternalRelease;
