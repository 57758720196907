import React, { ReactElement, useEffect, useState } from 'react';
import ActivityLine from './ActivityLine';
import styles from './RecentActivities.module.css';
import useActivity from '../../shared/hooks/useActivity';
import Pagination from '../Pagination/Pagination';
import config from '../../config';

const RecentActivities = (): ReactElement => {
  const { fetchLoading, page, userActivities, fetchUserActivity, onPageChange } = useActivity();
  const [, setRefreshTimer] = useState(config.customization.autoRefreshTimerInSeconds);

  useEffect(() => {
    fetchUserActivity({ page });
  }, [page, fetchUserActivity]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTimer((prevTimer) => {
        if (prevTimer === 1) {
          fetchUserActivity({ page, isAutoRefresh: true });
          return config.customization.autoRefreshTimerInSeconds;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [fetchUserActivity, page]);

  return (
    <>
      <div className={styles.root}>
        {fetchLoading &&
          [...Array(userActivities?.data.length ?? config.customization.activitiesPerPage)].map(
            (_, index) => (
              <ActivityLine
                key={index}
                loadingGhost
                bottomHr={Boolean(index + 1 < userActivities?.data.length)}
              />
            ),
          )}
        {!fetchLoading &&
          userActivities?.data.map((activity, index) => (
            <ActivityLine
              activity={activity}
              key={activity.id}
              bottomHr={Boolean(index + 1 < userActivities?.data.length)}
            />
          ))}
        {!fetchLoading && !userActivities?.data.length && 'No activities found'}
      </div>
      {userActivities?.meta &&
        userActivities?.meta.totalItems / config.customization.activitiesPerPage > 1 && (
          <Pagination
            meta={userActivities.meta}
            page={page}
            onPageChange={onPageChange}
            perPage={config.customization.activitiesPerPage}
          />
        )}
    </>
  );
};

export default RecentActivities;
