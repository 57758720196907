import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';

import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import { ReactComponent as BrandName } from '../../assets/brandName.svg';
import { ReactComponent as InfoSVG } from '../../assets/info.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Mail } from '../../assets/mail.svg';
import { ReactComponent as Birthday } from '../../assets/birthday.svg';
import { ReactComponent as SvgPdfDownload } from '../../assets/svgPdfDownload.svg';

import styles from './accessPdf.module.css';

import { StudyRequestAccessResponseInterface } from '../../shared/models/dicom';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';
import { axiosInstance } from '../../api/AxiosInstance';
import Button from '../../components/Button/Button';
import Section from '../../components/Section/Section';
import config from '../../config';
import { formatDateForPdfName } from '../../shared/utils/formatDateForPdfName';

type AccessReponseType = {
  data: StudyRequestAccessResponseInterface;
};

function AccessPDF() {
  const [loadingFetchingPDFData, setLoadingFetchingPDFData] = useState(false);
  const [loadingGeneratingPDF, setLoadingGeneratingPDF] = useState(false);
  const [accessPDFData, setAccessPDFData] = useState<StudyRequestAccessResponseInterface>();
  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get('hash');
  const elementHTMLRef = useRef<HTMLDivElement>(null);
  const [refVisible, setRefVisible] = useState(false);
  const navigate = useNavigate();

  const handleDownload = useCallback(
    async (ref: React.MutableRefObject<HTMLDivElement>): Promise<void> => {
      try {
        const doc = new JsPDF('p', 'mm', 'a4');

        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const scale = 3;
        const canvas = await html2canvas(ref.current, {
          scale,
          useCORS: true,
        });

        const imgData = canvas.toDataURL('image/png');
        const dateInName = formatDateForPdfName(accessPDFData.createdAt);

        doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        doc.save(`Medimint_${dateInName}`);
      } catch (error) {
        console.log(error);
        toast.error('Unexpected error occurred while downloading the PDF.');
      }
    },
    [accessPDFData],
  );

  const handleFetchingPDFData = useCallback(async () => {
    if (!hash) {
      navigate(config.routes.notFound);
      return;
    }
    const backendAccessUrl = config.api.accessRequestHash.replace(':hash', hash);
    try {
      setLoadingFetchingPDFData(true);
      setLoadingGeneratingPDF(true);
      const accessReponse: AccessReponseType = await axiosInstance.get(backendAccessUrl);
      accessReponse.data.patientBirthday =
        accessReponse.data.patientBirthday &&
        `${accessReponse.data.patientBirthday.substring(5, 7)} / ${accessReponse.data.patientBirthday.substring(8, 10)} / ${accessReponse.data.patientBirthday.substring(0, 4)} `;
      setAccessPDFData(accessReponse.data);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.statusCode === 404) {
          navigate(config.routes.notFound);
          return;
        }
      }
      console.log('error', error);
      toast.error('Unexpected error occurred while downloading the PDF.');
      setLoadingGeneratingPDF(false);
      setLoadingFetchingPDFData(false);
    } finally {
      setLoadingGeneratingPDF(false);
      setLoadingFetchingPDFData(false);
    }
  }, [hash, navigate]);

  useEffect(() => {
    if (!refVisible) {
      return;
    }
    handleDownload(elementHTMLRef);
  }, [handleDownload, refVisible]);

  useEffect(() => {
    handleFetchingPDFData();
  }, [handleFetchingPDFData, hash]);

  return accessPDFData === undefined || loadingFetchingPDFData || loadingGeneratingPDF ? (
    <div className={styles.rootLoading}>
      <LoadingCircle size={45} />
    </div>
  ) : (
    <div>
      <Section>
        <div className={styles.pdfDownload}>
          <SvgPdfDownload width={120} height={120} className={styles.svgDownload} />
          <div className={styles.title}>PDF is being downloaded</div>
          <div className={styles.description}>
            The PDF should start to download in a few seconds. If not, press the button below:
          </div>
          <div className={styles.buttonDownload}>
            <Button style='naked' onClick={() => handleDownload(elementHTMLRef)}>
              Download
            </Button>
          </div>
        </div>
      </Section>
      <div className={styles.root}>
        <div
          className={styles.rootPdf}
          id='pdfContent'
          ref={(el) => {
            elementHTMLRef.current = el;
            setRefVisible(!!el);
          }}
        >
          <div className={styles.header}>
            <div className={styles.headerImg} />
            <div className={styles.inner}>
              <div className={styles.logo}>
                <BrandMark width={45} height='100%' />
              </div>
              <div className={styles.logoDetails}>
                <BrandName width={107.38} height='100%' />
                <p>Easy medical image sharing</p>
              </div>
            </div>
          </div>
          <div className={styles.greyBackground} />
          <div className={styles.details}>
            <div className={styles.description}>
              <h1>Medical records request</h1>
              <p>
                <b>
                  {accessPDFData?.fromFirstName} {accessPDFData?.fromLastName}
                </b>{' '}
                has requested the release of medical images of{' '}
                <b>
                  {accessPDFData?.patientFirstName} {accessPDFData?.patientLastName}
                </b>
                . Please follow the steps to share the medical records.
              </p>
            </div>
            <div className={styles.layout}>
              <div className={styles.content}>
                <div className={styles.list}>
                  <div className={styles.listItem}>
                    <div className={styles.listNumber}>1</div>
                    <div className={styles.listDescription}>
                      <p>
                        Open the release form page <br /> at {config.support.externalRelease}
                      </p>
                      <a
                        href={config.support.externalRelease}
                        target='_blank'
                        className={styles.link}
                        rel='noreferrer'
                      >
                        Open Page
                      </a>
                    </div>
                  </div>
                  <div className={styles.listItem}>
                    <div className={styles.listNumber}>2</div>
                    <div className={styles.listDescription}>
                      <p>
                        Authorize the process by using <br /> the following access code:
                      </p>
                      <p className={styles.code}>{accessPDFData?.accessCode}</p>
                      <div className={styles.info}>
                        <InfoSVG width={16} height={16} />
                        <p>
                          The access code will expire on <br />{' '}
                          {new Date(accessPDFData?.accessCodeExpire).toUTCString()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.listItem}>
                    <div className={styles.listNumber}>3</div>
                    <div className={styles.listDescription}>
                      <p>
                        Upload files and release the <br /> study in the medimint network.
                      </p>
                    </div>
                  </div>
                </div>

                <p className={styles.signature}>
                  Thank you, <br /> {accessPDFData.providerFirstName}{' '}
                  {accessPDFData?.providerLastName}
                </p>
              </div>
              <div className={styles.patientDetails}>
                <h4>Patient details</h4>
                <div className={styles.patientDetailsItem}>
                  <User width={16} height={16} />
                  <p>
                    {accessPDFData?.patientFirstName} {accessPDFData?.patientLastName}
                  </p>
                </div>
                <div className={styles.patientDetailsItem}>
                  <Mail width={16} height={16} />
                  <p>{accessPDFData?.patientEmail}</p>
                </div>
                <div className={styles.patientDetailsItem}>
                  <Birthday width={16} height={16} />
                  <p>
                    {accessPDFData?.patientBirthday} <br />
                    <small className={styles.dateFormat}>MM / DD / YYYY</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.brandMark}>
            <QRCode
              value={config.support.externalRelease}
              fgColor='#303646'
              bgColor='#f6f6f9'
              size={220}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessPDF;
