import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import cx from 'classnames';
import Header from '../Header/Header';
import styles from './PageWrapper.module.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../shared/context/user.context';
import config from '../../config';
import { UserRoles } from '../../shared/enum/userRoles';
import NotFound from '../../pages/404/notFound';

const PageWrapper = ({
  children,
  noRows,
  roleAllowed,
  noValidation,
}: {
  children: ReactNode;
  noRows?: boolean;
  roleAllowed?: UserRoles;
  noValidation?: boolean;
}): ReactElement => {
  const { isLoggedIn, initLoading, user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && !initLoading && !noValidation) {
      navigate(config.routes.signIn);
    }
  }, [isLoggedIn, navigate, initLoading, noValidation]);

  const hasAccess = useMemo((): boolean => {
    if (noValidation || typeof roleAllowed === 'undefined') return true;
    if (roleAllowed === user?.roleId) return true;
    return false;
  }, [noValidation, roleAllowed, user?.roleId]);

  if (initLoading) return <></>;

  return (
    <div className={cx(styles.root, noRows && hasAccess && styles.noRows)}>
      <Header />
      <div className={styles.wrapper}>{hasAccess ? children : <NotFound />}</div>
    </div>
  );
};

export default PageWrapper;
