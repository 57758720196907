import React, { ReactElement } from 'react';
import config from '../config';

const CookiePolicy = (): ReactElement => {
  return (
    <div>
      <h1>Cookie Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Medimint uses cookies to enhance your experience on our Platform. This Cookie Policy
        explains what cookies are, how we use them, and your choices regarding cookies.
      </p>

      <h2>2. What Are Cookies?</h2>
      <p>
        Cookies are small text files stored on your device when you visit a website. They help the
        website remember your actions and preferences over a period of time.
      </p>

      <h2>3. How We Use Cookies</h2>
      <p>
        <strong>Essential Cookies:</strong> Necessary for the functioning of the Platform.
        <br />
        <strong>Analytical Cookies:</strong> To understand how you use the Platform and improve it.
        <br />
        <strong>Functional Cookies:</strong> To remember your preferences.
      </p>

      <h2>4. Managing Cookies</h2>
      <p>
        You can manage cookies through your browser settings. You can choose to delete or block
        cookies, but this may affect your ability to use some features of the Platform.
      </p>

      <h2>5. Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. We will notify you of any changes by
        posting the new Cookie Policy on the Platform.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Cookie Policy, please contact us at{' '}
        <a href={`mailto:${config.support.supportEmail}`}>{config.support.supportEmail}</a>
      </p>
    </div>
  );
};

export default CookiePolicy;
