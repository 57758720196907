import React from 'react';
import styles from './LoadingCircle.module.css';

const LoadingCircle = ({
  percentageLoaded,
  size = 25,
}: {
  percentageLoaded?: number;
  size?: number;
}) => {
  const stroke = size / 4;
  const normalizedRadius = size - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference -
    ((percentageLoaded && percentageLoaded !== 0 ? percentageLoaded : 75) / 100) * circumference;

  return (
    <svg height={`${size * 2}px`} width={`${size * 2}px`} className={styles.loadingCircle}>
      <circle
        stroke={'blue'}
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={size}
        cy={size}
        className={(!percentageLoaded || percentageLoaded === 0) && styles.indefiniteAnimation}
      />
      {size > 25 && percentageLoaded > 1 && (
        <text x='50%' y='50%' textAnchor='middle' dy='.3em' fill='black'>
          {Math.round(percentageLoaded)}
          {size > 50 && '%'}
        </text>
      )}
    </svg>
  );
};

export default LoadingCircle;
