import React, { ReactElement } from 'react';
import cs from 'classnames';
import { XCircle, Check2Circle } from 'react-bootstrap-icons';
import style from './StatusCell.module.css';

const ActiveRequestCell = ({ status }: { status: boolean }): ReactElement => {
  const renderStatusIcon = () => {
    if (!status) {
      return <XCircle />;
    }
    return <Check2Circle />;
  };

  const renderStatusValue = () => {
    if (!status) {
      return 'Expired';
    }
    return 'Active';
  };

  return (
    <div
      className={cs([
        style.root,
        renderStatusValue() === 'Active' && style.active,
        renderStatusValue() === 'Expired' && style.expired,
      ])}
    >
      <div className={style.icon}>{renderStatusIcon()}</div>
      <div className={style.label}>{renderStatusValue()}</div>
    </div>
  );
};

export default ActiveRequestCell;
