import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import Pages from './pages/pages';
import { UserProvider } from './shared/context/user.context';

const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <UserProvider>
        <ToastContainer />
        <Pages />
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
