import React, { useEffect, useCallback, useState, useRef } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import dicomParser from 'dicom-parser';
import { Tooltip } from 'react-tooltip';
import styles from './DicomViewerModal.module.css';
import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { StudyFileInterface } from '../../shared/models/file';
import DwvComponent from './DicomViewerComponent';
import messages from '../../shared/staticText/messages';
import MessageBox from '../MessageBox/MessageBox';
import MessageBoxType from '../MessageBox/MessageBoxTypeEnum';
import { extractDICOMData } from '../../shared/utils/extractDicomTags';

const DicomViewerModal = ({ file }: { file: StudyFileInterface }) => {
  const [downloadLoading, setDownloadLoading] = useState<boolean>(true);
  const [studyFile, setStudyFile] = useState<File>();
  const [downloadPercentage, setDownloadPercentage] = useState(3);
  const [dicomTags, setDicomTags] = useState<Record<string, string>>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const cancelTokenSource = useRef(axios.CancelToken.source());

  const getTotalSize = useCallback(() => {
    return axiosInstance({
      method: 'GET',
      url: config.api.studyIdFileDownloadSize.replace(':id', String(file.id)),
    }).then((response) => {
      return response.data;
    });
  }, [file.id]);

  const handleDownload = useCallback(
    (size: number) => {
      if (!file) return;
      setDownloadLoading(true);
      setErrorMessage(undefined);
      cancelTokenSource.current = axios.CancelToken.source();

      axiosInstance({
        method: 'GET',
        url: config.api.studyIdFileDownload.replace(':id', String(file.id)),
        responseType: 'arraybuffer',
        timeout: config.customization.axiosStudyUploadTimeout,
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / size);
          setDownloadPercentage(percentCompleted > 5 ? percentCompleted : 5);
        },
        cancelToken: cancelTokenSource.current.token,
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const dicomFile = new File([blob], 'dicom.dcm', { type: blob.type });
          setStudyFile(dicomFile);
          const byteArray = new Uint8Array(response.data);
          const dataSet = dicomParser.parseDicom(byteArray);
          const parsedData = extractDICOMData(dataSet);
          setDicomTags(parsedData);
          setErrorMessage(undefined);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          } else {
            console.error('Error:', error);
            setErrorMessage(messages.errorOccured);
          }
        })
        .finally(() => {
          setDownloadLoading(false);
        });
    },
    [file],
  );

  useEffect(() => {
    let isMounted = true;
    getTotalSize()
      .then((size) => {
        if (isMounted) {
          handleDownload(size);
        }
      })
      .catch((error) => {
        console.error('Error fetching total size:', error);
        setErrorMessage(messages.errorOccured);
      });

    return () => {
      isMounted = false;
      cancelTokenSource.current.cancel();
    };
  }, [getTotalSize, handleDownload]);

  return (
    <div className={styles.root}>
      {dicomTags && (
        <div className={styles.dicomTitle}>
          <small>
            ../
            {dicomTags['x00200011'] && (
              <>
                <span data-tooltip-id='info_series_number_00200011'>{dicomTags['x00200011']}</span>
                <Tooltip id='info_series_number_00200011' content='Series number' />
              </>
            )}
            {dicomTags['x0008103E'] && (
              <>
                /
                <span data-tooltip-id='info_series_description_0008103E'>
                  {dicomTags['x0008103E']}
                </span>
                <Tooltip id='info_series_description_0008103E' content='Series description' />
              </>
            )}
          </small>
        </div>
      )}
      {downloadLoading && (
        <div className={styles.downloading}>
          <div className={styles.downloadInfo}>
            Processing ...
            <div className={styles.percentageBar}>
              <div
                className={styles.innerPercentageBar}
                style={{ width: downloadPercentage + '%' }}
              ></div>
            </div>
          </div>
        </div>
      )}
      {studyFile && !downloadLoading && (
        <DwvComponent file={studyFile} frameTime={dicomTags['x00181063']} />
      )}
      {errorMessage && <MessageBox message={errorMessage} type={MessageBoxType.ERROR} />}
    </div>
  );
};

export default DicomViewerModal;
