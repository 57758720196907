import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './PatientDetailsMiniBox.module.css';
import { UserInterface } from '../../shared/models/userType';
import calculateAge from '../../shared/utils/calculateAge';
import formatDate from '../../shared/utils/formatDate';

const PatientDetailsMiniBox = ({
  patient,
  loadingGhost,
}: {
  patient: UserInterface;
  loadingGhost?: boolean;
}): ReactElement => {
  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div className={styles.cell}>
        <div className={styles.name}>
          {patient?.data?.firstName} {patient?.data?.lastName}
        </div>
      </div>
      {patient?.data?.birthday && (
        <>
          <div className={styles.cell}>
            <span className={styles.label}>Age:</span> {calculateAge(patient.data.birthday)}
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>DOB:</span> {formatDate(patient.data.birthday)}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientDetailsMiniBox;
