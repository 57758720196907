import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DicomImage, NativePixelDecoder } from 'dcmjs-imaging';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { PlayFill, PauseFill, Repeat, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import Select from 'react-select';
import styles from './DicomViewerComponent.module.css';
import messages from '../../shared/staticText/messages';

const DicomImageViewer = ({
  file,
  loadingGhost,
  frameTime = '60',
}: {
  file: File | null;
  loadingGhost?: boolean;
  frameTime?: string;
}) => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const [initializedDecoder, setInitializedDecoder] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [frameRate, setFrameRate] = useState<number>(24);
  const [frameImages, setFrameImages] = useState([]);

  const initializeDecoder = async () => {
    await NativePixelDecoder.initializeAsync({
      webAssemblyModulePathOrUrl: '/native-pixel-decoder.wasm',
      logNativeDecodersMessages: false,
    });
  };

  const generateAllFrames = useCallback(
    async (dicomImage) => {
      const frames = [];
      if (totalFrames > 0) {
        for (let i = 0; i < totalFrames; i++) {
          const renderingResult = dicomImage.render({ frame: i });
          const pixelData = new Uint8ClampedArray(renderingResult.pixels);
          if (pixelData.length > 0) {
            const imageData = new ImageData(
              pixelData,
              renderingResult.width,
              renderingResult.height,
            );
            frames.push(imageData);
          } else {
            console.error(`Frame ${i} has zero length data.`);
          }
        }
        setFrameImages(frames);
        updateCanvas(frames[0]);
      }
    },
    [totalFrames],
  );

  const processDICOMFile = useCallback(
    async (file: File) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target && e.target.result) {
          const dicomImage = new DicomImage(e.target.result as ArrayBuffer);
          const numFrames = dicomImage.getNumberOfFrames();
          setTotalFrames(numFrames);
          if (frameImages.length === 0) {
            await generateAllFrames(dicomImage);
          }
          if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
              const renderingResult = dicomImage.render({ frame: currentFrame });
              const pixelData = new Uint8ClampedArray(renderingResult.pixels);
              if (pixelData.length > 0) {
                const imageData = new ImageData(
                  pixelData,
                  renderingResult.width,
                  renderingResult.height,
                );
                canvasRef.current.width = renderingResult.width;
                canvasRef.current.height = renderingResult.height;
                context.putImageData(imageData, 0, 0);
              } else {
                console.error('Initial frame has zero length data.');
              }
            }
          }
        }
      };
      reader.readAsArrayBuffer(file);
    },
    [currentFrame, frameImages.length, generateAllFrames],
  );

  const updateCanvas = (imageData) => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = imageData.width;
        canvasRef.current.height = imageData.height;
        context.putImageData(imageData, 0, 0);
      }
    }
  };

  const goToNextFrame = () => {
    setPlaying(false);
    setCurrentFrame((prevFrame) => (prevFrame + 1) % totalFrames);
  };

  const goToPreviousFrame = () => {
    setPlaying(false);
    setCurrentFrame((prevFrame) => (prevFrame - 1 + totalFrames) % totalFrames);
  };

  useEffect(() => {
    if (file && initializedDecoder) {
      processDICOMFile(file);
    }
  }, [file, processDICOMFile, initializedDecoder]);

  useEffect(() => {
    initializeDecoder()
      .then(() => setInitializedDecoder(true))
      .catch(() => toast.error(messages.dicomImageDicoderInitializeFailed));
  }, []);

  const togglePlayback = () => {
    setPlaying((prevValue) => !prevValue);
  };

  const toggleRepeat = () => {
    setRepeat((prevValue) => !prevValue);
  };

  const changeActiveFrame = useCallback(
    async (newFrame: number) => {
      if (frameImages.length > 0) {
        updateCanvas(frameImages[newFrame]);
      } else if (file) {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const dicomImage = new DicomImage(arrayBuffer);
          const renderingResult = dicomImage.render({ frame: newFrame });
          const pixelData = new Uint8ClampedArray(renderingResult.pixels);
          if (pixelData.length > 0) {
            const imageData = new ImageData(
              pixelData,
              renderingResult.width,
              renderingResult.height,
            );
            updateCanvas(imageData);
          } else {
            console.error(`Frame ${newFrame} has zero length data.`);
          }
        } catch (error) {
          toast.error('Failed to load DICOM file');
        }
      }
    },
    [frameImages, file],
  );

  const handleFrameSliderChange = (event) => {
    const newFrame = parseInt(event.target.value, 10);
    setCurrentFrame(newFrame);
    changeActiveFrame(newFrame);
  };

  const changeFrame = useCallback(() => {
    if (playing && currentFrame < totalFrames - 1) {
      changeActiveFrame((currentFrame + 1) % totalFrames);
      setCurrentFrame((frame) => (frame + 1) % totalFrames);
    } else if (playing && repeat) {
      changeActiveFrame(0);
      setCurrentFrame(0);
    }
  }, [playing, currentFrame, totalFrames, repeat, changeActiveFrame]);

  useEffect(() => {
    const normalFrameInterval = Number(frameTime);
    const currentInterval = normalFrameInterval * (24 / frameRate);
    if (playing) {
      const timer = setInterval(changeFrame, currentInterval);
      return () => clearInterval(timer);
    }
  }, [playing, frameRate, changeFrame, frameTime]);

  const speedOptions = [
    { value: 5, label: 'Ultra slow' },
    { value: 15, label: 'Slow' },
    { value: 24, label: 'Normal' },
    { value: 45, label: 'Fast' },
  ];

  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div>
        <div className={styles.playControls}>
          <div className={styles.playBackInnerControls}>
            <button
              onClick={goToPreviousFrame}
              disabled={totalFrames <= 1}
              className={styles.playControlButton}
            >
              <ChevronLeft />
            </button>
            <button
              onClick={togglePlayback}
              disabled={totalFrames <= 1}
              className={styles.playControlButton}
            >
              {playing ? <PauseFill /> : <PlayFill />}
            </button>
            <button
              onClick={goToNextFrame}
              disabled={totalFrames <= 1}
              className={styles.playControlButton}
            >
              <ChevronRight />
            </button>
          </div>
          <input
            type='range'
            min='0'
            max={totalFrames - 1}
            value={currentFrame}
            onChange={handleFrameSliderChange}
            disabled={totalFrames <= 1}
            className={cx(totalFrames <= 1 && styles.disabled)}
          />
          <button
            onClick={toggleRepeat}
            disabled={totalFrames <= 1}
            className={styles.playControlButton}
          >
            <Repeat className={cx(!repeat && styles.fadeIcon)} />
          </button>
          {totalFrames && (
            <Select
              value={speedOptions.find((option) => option.value === frameRate)}
              onChange={(option) => setFrameRate(option?.value ?? 30)}
              options={speedOptions}
              className={cx(styles.speedController, totalFrames <= 1 && styles.disabled)}
            />
          )}
        </div>
      </div>
      <div className={styles.canvasWrapper}>
        <canvas ref={canvasRef} className={styles.canvas}></canvas>
      </div>
    </div>
  );
};

export default DicomImageViewer;
