import React, { ReactElement } from 'react';
import { ClockHistory } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './ActivityLine.module.css';
import { ActivityInterface } from '../../shared/models/activity';
import Tag from '../Tag/Tag';
import { ActivityType } from '../../shared/enum/activity';
import formatDate from '../../shared/utils/formatDate';
import StudyDate from '../Table/StudyDate';
import config from '../../config';

const ActivityLine = ({
  activity,
  bottomHr,
  loadingGhost,
}: {
  activity?: ActivityInterface;
  key: string | number;
  bottomHr: boolean;
  loadingGhost?: boolean;
}): ReactElement => {
  const getTextLine = (): ReactElement | undefined => {
    if (activity?.type === ActivityType.SHARE) {
      return (
        <>
          <span>Your study </span>
          <Link to={config.routes.studyId.replace(':id', String(activity?.study?.id))}>
            {activity?.study?.data?.studyDetails?.name}
          </Link>{' '}
          ({formatDate(activity?.study?.data?.studyDetails?.date)}){' '}
          <span>{activity.completedAt ? 'was shared' : 'is in sharing process'}</span>{' '}
          {activity.share.user.data.firstName && (
            <>
              <span>with</span> {activity.share.user.data.firstName}{' '}
              {activity.share.user.data.lastName}
            </>
          )}
        </>
      );
    }
    if (activity?.type === ActivityType.SHARED) {
      return (
        <>
          <span>
            {activity.study.user.data.firstName} {activity.study.user.data.lastName}{' '}
            {activity.completedAt ? 'shared a study' : 'is sharing a study'}
          </span>{' '}
          {activity?.study?.data?.studyDetails?.name && (
            <>
              <Link to={config.routes.studyId.replace(':id', String(activity?.study?.id))}>
                {activity?.study?.data?.studyDetails?.name}
              </Link>{' '}
              ({formatDate(activity?.study?.data?.studyDetails?.date)}){' '}
            </>
          )}
          <span>with you</span>
        </>
      );
    }
    if (activity?.type === ActivityType.REVOKE) {
      return (
        <>
          You{' '}
          <span>
            {activity.completedAt ? 'revoked access to' : 'initiated the revoke access of'}
          </span>{' '}
          <Link to={config.routes.studyId.replace(':id', String(activity?.study?.id))}>
            {activity?.study?.data?.studyDetails?.name}
          </Link>{' '}
          ({formatDate(activity?.study?.data?.studyDetails?.date)}){' '}
          {activity.share.user.data.firstName && (
            <>
              <span>for</span> {activity.share.user.data.firstName}{' '}
              {activity.share.user.data.lastName}
            </>
          )}
        </>
      );
    }
    if (activity?.type === ActivityType.REVOKED) {
      return (
        <>
          <span>
            {activity.study.user.data.firstName} {activity.study.user.data.lastName}{' '}
            {activity.completedAt ? 'revoked' : 'is revoking'}
          </span>{' '}
          <span> your access to his study </span>
          {activity?.study?.data?.studyDetails?.name && (
            <>
              <Link to={config.routes.studyId.replace(':id', String(activity?.study?.id))}>
                {activity?.study?.data?.studyDetails?.name}
              </Link>{' '}
              ({formatDate(activity?.study?.data?.studyDetails?.date)}){' '}
            </>
          )}
        </>
      );
    }
    if (activity?.type === ActivityType.RELEASE) {
      return (
        <>
          <span>A new study was released: </span>{' '}
          <Link to={config.routes.studyId.replace(':id', String(activity?.study?.id))}>
            {activity?.study?.data?.studyDetails?.name}
          </Link>{' '}
          ({formatDate(activity?.study?.data?.studyDetails?.date)})
        </>
      );
    }
    return;
  };

  return (
    <>
      <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
        <div className={styles.timestamp}>
          {loadingGhost ? (
            <div>-----</div>
          ) : (
            <>
              <ClockHistory />
              <StudyDate
                cell={activity?.completedAt ?? activity?.createdAt}
                excludeTime={!activity?.completedAt}
              />
            </>
          )}
        </div>
        <div className={styles.description}>
          {loadingGhost ? '- - - - - - - - - - - - -' : getTextLine()}
        </div>
        <div className={styles.type}>
          <Tag type={activity?.type} loadingGhost={loadingGhost} />
        </div>
      </div>
      {bottomHr && <hr className={styles.separator} />}
    </>
  );
};

export default ActivityLine;
