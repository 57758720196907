import React from 'react';
import cx from 'classnames';

import styles from './ConfirmText.module.css';

import { ReactComponent as ConfirmProcessingSvg } from '../../assets/check_circle.svg';

type ConfirmTextPropType = {
  text: string;
  classNames?: string;
};

export default function ConfirmText({ text, classNames }: ConfirmTextPropType) {
  return (
    <div className={cx([styles.resentText, classNames])}>
      {text} <ConfirmProcessingSvg width={15} height={15} />
    </div>
  );
}
