import React, { ReactElement } from 'react';
import config from '../config';

const TermsAndConditions = (): ReactElement => {
  return (
    <div>
      <h1>Terms of Use</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Medimint. These Terms of Use (&quot;Terms&quot;) govern your use of our
        application platform (&quot;Platform&quot;). By accessing or using the Platform, you agree
        to be bound by these Terms.
      </p>

      <h2>2. User Registration</h2>
      <p>
        To register on Medimint, you must provide your email, date of birth (DOB), and the last 5
        digits of your social security number (SSN). Medimint uses passwordless authentication where
        a verification code is sent to your registered email. You are responsible for maintaining
        the confidentiality of your registration information and for all activities that occur under
        your account.
      </p>

      <h2>3. Use of Platform</h2>
      <p>
        Medimint allows providers to upload medical data to the Platform. The data is encrypted with
        the patient&apos;s public key and securely shared. Patients own their data and can share it
        with anyone or revoke previously shared access using blockchain smart contracts for access
        control.
      </p>

      <h2>4. Data Security</h2>
      <p>
        Medimint is committed to protecting your personal information and employs a variety of
        security technologies and procedures to help protect your information from unauthorized
        access, use, and disclosure. This includes, but is not limited to, the use of firewalls,
        complex passwords, dual-factor authentication, various audit trails, data loss prevention,
        regular penetration testing, and risk assessments. Moreover, Medimint stores your personal
        information on secure servers, uses robust encryption technology, and requires comprehensive
        security training for our workforce.
      </p>

      <h2>5. User Responsibilities</h2>
      <p>
        You agree to provide accurate and complete information during registration.
        <br />
        You must not use the Platform for any illegal or unauthorized purpose.
        <br />
        You are responsible for maintaining the confidentiality of your login credentials.
      </p>

      <h2>6. Termination</h2>
      <p>
        Medimint reserves the right to suspend or terminate your account at any time for any reason,
        including if you violate these Terms.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        Medimint is not liable for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any
        loss of data, use, goodwill, or other intangible losses, resulting from your use of the
        Platform.
      </p>

      <h2>8. Changes to Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of any changes by posting
        the new Terms on the Platform. You are advised to review these Terms periodically for any
        changes.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of [Your
        Country/State], without regard to its conflict of law provisions.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at{' '}
        <a href={`mailto:${config.support.supportEmail}`}>{config.support.supportEmail}</a>
      </p>
    </div>
  );
};

export default TermsAndConditions;
