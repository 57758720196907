import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { useUserContext } from '../../shared/context/user.context';
import { UserRoles } from '../../shared/enum/userRoles';
import { Form, Formik } from 'formik';
import InputField from '../Form/InputField';
import countryOptions from '../../shared/utils/countryOptions';
import Button from '../Button/Button';
import useUser from '../../shared/hooks/useUser';
import { UserUpdateType } from '../../shared/models/userType';
import styles from './EditProfileForm.module.css';
import formatDateToMmDdYyyyy from '../../shared/utils/formatDateToMmDdYyyyy';

const EditProfileForm = (): ReactElement => {
  const { userData } = useUserContext();
  const { errorMessage, loading, onUpdate } = useUser();

  const isProvider = userData.roleId === UserRoles.PROVIDER;

  const initialValues: UserUpdateType = {
    firstName: userData.data.firstName ?? '',
    lastName: userData.data.lastName ?? '',
    email: userData.data.email ?? '',
    phoneNumber: userData.data.phoneNumber ?? '',
    ...(!isProvider && {
      birthday: formatDateToMmDdYyyyy(userData?.data?.birthday) ?? '',
      ssn: userData.data.ssn ?? '',
    }),
    ...(isProvider && {
      npi: userData.data.npi ?? '',
      institutionName: userData.data.institutionName ?? '',
    }),
    address: userData.data.address ?? '',
    secondAddress: userData.data.secondAddress ?? '',
    city: userData.data.city ?? '',
    state: userData.data.state ?? '',
    country: userData.data.country ?? '',
    zipCode: userData.data.zipCode ?? '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    address: isProvider && Yup.string().required('Address is required'),
    city: isProvider && Yup.string().required('City is required'),
    state: isProvider && Yup.string().required('State is required'),
    country: isProvider && Yup.string().required('Country is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onUpdate}
      validateOnChange
      validateOnBlur
    >
      <Form className={styles.form}>
        <div className={styles.inputRow}>
          <InputField label='First Name' name='firstName' type='text' required />
          <InputField label='Last Name' name='lastName' type='text' required />
        </div>
        <InputField name='email' type='text' label='Email' readOnly />
        <InputField name='phoneNumber' type='phone' label='Phone Number' required={isProvider} />
        {!isProvider && (
          <>
            <InputField name='birthday' type='text' label='Date of birth' readOnly />
            <InputField label='SSN (last 5 digits)' name='ssn' type='text' readOnly />
          </>
        )}
        {isProvider && (
          <>
            <InputField name='npi' type='text' label='NPI' readOnly />
            <InputField name='institutionName' type='text' label='Institution Name' required />
          </>
        )}
        <InputField name='address' type='text' label='Address 1' required={isProvider} />
        <InputField name='secondAddress' type='text' label='Address 2' />
        <div className={styles.inputRow}>
          <InputField label='City' name='city' type='text' required={isProvider} />
          <InputField label='State / County' name='state' type='text' required={isProvider} />
        </div>
        <InputField name='zipCode' type='text' label='Zipcode' />
        <InputField
          label='Country'
          name='country'
          type='select'
          options={countryOptions}
          required={isProvider}
        />
        {errorMessage && <p className={styles.erorr}>{errorMessage}</p>}
        <div>
          <Button style='navy' size='large' type='submit' loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default EditProfileForm;
