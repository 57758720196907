import React, { ReactElement } from 'react';
import cx from 'classnames';
import { Cake, Upc, ArrowRightShort } from 'react-bootstrap-icons';
import { Tooltip } from 'react-tooltip';
import styles from './AccesCodeInfoMiniBox.module.css';
import formatDate from '../../shared/utils/formatDate';
import { StudyRequestAccessResponseInterface } from '../../shared/models/dicom';
import { Link } from 'react-router-dom';
import config from '../../config';
import ActiveRequestCell from '../Table/ActiveRequestCell';
import { checkIfDateIsPast } from '../../shared/utils/checkDateIfIsPast';
import StudyDate from '../Table/StudyDate';
import { UserStatus } from '../../shared/enum/userStatus';

const AccesCodeInfoMiniBox = ({
  accessInfo,
  loadingGhost,
}: {
  accessInfo: StudyRequestAccessResponseInterface;
  loadingGhost?: boolean;
}): ReactElement => {
  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div className={styles.cell}>
        <strong>
          <small>FROM:</small>
        </strong>
        <div>
          <span>
            {accessInfo?.fromFirstName} {accessInfo?.fromLastName}
          </span>
        </div>
        {accessInfo?.npi && (
          <small>
            <Upc className={styles.physicianSvg} data-tooltip-id='npiSerial' /> {accessInfo.npi}
          </small>
        )}
      </div>
      <div className={styles.cell}>
        <strong>
          <small>FOR PATIENT:</small>
        </strong>
        <div>
          {accessInfo?.patientStatus === UserStatus.ACTIVE && (
            <Link
              className={styles.link}
              to={config.routes.patientRecords.replace(':id', String(accessInfo?.patientId))}
            >
              <span>
                {accessInfo?.patientFirstName} {accessInfo?.patientLastName}
              </span>
            </Link>
          )}
          {accessInfo?.patientStatus !== UserStatus.ACTIVE && (
            <span>
              {accessInfo?.patientFirstName} {accessInfo?.patientLastName}
            </span>
          )}
        </div>
        {accessInfo?.patientBirthday && (
          <small>
            <Cake className={styles.dateSvg} data-tooltip-id='patientBirthday' />{' '}
            {formatDate(accessInfo?.patientBirthday)}
          </small>
        )}
      </div>

      <div className={styles.cell}>
        <strong>
          <small>VALIDTITY PERIOD:</small>
        </strong>
        <div className={styles.datesPeriod}>
          {accessInfo?.createdAt && <StudyDate cell={accessInfo?.createdAt} />}
          <ArrowRightShort />
          {accessInfo?.accessCodeExpire && <StudyDate cell={accessInfo?.accessCodeExpire} />}
        </div>
      </div>
      <div className={styles.cell}>
        <strong>
          <small>STATUS:</small>
        </strong>
        {accessInfo?.accessCodeExpire && (
          <ActiveRequestCell status={checkIfDateIsPast(accessInfo?.accessCodeExpire)} />
        )}
      </div>
      <Tooltip id='patientBirthday' content='Patient date of birth' />
      <Tooltip id='npiSerial' content='NPI' />
    </div>
  );
};

export default AccesCodeInfoMiniBox;
