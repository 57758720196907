import React, { ReactElement } from 'react';
import styles from './notFound.module.css';
import Section from '../../components/Section/Section';

const NotFound = (): ReactElement => {
  return (
    <Section>
      <div className={styles.root}>
        <h1>404</h1>
        <h3>Page not found!</h3>
      </div>
    </Section>
  );
};

export default NotFound;
