import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { ReactComponent as UploadIcon } from '../../assets/upload.svg';
import styles from './FileDrop.module.css';
import FileItem from './FileItem';
import messages from '../../shared/staticText/messages';
import { FormFile } from '../../shared/hooks/useRelease';
import config from '../../config';

interface FileDropProps {
  files: FormFile[];
  setFiles: React.Dispatch<React.SetStateAction<FormFile[]>>;
  loading: boolean;
  disabled?: boolean;
  fileErrors: Record<string, string[]>;
}

const validateFile = (file: File, currentTotalSize: number): string | null => {
  const validExtensions = ['.dcm', '.pdf', '.doc', '.zip', '.hl7', '.fhir'];
  const fileSizeLimit = config.customization.maxFileSizeInMb * 1024 * 1024;
  const totalFileSizeLimit = config.customization.maxStudySizeInGb * 1024 * 1024 * 1024;
  const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
  const isFileTypeValid = validExtensions.includes(fileExtension.toLowerCase());
  const isFileSizeValid = file.size <= fileSizeLimit;
  const isTotalSizeValid = currentTotalSize + file.size <= totalFileSizeLimit;

  if (!isFileTypeValid) {
    return messages.invalidFiletype;
  }
  if (!isFileSizeValid) {
    return messages.maxFileSize;
  }
  if (!isTotalSizeValid) {
    return messages.maxTotalFilesSize;
  }
  return null;
};

const FileDrop = ({ files, setFiles, loading, disabled, fileErrors }: FileDropProps) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const getTotalSize = () => files.reduce((total, formFile) => total + formFile.file.size, 0);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const getUniqueFileName = (fileName: string): string => {
    let newFileName = fileName;
    let fileExists = files.some((formFile) => formFile.file.name === newFileName);
    let count = 1;

    while (fileExists) {
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
      const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
      newFileName = `${fileNameWithoutExtension}-${count}${fileExtension}`;
      count++;
      fileExists = files.some((formFile) => formFile.file.name === newFileName);
    }

    return newFileName;
  };

  const addFiles = (newFiles: File[]) => {
    const currentTotalSize = getTotalSize();

    const newFormFiles = newFiles
      .filter((file) => {
        const fileError = validateFile(file, currentTotalSize);
        if (fileError) {
          toast.error(fileError);
          return false;
        }
        return true;
      })
      .map((file) => {
        const uniqueFileName = getUniqueFileName(file.name);
        const renamedFile = new File([file], uniqueFileName, { type: file.type });
        return { file: renamedFile, loading: false, parsedData: undefined };
      });

    setFiles((prevFiles) => [...prevFiles, ...newFormFiles]);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files) {
      addFiles(Array.from(e.dataTransfer.files));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      addFiles(Array.from(e.target.files));
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const removeFile = (fileIndex: number) => {
    if (loading) return;
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
  };

  return (
    <>
      <div
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={cx(
          styles.root,
          dragging && styles.dragging,
          files && styles.fileAdded,
          disabled && styles.disabled,
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <UploadIcon onClick={handleClick} width={108} height='100%' className={styles.icon} />
            <p>
              <span onClick={handleClick}>Click to upload</span> or drag and drop to upload medical
              image
            </p>
            <small>
              Allowed files: .dcm, .pdf, .doc, .zip, .hl7, .fhir | Max file size: 1000mb <br /> Max
              total study size: 5GB
            </small>
          </div>
        </div>
        <input
          type='file'
          accept='.dcm, .DCM, .pdf, .PDF, .doc, .DOC, .zip, .ZIP, .hl7, .HL7, .fhir, .FHIR'
          onChange={handleChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
          multiple={true}
        />
      </div>
      {files.length > 0 && (
        <div className={styles.fileList}>
          {files.map((formFile, index) => (
            <FileItem
              key={formFile.file.name + index}
              formFile={formFile}
              errors={fileErrors[formFile.file.name]}
              onRemove={() => removeFile(index)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FileDrop;
