import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import styles from '../SignIn.module.css';

import Button from '../../../components/Button/Button';
import InputField from '../../../components/Form/InputField';
import { LoginAccessCodeType, LoginRequestType } from '../../../shared/models/authType';
import { ReactComponent as RequestProcessingSvg } from '../../../assets/request_process.svg';

type OtpFormPropTypes = {
  email: string;
  handleSubmit: (values: LoginAccessCodeType) => Promise<void>;
  handleResend: (values: LoginRequestType) => Promise<void>;
  errorMessage: string;
  resetError: Dispatch<SetStateAction<string>>;
  loading: boolean;
  resendValues: LoginRequestType;
  loadingResendEmailSent: boolean;
};

const OtpForm = ({
  email,
  handleSubmit,
  handleResend,
  resendValues,
  errorMessage,
  resetError,
  loading,
  loadingResendEmailSent,
}: OtpFormPropTypes) => {
  const otpValidationSchema = Yup.object({
    accessCode: Yup.string()
      .required('OTP is required')
      .matches(/^[a-zA-Z0-9]{6}$/, 'OTP must be exactly 6 characters'),
  });

  return (
    <Formik
      initialValues={{
        email: email || '',
        accessCode: '',
      }}
      validationSchema={otpValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange }) => {
        const handleInputChange = (e) => {
          resetError(undefined);
          handleChange(e);
        };
        return (
          <Form className={styles.signInForm}>
            <RequestProcessingSvg width={134} height={109} className={styles.centerSvg} />
            <p>Add the one time code you just received on your email.</p>
            <InputField
              label='Verification code*'
              name='accessCode'
              type='text'
              isCodeField
              maxLength={6}
              regex={/^[a-zA-Z0-9]{6}$/}
              onChange={handleInputChange}
              required
            />
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <Button style='navy' size='large' type='submit' loading={loading}>
              Sign in
            </Button>
            <div className={styles.row}>
              Didn’t get an email?{' '}
              <Button
                onClick={() => handleResend(resendValues)}
                type='button'
                style='inlineButton'
                loading={loadingResendEmailSent}
              >
                Resend
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OtpForm;
