import React, { ReactElement } from 'react';
import styles from './HistoryTabsButtons.module.css';
import Button from '../Button/Button';
import config from '../../config';
import { useLocation } from 'react-router-dom';

const HistoryTabsButtons = (): ReactElement => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const buttons = [
    {
      label: 'Release',
      route: config.routes.releaseHistory,
    },
    {
      label: 'Request',
      route: config.routes.requestHistory,
    },
  ];

  return (
    <div className={styles.root}>
      {buttons.map((button) => (
        <Button
          key={button.route}
          toPage={button.route}
          style={isActive(button.route) ? 'primary' : 'naked'}
          size='large'
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};

export default HistoryTabsButtons;
