import React, { ReactElement } from 'react';
import Section from '../components/Section/Section';
import UserSettings from '../components/UserSettings/UserSettings';

const SettingsPage = (): ReactElement => {
  return (
    <Section title='Settings'>
      <UserSettings />
    </Section>
  );
};

export default SettingsPage;
