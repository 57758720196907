import { formatDateInheritLocale } from './formatDateInheritLocale';

const extractUserData = (data: Record<string, string>) => {
  return {
    firstName: data['user.firstName'],
    lastName: data['user.lastName'],
    email: data['user.email'],
    ssn: data['user.ssn'],
    birthday: formatDateInheritLocale(data['user.birthday']),
  };
};

export default extractUserData;
