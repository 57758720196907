import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ExclamationOctagon, ExclamationTriangle } from 'react-bootstrap-icons';
import styles from './MessageBox.module.css';
import MessageBoxType from './MessageBoxTypeEnum';

const MessageBox = ({ type, message }: { type: MessageBoxType; message: string }): ReactElement => {
  return (
    <div
      className={cx(
        styles.root,
        type === MessageBoxType.ERROR && styles.error,
        type === MessageBoxType.WARNING && styles.warning,
      )}
    >
      <div className={styles.icon}>
        {type === MessageBoxType.ERROR && <ExclamationOctagon />}
        {type === MessageBoxType.WARNING && <ExclamationTriangle />}
      </div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default MessageBox;
