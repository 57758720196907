import React from 'react';

import styles from './StudyAccess.module.css';

import Button from '../Button/Button';

const StudyAccess = ({
  buttons,
}: {
  buttons: {
    label: string;
    url: string;
  }[];
}) => {
  return (
    <div className={styles.root}>
      {buttons &&
        buttons.map((button) => (
          <Button toPage={button.url} style='naked' key={button.url}>
            {button.label}
          </Button>
        ))}
    </div>
  );
};

export default StudyAccess;
