import React, { ReactElement } from 'react';
import config from '../config';

const PrivacyPolicy = (): ReactElement => {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Medimint values your privacy. This Privacy Policy explains how we collect, use, disclose,
        and protect your information when you use our Platform.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        <strong>Personal Information:</strong> Email, DOB, and last 5 digits of SSN.
        <br />
        <strong>Medical Data:</strong> Uploaded by providers and encrypted with your public key.
        <br />
        <strong>Usage Data:</strong> Information about your use of the Platform, such as access
        times and pages viewed.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>
        To Provide and Maintain the Platform: Ensure the security and functionality of the Platform.
        <br />
        To Communicate with You: Send updates, security alerts, and support messages.
        <br />
        To Improve the Platform: Analyze usage patterns to enhance user experience.
      </p>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not sell or license your information. These are the limited ways we interact with your
        information:
        <br />
        <strong>Service Providers:</strong> Third parties who perform services on our behalf.
        <br />
        <strong>Legal Requirements:</strong> If required by law or to protect our rights.
      </p>

      <h2>5. Data Security</h2>
      <p>
        Medimint is committed to protecting your personal information and employs a variety of
        security technologies and procedures to help protect your information from unauthorized
        access, use, and disclosure. This includes, but is not limited to, the use of firewalls,
        complex passwords, dual-factor authentication, various audit trails, data loss prevention,
        regular penetration testing, and risk assessments. Moreover, Medimint stores your personal
        information on secure servers, uses robust encryption technology to encrypt your personal
        information, and requires comprehensive security training for our workforce.
      </p>

      <h2>6. Your Rights</h2>
      <p>
        <strong>Access:</strong> You can access your personal information.
        <br />
        <strong>Correction:</strong> You can request corrections to inaccurate information.
        <br />
        <strong>Deletion:</strong> You can request the deletion of your data.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on the Platform.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at{' '}
        <a href={`mailto:${config.support.supportEmail}`}>{config.support.supportEmail}</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
