import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Section.module.css';

interface SectionProps {
  children: ReactNode;
  title?: string;
  flexSpacing?: boolean;
  flexColumnOrientation?: boolean;
  classNames?: string;
}

const Section = ({
  children,
  title,
  flexSpacing,
  flexColumnOrientation,
  classNames,
}: SectionProps): ReactElement => {
  return (
    <div className={cx('container', styles.root, classNames)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div
        className={cx(
          styles.inner,
          flexSpacing && styles.flexSpacing,
          flexColumnOrientation && styles.flexColumnOrientation,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
