export enum ActivityType {
  RELEASE = 1,
  SHARE = 2,
  SHARED = 20,
  REVOKE = 3,
  REVOKED = 30,
}

export enum ActivityStatus {
  INITIATED = 0,
  COMPLETED = 1,
}
