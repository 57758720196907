import React, { useRef, useEffect } from 'react';
import { useFormikContext } from 'formik';
import cx from 'classnames';
import styles from './InputField.module.css';

interface CodeInputFieldProps {
  name: string;
  length: number;
}

const CodeInputField = ({ name, length }: CodeInputFieldProps) => {
  const { setFieldValue, values } = useFormikContext();
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array.from({ length }, () => null));
  const fieldValue: string[] = values[name]?.split('') || Array(length).fill('');

  useEffect(() => {
    setFieldValue(name, '');
  }, [setFieldValue, name]);

  const handleChange = (value: string, index: number) => {
    const newCode = [...fieldValue];
    newCode[index] = value;
    setFieldValue(name, newCode.join(''));

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKey = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && index > 0 && !fieldValue[index]) {
      inputRefs.current[index - 1]?.focus();
    } else if (event.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (event.key === 'ArrowRight' && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text').slice(0, length);
    const currentIndex = inputRefs.current.findIndex((ref) => document.activeElement === ref);
    const newCode = [...fieldValue];

    pasteData.split('').forEach((char, i) => {
      const position = currentIndex + i;
      if (position < length && inputRefs.current[position]) {
        inputRefs.current[position].value = char;
        newCode[position] = char;
      }
    });

    setFieldValue(name, newCode.join(''));
    const nextInputIndex = currentIndex + pasteData.length;
    if (nextInputIndex < length && inputRefs.current[nextInputIndex]) {
      inputRefs.current[nextInputIndex].focus();
    } else {
      inputRefs.current[length - 1]?.focus();
    }
  };

  return (
    <div className={cx(styles.input, styles.codeInputWrapper)}>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          className={styles.codeInput}
          maxLength={1}
          value={fieldValue[index] || ''}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKey(e, index)}
          onPaste={handlePaste}
          style={{ width: `calc(100% / ${length})` }}
          placeholder='-'
        />
      ))}
    </div>
  );
};

export default CodeInputField;
