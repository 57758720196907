import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import styles from './SideBox.module.css';

interface SideBoxProps {
  title: string;
  children: ReactNode;
  classNames?: string;
  buttons?: ReactElement[];
  topIcon?: ReactElement;
}

const SideBox = ({ title, children, buttons, topIcon, classNames }: SideBoxProps): ReactElement => {
  return (
    <div className={cx(styles.root, classNames)}>
      {topIcon}
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.contents}>{children}</div>
      {Boolean(buttons?.length) && (
        <div className={styles.buttons}>{buttons.map((button) => button)}</div>
      )}
    </div>
  );
};

export default SideBox;
