import React, { ReactElement, useEffect, useState } from 'react';

import InputField from '../Form/InputField';
import useDebounce from '../../shared/hooks/useDebounce';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import { formatDateInheritLocale } from '../../shared/utils/formatDateInheritLocale';

type FilterInputProps = {
  placeholder?: string;
  name: string;
  type?: string;
  onChange?: (field: string, newVal: string) => void;
  classNames?: string;
  icon?: ReactElement;
};

const FilterInput = ({ placeholder, name, type, onChange, classNames, icon }: FilterInputProps) => {
  const [fieldValue, setFieldValue] = useState<string>();
  const debouncedSearch = useDebounce(fieldValue, 1000);

  const onFieldChange = (field: string, newVal: string) => {
    if (type === FieldTypes.DATE && Boolean(newVal)) {
      newVal = formatDateInheritLocale(newVal);
    }
    if (type !== 'text' && onChange) {
      onChange(field, newVal);
      return;
    }
    setFieldValue(newVal);
  };

  useEffect(() => {
    if (type === 'text' && onChange) {
      onChange(name, debouncedSearch);
    }
  }, [debouncedSearch, name, onChange, type]);

  return (
    <InputField
      icon={icon}
      name={name}
      type={type}
      onChange={onFieldChange}
      placeholder={placeholder}
      classNames={classNames}
    />
  );
};

export default FilterInput;
