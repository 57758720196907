import config from "../../config";

const messages = {
  errorOccured: 'An error occurred',
  errorTryAgainLater: 'An error occured, try again later or contact support',
  invalidShare: 'Invalid share',
  alreadySharedToUser: 'Already shared to this user',
  revokedSucces: 'Revoked successfully requested',
  sharedSuccess: 'Shared successfully requested',
  userNotFound: 'Account does not exist',
  requiredField: 'This field is mandatory',
  invalidEmail: 'Invalid email',
  invalidFiletype: 'Invalid file type. Only .dcm, .pdf, .doc, .zip, .hl7, .fhir files are allowed.',
  maxFileSize: 'File size exceeds the limit of 1000MB.',
  maxTotalFilesSize: 'Total files size limit is 5GB',
  dicomDownloadError: 'Dicom download error',
  dicomImageDicoderInitializeFailed: 'Failed to initialize Dicom viewer',
  onlyPastDates: 'Please add a date from the past',
  minLenght6: 'The length of allowed codes is 6',
  invalidCodeOrBirthday: 'Invalid access code or birthday',
  expiredAccessCode: 'Expired access code',
  atLeastOneDicom: 'Upload at least one Dicom file',
  dobNotMatchError:
    "Patient's date of birth does not match with the information stored in the database",
  firstNameNotMatchError:
    "Patient's first name does not match the information stored in the database",
  lastNameNotMatchError:
    "Patient's last name does not match the information stored in the database",
  ssnNotMatchError: "Patient's SSN does not match the information stored in the database",
  emailNotMatchError: "Patient's email does not match the information stored in the database",
  min5charsLength: 'SSN must be 5 numbers long',
  min6charsLength: 'Zipcode must be 6 numbers long',
  updatedSuccessfully: 'Updated successfully',
  providerNotConfirmed: 'The provider account has not been approved yet, please wait or ',
  emailIsNotConfirmed: 'Email is not confirmed',
  userNotFoundSingIn: 'User does not exist. Please sign up.',
  wrongCredentials: 'Wrong credentials',
  roleNotMatch: 'This email address is reserved to patient account.\nPlease register as patient!',
  requiredPhysicianFirstName:
    "If you provide an email address for the physician, the physician's first name is required",
  requiredPhysicianLastName:
    "If you provide an email address for the physician, the physician's last name is required",
  registerBirthdayNotMatch:
    `The Date of Birth you introduced does not match with the Date of Birth from the study. Please contact ${config.support.supportEmail}.`,
  errorRegisterGeneric: 'An error occurred during register request.',
};

export default messages;
