import React, { ReactElement } from 'react';
import styles from './Instructions.module.css';

const ReleaseInstructions = (): ReactElement => (
  <div className={styles.instructions}>
    <h3 className={styles.instructionsTitle}>Instructions/infos</h3>
    <ul>
      <li>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc dolor. Vestibulum
        elementum aliquet mattis. Nunc ac ex condimentum purus laoreet tempus sagittis at augue.
        Morbi iaculis et dolor eu eleifend.
      </li>
      <li>
        Nunc vitae felis sed augue malesuada egestas eget et urna. Cras elementum finibus orci eu
        sollicitudin. Praesent aliquet pretium nibh, sit amet dapibus metus porttitor vitae.
      </li>
      <li>
        Nam vestibulum purus leo, vehicula tempus libero gravida at. Sed id viverra massa. Nulla
        venenatis semper velit rhoncus varius.
      </li>
      <li>
        In hac habitasse platea dictumst. Fusce convallis ac nisi sed elementum. Proin suscipit
        bibendum odio eu maximus. Nunc fringilla lacinia laoreet. Aliquam sed varius orci.
      </li>
    </ul>
  </div>
);

export default ReleaseInstructions;
