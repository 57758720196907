import React, { ReactElement, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { XSquareFill } from 'react-bootstrap-icons';
import styles from './ShareStudyButton.module.css';
import Button from '../Button/Button';
import InputField from '../Form/InputField';
import { revokesShareStudy, shareStudy } from '../../shared/services/dicom.service';
import { ShareError } from '../../shared/enum/shareErrors';
import messages from '../../shared/staticText/messages';
import { StudyInterface } from '../../shared/models/study';
import { StudyShareInterface } from '../../shared/models/shares';
import { ReactComponent as ProcessingSVG } from '../../assets/processing.svg';
import { ShareStatus } from '../../shared/enum/shareStatus';

type ShareStudyButtonProps = {
  share?: StudyShareInterface;
  study: StudyInterface;
  email?: string;
  fetchStudyShares?: (studyId: number, page: number) => void;
  page?: number;
  isRevoke?: boolean;
  loadingGhost?: boolean;
  alternativeShareButtonText?: string;
};

type ShareFormValues = {
  email: string;
  description?: string;
};

const ShareStudyButton = ({
  share,
  study,
  email,
  fetchStudyShares,
  page,
  isRevoke,
  loadingGhost,
  alternativeShareButtonText,
}: ShareStudyButtonProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const formikRef = useRef<FormikProps<ShareFormValues>>(null);

  const getText = (status: ShareStatus): string => {
    switch (status) {
      case ShareStatus.SHARE_PENDING:
        return 'Pending access';
      case ShareStatus.ACTIVE:
        return 'Revoke access';
      case ShareStatus.REVOKE_PENDING:
        return 'Pending revoke';
      default:
        return '';
    }
  };

  const getDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const onSubmit = async (
    values: ShareFormValues,
    { resetForm }: FormikHelpers<ShareFormValues>,
  ): Promise<void> => {
    try {
      setLoading(true);
      setErrorMessage(undefined);

      if (isRevoke) {
        await revokesShareStudy(String(study?.id), values.email);
        toast.success(messages.revokedSucces);
      } else {
        await shareStudy(String(study?.id), values.email, values.description);
        toast.success(messages.sharedSuccess);
      }

      resetForm();
      fetchStudyShares && fetchStudyShares(study?.id, page);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.data.message === ShareError.ALREADY_SHARED) {
          toast.error(messages.alreadySharedToUser);
          setErrorMessage(messages.alreadySharedToUser);
        } else if (error.response.data.message === ShareError.INVALID_SHARE) {
          toast.error(messages.invalidShare);
          setErrorMessage(messages.invalidShare);
        } else if (error.response.data.message === ShareError.USER_NOT_FOUND) {
          toast.error(messages.userNotFound);
          setErrorMessage(messages.userNotFound);
        } else {
          toast.error(messages.errorOccured);
          setErrorMessage(messages.errorOccured);
        }
      } else {
        toast.error(messages.errorOccured);
        setErrorMessage(messages.errorOccured);
        console.log(error);
      }
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  const onModalSubmit = async () => {
    if (formikRef.current) {
      const errors = await formikRef.current.validateForm();
      const errorKeys = Object.keys(errors);
      if (errorKeys.length === 0) {
        await formikRef.current.submitForm();
      } else {
        const firstErrorMessage = errors[errorKeys[0]];
        if (firstErrorMessage === 'Invalid email') {
          setErrorMessage(firstErrorMessage);
        } else {
          setErrorMessage('All fields are mandatory');
        }
        throw new Error(firstErrorMessage);
      }
    }
  };

  const shareValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is mandatory'),
  });

  return (
    <>
      {isRevoke ? (
        <Button
          style='blank'
          loadingGhost={loadingGhost}
          disabled={share?.status !== 1}
          icon={
            share?.status === ShareStatus.ACTIVE ? (
              <XSquareFill />
            ) : (
              (share?.status === ShareStatus.REVOKE_PENDING ||
                share?.status === ShareStatus.SHARE_PENDING) && (
                <ProcessingSVG className={styles.rotating} />
              )
            )
          }
          modal={
            study?.id && share?.status == ShareStatus.ACTIVE
              ? {
                  title: <big>Revoke confimation</big>,
                  submitText: 'Revoke',
                  onSubmit: onModalSubmit,
                  onCancel: () => {},
                  loading,
                  contents: (
                    <div className={styles.root}>
                      <Formik
                        innerRef={formikRef}
                        initialValues={{ email: email || '' }}
                        onSubmit={onSubmit}
                      >
                        <Form className={styles.form}>
                          <div className={styles.revokeModalContents}>
                            <p>
                              <b>Are you sure that you want to revoke access for this user?</b>
                            </p>
                            <p>{email} will not have access to view this study anymore.</p>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  ),
                }
              : undefined
          }
        >
          {getText(share?.status)}
        </Button>
      ) : (
        <Button
          style='naked'
          loadingGhost={loadingGhost}
          modal={
            study?.id
              ? {
                  title: (
                    <big>
                      <strong>{study?.data?.studyDetails?.name}</strong> -{' '}
                      {getDate(study?.data?.studyDetails?.date)}
                    </big>
                  ),
                  submitText: 'Share',
                  onSubmit: onModalSubmit,
                  onCancel: () => {},
                  loading,
                  contents: (
                    <div className={styles.root}>
                      <Formik
                        innerRef={formikRef}
                        initialValues={{ email: email || '', description: '' }}
                        onSubmit={onSubmit}
                        validationSchema={shareValidationSchema}
                      >
                        <Form className={styles.form}>
                          <InputField
                            name='email'
                            type='text'
                            label={'Share with'}
                            required
                            readOnly={!!email}
                          />
                          <InputField
                            name='description'
                            type='text'
                            label={'Description'}
                            maxLength={60}
                          />
                          {errorMessage && (
                            <div className={styles.errorMessage}>{errorMessage}</div>
                          )}
                        </Form>
                      </Formik>
                    </div>
                  ),
                }
              : undefined
          }
        >
          {alternativeShareButtonText ?? 'New Share'}
        </Button>
      )}
    </>
  );
};

export default ShareStudyButton;
