import React, { ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './ExternalFlag.module.css';
import formatDate from '../../shared/utils/formatDate';

type ExternalFlagProps = {
  providerName?: string;
  requestedDate?: string;
};

const ExternalFlag = ({ providerName, requestedDate }: ExternalFlagProps): ReactElement => {
  const tooltipId = `tooltipExternalInfo-${providerName}-${requestedDate}`;
  return (
    <>
      <div className={styles.root}>
        <div className={styles.flag} data-tooltip-id={tooltipId}>
          E
        </div>
      </div>
      <Tooltip id={tooltipId}>
        <div className={styles.tooltipRoot}>
          <strong>Uploaded by external provider</strong>
          <br />
          Provider: {providerName} <br />
          Requested date: {formatDate(requestedDate)}
        </div>
      </Tooltip>
    </>
  );
};

export default ExternalFlag;
