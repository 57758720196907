import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styles from './UserSettings.module.css';
import { useUserContext } from '../../shared/context/user.context';
import { UserRoles } from '../../shared/enum/userRoles';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import EditProfileForm from './EditProfileForm';
import SideBox from './SideBox';
import Button from '../Button/Button';
import config from '../../config';
import { ReactComponent as ImportStudiesIcon } from '../../assets/importStudiesIcon.svg';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import ConfirmText from '../ConfirmText/ConfirmText';

const UserSettings = (): ReactElement => {
  const {
    userData,
    initLoading,
    handleImportStudiesEmailRequest,
    emailRequestLoading,
    emailRequestSent,
  } = useUserContext();

  if (initLoading) {
    return <LoadingAnimation size={20} />;
  }

  const RequestButton = () => {
    if (emailRequestSent) {
      return <ConfirmText text='Email sent successfully!' classNames={styles.confirmText} />;
    }
    return (
      <Button onClick={handleImportStudiesEmailRequest} loading={emailRequestLoading}>
        Request
      </Button>
    );
  };

  const onSupportClick = () => {
    const email = config.support.supportEmail;
    const subject = encodeURIComponent('Support Request');
    const body = encodeURIComponent(
      'Hello,\n\nI need help with ... [describe your issue or question here].\n\nThank you!',
    );
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  const resourcesRoutes = [
    {
      label: 'Terms and conditions',
      link: config.routes.termsAndConditions,
    },
    {
      label: 'Privacy policy',
      link: config.routes.privacyPolicy,
    },
    {
      label: 'Cookie policy',
      link: config.routes.cookiePolicy,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.leftColumn}>
        <EditProfileForm />
      </div>
      <div className={styles.rightColumn}>
        {userData.roleId === UserRoles.PATIENT && (
          <SideBox
            title='Import studies'
            classNames={styles.importStudiesBox}
            buttons={[<RequestButton key='RequestButton' />]}
            topIcon={
              <ImportStudiesIcon className={styles.importStudiesIcon} width={249} height='100%' />
            }
          >
            <p>
              Do you want to import studies from other sources in your account? Send a request and
              one of our admins will guide you through the process.
            </p>
            <p>Send an email to {config.support.supportEmail}</p>
          </SideBox>
        )}
        <SideBox
          title='Support'
          buttons={[
            <Button key='contactUsBtn' onClick={onSupportClick}>
              Contact us
            </Button>,
          ]}
          topIcon={<BrandMark className={styles.supportBoxIcon} width={131} height='100%' />}
        >
          <p>Need help with our app or want to get in touch? Contact us:</p>
          <p>
            <strong>Support Team:</strong> {config.support.supportEmail}
          </p>
        </SideBox>
        {Boolean(resourcesRoutes?.length) && (
          <SideBox title='Resources'>
            <ul>
              {resourcesRoutes.map((route) => (
                <li key={route.link}>
                  <Link to={route.link} target='_blank'>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          </SideBox>
        )}
      </div>
    </div>
  );
};

export default UserSettings;
