import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { UpdateUserPayloadType } from '../models/userType';
import filterEmptyStringsFromObject from '../utils/filterEmptyStringsFromObject';

export const updateUser = ({ role, data }: UpdateUserPayloadType) => {
  const filteredData = filterEmptyStringsFromObject(data);
  return axiosInstance.patch(config.api.userMe, {
    role,
    data: filteredData,
  });
};
