import React, { ReactElement, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import styles from './StudyComponent.module.css';
import Button from '../Button/Button';
import { StudyInterface } from '../../shared/models/study';
import { priorityDicomTags, skipDicomTags } from '../../shared/utils/skipDicomInputs';
import { dicomTags } from '../../shared/staticText/rawDicomLabelTags';
import StudyFiles from '../StudyFiles/StudyFiles';
import formatDate from '../../shared/utils/formatDate';
import calculateAge from '../../shared/utils/calculateAge';
import { formatGender } from '../../shared/utils/formatGender';
import StudyShares from '../StudyShares/StudyShares';

const StudyComponent = ({
  study,
  patientView,
  loadingGhost,
}: {
  study?: StudyInterface;
  patientView?: boolean;
  loadingGhost?: boolean;
}): ReactElement => {
  const [viewRawDicom, setViewRawDicom] = useState<boolean>(false);
  const toggleRawView = () => {
    setViewRawDicom((prev) => !prev);
  };

  const infoRows: { label: string; value: string }[] = [
    ...(study?.data?.studyDetails?.date
      ? [{ label: 'Taken on', value: formatDate(study.data.studyDetails.date) }]
      : []),
    ...(study?.data?.extraData?.providerName
      ? [{ label: 'Taken in', value: study.data?.extraData?.providerName }]
      : []),
    ...(study?.data?.studyDetails?.physicianFirstName
      ? [
          {
            label: 'Taken by',
            value: `${study.data?.studyDetails?.physicianFirstName} ${study.data?.studyDetails?.physicianLastName}`,
          },
        ]
      : []),
  ];

  const patientInfoRows: { label: string; value: string }[] = [
    ...(study?.user?.data?.firstName
      ? [{ label: 'Patient', value: `${study.user.data.firstName} ${study.user.data.lastName}` }]
      : []),
    ...(study?.data?.extraData && study?.data?.extraData['x00100040']
      ? [{ label: 'Gender', value: formatGender(study.data?.extraData['x00100040']) }]
      : []),
    ...(study?.user?.data?.birthday
      ? [{ label: 'Age', value: `${calculateAge(study?.user?.data?.birthday)}` }]
      : []),
    ...(study?.user?.data?.birthday
      ? [{ label: 'DOB', value: formatDate(study?.user?.data?.birthday) }]
      : []),
  ];

  return (
    <div>
      <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
        <div className={styles.box}>
          <div className={styles.name}>
            <div className={styles.image}>
              <BrandMark width={32} height={'100%'} />
            </div>
            <h2>
              {!loadingGhost && study?.data?.studyDetails?.name}
              {loadingGhost && 'Test dicom title'}
            </h2>
          </div>
          <hr className={styles.separator} />
          {!loadingGhost && !patientView && Boolean(patientInfoRows.length) && (
            <>
              <div className={styles.infoRows}>
                {patientInfoRows.map((item) => (
                  <div className={styles.infoRow} key={item.label}>
                    <div className={styles.label}>{item.label}</div>
                    <div className={styles.value}>{item.value}</div>
                  </div>
                ))}
              </div>
              <hr className={styles.separator} />
            </>
          )}
          {!loadingGhost && Boolean(infoRows.length) && (
            <>
              <div className={styles.infoRows}>
                {infoRows.map((item) => (
                  <div className={styles.infoRow} key={item.label}>
                    <div className={styles.label}>{item.label}:</div>
                    <div className={styles.value}>{item.value}</div>
                  </div>
                ))}
              </div>
              <hr className={styles.separator} />
            </>
          )}
          <div className={styles.infoRows}>
            <small className={styles.dataExtractedFormDicom}>Metadata extracted from dicom:</small>
            {!loadingGhost && study?.data?.extraData && (
              <>
                {priorityDicomTags.map((key) => (
                  <div className={styles.infoRow} key={key}>
                    <div className={styles.label}>{dicomTags[key]}:</div>
                    <div className={styles.value}>{study?.data?.extraData[key] ?? 'empty'}</div>
                  </div>
                ))}
                {viewRawDicom &&
                  Object.entries(study?.data?.extraData)
                    .filter(
                      ([key]) => !priorityDicomTags.includes(key) && !skipDicomTags.includes(key),
                    )
                    .map(([key, value]) => (
                      <div className={styles.infoRow} key={key}>
                        <div className={styles.label}>{dicomTags[key]}:</div>
                        <div className={styles.value}>{value}</div>
                      </div>
                    ))}
              </>
            )}
            {loadingGhost && (
              <>
                {[...Array(5)].map((_, index) => (
                  <div className={styles.infoRow} key={index + Math.random()}>
                    <div className={styles.label}>Label test:</div>
                    <div className={styles.value}>Value test </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <hr className={styles.separator} />
          <Button onClick={toggleRawView} style='naked' loadingGhost={loadingGhost}>
            {viewRawDicom ? 'Collapse' : 'Expand'} metadata
          </Button>
        </div>
        <StudyFiles studyId={study?.id} />
      </div>
      <StudyShares study={study} loadingGhost={loadingGhost} />
    </div>
  );
};

export default StudyComponent;
